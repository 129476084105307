import React, { useContext } from "react";
import VideoItemTransition from "./VideoGroupItem/VideoItemTransition/VideoItemTransition";
import { IMaterialFile, ISences } from "@/stores/mixCutDetailStore/type";
import { mixCutDetailContext } from "../../MixCutDetail";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useMixCutDetail from "@/useStore/mixCutDetail/useMixCutDetail";
import useCaption from "@/useStore/mixCutDetail/useCaption";
import useTitle from "@/useStore/mixCutDetail/useTitle";
import { observer } from "mobx-react";
import useTransition from "@/useStore/mixCutDetail/useTransition";
import VideoGroupItem from "./VideoGroupItem/VideoGroupItem";
import usePreviewConfig from "@/useStore/preview/usePreview";
import usePreviewInfo from "@/useStore/mixCutDetail/usePreviewInfo";
import _ from "lodash";
export interface IVideoGroupBlockProps {
  data: ISences[];
  handleDataChange: (data: ISences[]) => void;
  handleVideoGroupDelete: (index: number) => void;
  handleTitleBtnClick: (index: number) => void;
  handleCaptionBtnClick: (index: number) => void;
  handleTransitionBtnClick: (index: number) => void;
  handleProjectVideoChange: (index: number, data: IMaterialFile[]) => void;
  handleUploadBtnClick: (type: "material" | "local", index: number) => void;
  handleMetarialEditBtnClick: (groupIndex: number, index: number) => void;
  // handlePlayerCaption: (index: number) => void;
  // handlePlayerCaptionStop: (index: number) => void;
  handleHeaderItemClick: (index: number) => void;
  toGetText?: (id: string) => void;
  onTextAnalysis?: (data: object) => void;
  handleTextAnalysis?: (id: string) => void;
  showAnalysis?: boolean;
  analysisId: string | number,
  videoStatusList: any;
}

const VideoGroupBlock: React.FC<IVideoGroupBlockProps> = observer((props) => {
  const {
    data,
    handleDataChange,
    handleVideoGroupDelete,
    handleTitleBtnClick,
    handleCaptionBtnClick,
    handleTransitionBtnClick,
    handleProjectVideoChange,
    handleUploadBtnClick,
    handleMetarialEditBtnClick,
    handleHeaderItemClick,
    toGetText,
    onTextAnalysis,
    analysisId,
    showAnalysis,
    videoStatusList,
  } = props;
  const { toIndexGetError } = usePreviewInfo();
  const { transition } = useTransition();
  const { global } = useMixCutDetail();
  const { handleSwapCaptionItem, projectCaption } = useCaption();
  const { handelSwapTitleItem, titleList } = useTitle();
  const {
    handlePreviewTitleChange,
    handlePreviewCaptionChange,
    handleClipIdChange,
    handleToChangeVideoIndexSetTitleAndCaption,
  } = usePreviewConfig();
  const swapArrayElements = (arr, indexA, indexB) => {
    const temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  }
  const { videoGroupActive, setVideoGroupActive } =
    useContext(mixCutDetailContext);
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    const reorderedData = _.cloneDeep(data);
    // const [movedItem] = reorderedData.splice(source.index, 1);
    // reorderedData.splice(destination.index, 0, movedItem);
    const newArr = swapArrayElements(reorderedData, source.index, destination.index);
    handleDataChange(newArr);
    setVideoGroupActive(destination.index);
    if (!global) {
      handleSwapCaptionItem(destination.index, source.index);
      handelSwapTitleItem(destination.index, source.index);
    }
    handleToChangeVideoIndexSetTitleAndCaption(videoGroupActive);
    handleClipIdChange(null);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="video-group-droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {(data || []).map((item, index) => (
              <Draggable
                key={item.id} // 为每个项提供唯一的 key
                draggableId={item.id} // 为 Draggable 提供 id
                index={index} // 索引
              >
                {(provided, snapshot) => (
                  <React.Fragment key={item.id}>
                    <div
                      key={item.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps} // 拖拽事件
                      style={{
                        ...provided.draggableProps.style,
                      }}>
                      <VideoGroupItem
                        videoStatusList={videoStatusList}
                        analysisId={analysisId}
                        showAnalysis={showAnalysis}
                        key={item.id}
                        onClick={() => {
                          handleClipIdChange(null);
                          handleHeaderItemClick(index);
                        }}
                        sencesIndex={index}
                        error={toIndexGetError(index)}
                        handleEditBtnClick={(e) => {
                          handleMetarialEditBtnClick(index, e);
                        }}
                        isDragging={snapshot.isDragging}
                        active={videoGroupActive === index}
                        handleHeaderClick={() => {
                          handleClipIdChange(null);
                          handleHeaderItemClick(index);
                        }}
                        name={item?.name}
                        materials={item?.materials}
                        materialMute={item?.setting.material_mute}
                        disabled={item?.setting?.disabled}
                        speed={item?.setting?.speed}
                        clipRule={item?.setting?.clip_rule}
                        hanldeMaterialChange={(e) => {
                          handleProjectVideoChange(index, e);
                        }}
                        handleNameChange={(e) => {
                          data[index].name = e;
                          handleDataChange([...data]);
                        }}
                        handleSetting={(e) => {
                          data[index].setting = e;
                          handleDataChange([...data]);
                        }}
                        handleDeleteBtnClick={() => {
                          if (
                            index === 0 &&
                            videoGroupActive === 0 &&
                            projectCaption.length > 1
                          ) {
                            handlePreviewCaptionChange(
                              ((projectCaption || [])[1] || [])[0],
                            );
                            handlePreviewTitleChange((titleList || [])[1]);
                          }
                          if (videoGroupActive === index) {
                            setVideoGroupActive(0);
                          }
                          if (videoGroupActive > index) {
                            setVideoGroupActive(videoGroupActive - 1);
                          }
                          handleVideoGroupDelete(index);
                        }}
                        handleTitleBtnClick={() => {
                          handleTitleBtnClick(index);
                        }}
                        handleCaptionBtnClick={() => {
                          handleCaptionBtnClick(index);
                        }}
                        handleUploadBtnClick={(type: "material" | "local") => {
                          handleUploadBtnClick(type, index);
                        }}
                        toGetText={() =>{
                          toGetText(data[index].id);
                        }}
                        onTextAnalysis={(e) => {
                          onTextAnalysis(e);
                        }}
                        dragHandleProps={provided.dragHandleProps} // 传递 dragHandleProps
                      />
                    </div>

                    {!snapshot.isDragging && index !== data.length - 1 && (
                      <VideoItemTransition
                        onClick={() => {
                          handleTransitionBtnClick(index);
                          setVideoGroupActive(index + 1);
                        }}
                        number={transition[index]?.effects?.length || 0}
                      />
                    )}
                  </React.Fragment>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default VideoGroupBlock;
