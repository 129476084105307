import mixCutDetailStore from "@/stores/mixCutDetailStore";
import {
  getInitGlobalCaption,
  getToSencesInitTransitionItem,
  initCaptionItem,
  initISencesItem,
  toIndexGetInitTitleTextSetting,
} from "@/stores/mixCutDetailStore/init";
import { v4 as uuid } from "uuid";
import ConfigStore from '@/stores/mixedConfig/index'
export interface IUseMixCutDetailInitReturn {
  initVersion: () => void;
  initAllMixCutDetal: () => void;
  initVideoGroup: () => void;
  initCaptionGroup: () => void;
  initTitleGroup: () => void;
  initTransitionGroup: () => void;
  initBg: () => void;
  initGlobleCaptionGroup: () => void;
}

const useMixCutDetailInit = () => {
  const initAllMixCutDetal = () => {
    initVersion();
    initCaptionGroup();
    initVideoGroup();
    initTitleGroup();
    initTransitionGroup();
    initBg();
    initGlobleCaptionGroup();
  };

  const initVersion = () => {
    ConfigStore.setVersion("1.0.0");
  };

  const initVideoGroup = () => {
    mixCutDetailStore.setProjectSences([{ ...initISencesItem, id: uuid() }]);
    // 修改caption
    ConfigStore.setScene([{ ...initISencesItem, id: uuid(), caption: {
        id: Math.floor(Date.now() + Math.random() * 100000),
        ...initCaptionItem,
      }, title: {
        id: Math.floor(Date.now() + Math.random() * 100000),
        content: [
          {
            id: Math.floor(Date.now() + Math.random() * 100000),
            text: "",
            _text: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ],
            text_setting: { ...toIndexGetInitTitleTextSetting(0) },
          },
        ],
      }}]);
  };
  const initCaptionGroup = () => {
    mixCutDetailStore.setProjectCaption([
      [
        {
          id: Math.floor(Date.now() + Math.random() * 100000),
          ...initCaptionItem,
        },
      ],
    ]);
  };
  const initTitleGroup = () => {
    mixCutDetailStore.setProTitle([
      {
        id: Math.floor(Date.now() + Math.random() * 100000),
        content: [
          {
            id: Math.floor(Date.now() + Math.random() * 100000),
            text: "",
            _text: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ],
            text_setting: { ...toIndexGetInitTitleTextSetting(0) },
          },
        ],
      },
    ]);
    ConfigStore.global_settings.titles = [{
      id: Math.floor(Date.now() + Math.random() * 100000),
      content: [
        {
          id: Math.floor(Date.now() + Math.random() * 100000),
          text: "",
          _text: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "",
                },
              ],
            },
          ],
          text_setting: { ...toIndexGetInitTitleTextSetting(0) },
        },
      ],
    }]
  };
  const initTransitionGroup = () => {
    mixCutDetailStore.setTransition({ ...getToSencesInitTransitionItem(0) });
    ConfigStore.setTransition({ ...getToSencesInitTransitionItem(0) })
  };
  const initBg = () => {
    mixCutDetailStore.setProjectBgColor("");
    mixCutDetailStore.setProjectCover([]);
    mixCutDetailStore.setProjectBgAudio([]);
    mixCutDetailStore.setProjectBgImages([]);
    ConfigStore.global_settings.background.color = ""
    ConfigStore.global_settings.background.audio = []
    ConfigStore.global_settings.background.images = []
    ConfigStore.global_settings.cover = []
  };
  const initGlobleCaptionGroup = () => {
    mixCutDetailStore.setProjectGlobalCaptions([...getInitGlobalCaption()]);
    ConfigStore.global_settings.captions = [...getInitGlobalCaption()]
  };

  return {
    initAllMixCutDetal,
    initVideoGroup,
    initCaptionGroup,
    initTitleGroup,
    initTransitionGroup,
    initBg,
    initGlobleCaptionGroup,
    initVersion,
  };
};

export default useMixCutDetailInit;
