import { useState } from "react";
import { RequestStatus } from "../../common";
import {
  CreateFolderResponse,
  ICreateFolderProps,
  createFolder,
} from "@/services/createSpace/material";
import { Message } from "@arco-design/web-react";

interface useCreateFolderReturn {
  loading: RequestStatus;
  folderInfo: CreateFolderResponse;
  handleCreateFolder: (
    data: ICreateFolderProps,
  ) => Promise<CreateFolderResponse>;
}

const useCreateFolder = (): useCreateFolderReturn => {
  const [loading, setLoading] = useState<RequestStatus>(
    RequestStatus.NOTREQUESTED,
  );
  const [folderInfo, setFolderInfo] = useState(null);
  const handleCreateFolder = async (
    data: ICreateFolderProps,
  ): Promise<CreateFolderResponse> => {
    setLoading(RequestStatus.LOADING);
    try {
      const info = await createFolder(data);
      setLoading(RequestStatus.SUCCESS);
      Message.success("添加成功！");
      return info;
    } catch (e) {
      Message.error(e);
      setLoading(RequestStatus.FAILED);
      return null;
    }
  };

  return {
    loading,
    folderInfo,
    handleCreateFolder,
  };
};

export default useCreateFolder;
