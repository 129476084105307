import React, {useState, useEffect} from 'react';
import './index.less';
import { Input,Image, Spin } from "@arco-design/web-react";
import useNavProject from "@/useStore/createspace/useProject";
const InputSearch = Input.Search;
import { observer } from "mobx-react";
import avtiveImg from "@/assets/create-space/project_nav_active.png";
import FileImg from '@/assets/create-space/file-img-min.png'
import clearImg from "@/assets/create-space/script-clear.png";
import {getPorjectList} from "@/services/createSpace/graphQ/getProjectList";
import navStore from "@/stores/spaceStore/projectStore";
type Props = {
  onClick: (e: object) => void;
};
const ProjectList = (props: Props) => {
  const { onClick } = props;
  const [projectList, setProjectList] = useState([]);
  const [projectKeyWord, setProjectKeyWord] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getList();
  }, []);
  const toGetProjectList = (name: string) => {
    if (name) {
      return projectList.filter((item) => item.name.includes(name));
    } else {
      return projectList;
    }
  }
  const getList = () => {
    setLoading(true)
    getPorjectList({}).then((res) => {
      setProjectList(res);
      setLoading(false)
    });
  }
  return <div className="project-list">
    <div style={{ marginBottom: '20px' }} className="flex--between--center">
      <div className="project-list-title">项目目录({projectList.length || 0})</div>
      <InputSearch
        value={projectKeyWord}
        allowClear
        placeholder="请输入关键字搜索"
        style={{ width: "210px", height: 38 }}
        onChange={(e) => {
          setProjectKeyWord(e);
        }}
      />
    </div>
    {
      loading && <Spin className="spin-project" />
    }
    <div className="project-box">
      {
        !loading && toGetProjectList(projectKeyWord).map((item) => {
        return (
          <div onClick={() => {
            onClick(item);
          }} className="pro-item" key={item.id}>
            <Image className="pro-img" src={FileImg} preview={false} width={58} height={58} />
            <div>{ item.name }</div>
          </div>
        )
      })
    }</div>
  </div>;
}
export default observer(ProjectList);
