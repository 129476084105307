import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import ErrorBoundary from "./components/business/ErrorBoundary";
import { Provider } from "mobx-react";
import stores from "./stores";
import { registerMicroApps, start } from "qiankun";
import "./style/globals.less";
import "./style/reset.less";
const entry1 = process.env.REACT_APP_ENTRY1;
const entry2 = process.env.REACT_APP_ENTRY2;

registerMicroApps([
  {
    name: "matrix", // app name registered
    entry: entry1,
    container: "#matrixcontainer",
    activeRule: "/matrix",
  },
  {
    name: "web", // app name registered
    entry: entry2,
    container: "#matrixcontainer",
    activeRule: "/web",
  }
]);
start();
dayjs.locale("zh-cn");
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <div className="microfront-root">
    <BrowserRouter>
      {/* <ErrorBoundary
        fallback={<h1 style={{ color: "red" }}>出现了一些毁天灭地的错误!</h1>}> */}
      <Provider {...stores}>
        <App />
      </Provider>
      {/* </ErrorBoundary> */}
    </BrowserRouter>
    <div id="matrixcontainer">
    </div>
  </div>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
