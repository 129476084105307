import { makeAutoObservable, action, toJS, runInAction } from "mobx";
import { Message } from "@arco-design/web-react";
import { setTokenToLocalStorage } from "@/utils/token";
import { makePersistable } from "mobx-persist-store";
import {
  getIsTeam,
  getMyTeamsList,
  getUserInfo,
  getTeamAll,
  getUserAvatarUrl,
} from "@/services/user/user";
import { getAataIntegration } from "@/services/workbenck/workbenck";
interface IUserInfo {
  avatar_color: string;
  avatar?: string;
  created_at: number;
  last_login: number;
  last_login_ip: string;
  nickname: string;
  phone_number: string;
  updated_at: number;
  uuid: string;
  password_strength: number;
}

interface ICollection {
  job_num: number;
  script_num: number;
  session_num: number;
}

interface ICompose {
  month_composed: number;
  total_composed: number;
}

interface IPublish {
  month_published: number;
  published: number;
}

interface IDataIntegrationItem {
  benefits?: any[];
  collection?: ICollection;
  compose?: ICompose;
  publish?: IPublish;
}

class UserStore {
  userInfo: IUserInfo = {
    avatar_color: "",
    created_at: 0,
    last_login: 0,
    last_login_ip: "",
    nickname: "",
    phone_number: "",
    updated_at: 0,
    uuid: "",
    password_strength: 0,
  };
  isExpired_at_package = false;
  teamInfoLoaded = false;
  teamInfo = {
    team_user_role: {
      exists: "",
      nickname: "",
      status: 0,
      expired_at: 0,
      roles: [
        {
          code: "",
          description: "",
          id: "",
          name: "",
        },
      ],
      team: {
        id: "",
        name: "",
      },
    },
    teamList: {
      items: [],
      team_id: -1,
    },
    memberList: [],
  };
  dataIntegration: IDataIntegrationItem;
  logoWidth = 107;
  ChangeTeamMoadl = false;
  CreateTeamMoadl = false;
  ExclusiveModal = false;
  isHaveTeam = false;
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "UserStore",
      properties: [
        "userInfo",
        "teamInfo",
        "logoWidth",
        "dataIntegration",
        "isExpired_at_package",
      ],
      storage: window.localStorage,
    });
  }

  getUserInfo = action(async () => {
    const res = await getUserInfo();
    if (res?.code === 0) {
      this.userInfo = {
        ...res.data,
        avatar: res.data.avatar
          ? (await getUserAvatarUrl(res.data.avatar)).data?.url
          : null,
      };
    } else {
      this.userInfo = res.data;
    }
  });

  getTeamInfo = action(async () => {
    const res = await getIsTeam();
    const resList = await getMyTeamsList();
    const memberInfo = await getTeamAll({
      state: 3,
    });

    if (res?.data) {
      this.teamInfo.team_user_role = res.data?.team_user_role;
    }
    if (resList) {
      this.teamInfo.teamList = resList.data || [];
    }
    if (memberInfo?.data) {
      this.teamInfo.memberList =
        (memberInfo.data?.info && memberInfo.data?.info.length
          ? memberInfo.data?.info &&
            memberInfo.data?.info.filter((v) => v.role_id[0] != "1")
          : []) || [];
    }
  });
  setLogoWidth = action((width) => {
    this.logoWidth = width;
  });

  getDataIntegration = action(async () => {
    const res = await getAataIntegration();
    if (res?.data) {
      this.dataIntegration = res?.data?.workbench_data_integration || {};
    }
  });
  setIsExpired = action(async () => {
    this.isExpired_at_package = !this.isExpired_at_package;
  });
  setChangeTeamModal = action(async () => {
    this.ChangeTeamMoadl = !this.ChangeTeamMoadl;
  });
  setExclusiveModal = action(async () => {
    this.ExclusiveModal = !this.ExclusiveModal;
  });
  setCreateTeamModal = action(async () => {
    this.CreateTeamMoadl = !this.CreateTeamMoadl;
  });

  getTeamStatus = action(async () => {
    if (!this.teamInfoLoaded) {
      await this.getTeamInfo();
      this.teamInfoLoaded = true;
    }
    const res = await getIsTeam();
    const exists = res?.data?.team_user_role;
    const is = Boolean(exists?.team?.name?.length || 0);
    this.isHaveTeam = is;
    if (!is) {
      if (this.teamInfo?.teamList?.items?.length) {
        this.setChangeTeamModal();
        return true;
      } else {
        this.setCreateTeamModal();
        return true;
      }
    } else {
      return false;
    }
  });
}
const User = new UserStore();
export default User;
