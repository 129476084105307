import mixCutDetailStore from "@/stores/mixCutDetailStore";
import {
  ITransition,
  ITransitionItem,
} from "@/stores/mixCutDetailStore/type/transition";
import useMixCutDetailSave from "./useMixCutDetailSave";
import { toJS } from "mobx";
import ConfigStore from '@/stores/mixedConfig/index'
export interface ITransitionReturn {
  transition: ITransition;
  handleTransitionItemAdd: (key: string, data: ITransitionItem) => void;
  handleTransitionItemRemove: (key: string) => void;
  handleTransitionItemChange: (key: string, data: ITransitionItem) => void;
  handleTransitionItemEffectsRemove: (key: string, index: number) => void;
  handleTransitionItemIsGlobaltoTrue: (key: string) => void;
  handleTransitionItemClear: (key: string) => void;
}

const useTransition = (): ITransitionReturn => {
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();

  const handleTransitionItemClear = (key: string) => {
    const newTransition = { ...ConfigStore.transition };
    const newTransitionItem = newTransition[key];
    if (newTransitionItem.is_global) {
      Object.keys(newTransition).forEach((item) => {
        newTransition[item].effects = [];
      });
    }
    newTransition[key] = {
      ...newTransitionItem,
      effects: [],
    };
    console.log(newTransition);
    ConfigStore.setTransition({ ...newTransition });
  };

  const handleTransitionItemAdd = (key: string, data: ITransitionItem) => {
    const newTransition = {
      ...ConfigStore.transition,
      [key]: data,
    };
    ConfigStore.setTransition(newTransition);
    handleSaveMixCutDetailSave();
  };

  const handleTransitionItemRemove = (key: string) => {
    const newTransition = { ...ConfigStore.transition };

    delete newTransition[key];

    const reorderedTransition: Record<string, any> = {};

    const keys = Object.keys(newTransition)
      .map((k) => Number(k)) // 转换为数字，以确保按顺序排列
      .sort((a, b) => a - b); // 排序

    keys.forEach((k, index) => {
      reorderedTransition[index.toString()] = newTransition[k];
    });

    mixCutDetailStore.setTransition(reorderedTransition);

    handleSaveMixCutDetailSave();
  };

  const handleTransitionItemChange = (key: string, data: ITransitionItem) => {
    const newTransition = { ...ConfigStore.transition };
    newTransition[key] = data;
    ConfigStore.setTransition(newTransition);
    if (data.is_global) {
      handleTransitionItemIsGlobaltoTrue(key);
    }
    handleSaveMixCutDetailSave();
  };

  const handleTransitionItemEffectsRemove = (key: string, index: number) => {
    const newMixCutDetailStroeTransitionItem =
      ConfigStore.transition[key];
    if (
      index >= 0 &&
      index < newMixCutDetailStroeTransitionItem.effects.length
    ) {
      newMixCutDetailStroeTransitionItem.effects.splice(index, 1);

      ConfigStore.setTransition({
        ...ConfigStore.transition,
        [key]: newMixCutDetailStroeTransitionItem,
      });
      if (newMixCutDetailStroeTransitionItem.is_global) {
        handleTransitionItemIsGlobaltoTrue(key);
      }
    }
    handleSaveMixCutDetailSave();
  };

  const handleTransitionItemIsGlobaltoTrue = (key: string) => {
    const newTransitions = { ...ConfigStore.transition };
    const transitionItem = newTransitions[key];
    Object.keys(newTransitions).forEach((item) => {
      newTransitions[item].effects = [...transitionItem.effects];
      newTransitions[item].duration = transitionItem.duration;
    });
    ConfigStore.setTransition(newTransitions);
  };

  return {
    transition: ConfigStore.transition,
    handleTransitionItemAdd,
    handleTransitionItemRemove,
    handleTransitionItemChange,
    handleTransitionItemEffectsRemove,
    handleTransitionItemIsGlobaltoTrue,
    handleTransitionItemClear,
  };
};

export default useTransition;
