import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import {
  IBgResponse,
  ICoverItemResponse,
  IMusicResponse,
} from "@/services/mixCutDetail/mixCutDetail";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import useMixCutDetailSave from "./useMixCutDetailSave";
import { toJS } from "mobx";
import ConfigStore from '@/stores/mixedConfig/index'
interface IUseCoverAndBgReturn {
  cover: ICoverItemResponse[];
  bgColor: string;
  bgAudio: IMusicResponse[];
  bgImages: (IBgResponse | IMaterialDetail)[];
  bgAudioVolumn: number;
  handleCoverChange: (cover: ICoverItemResponse[]) => void;
  handleBgChange: (bgColor: string) => void;
  handleBgAudiosChange: (bgAudios: IMusicResponse[]) => void;
  handleBgImagesChange: (images: (IBgResponse | IMaterialDetail)[]) => void;
  handleBgAudioVolumn: (volumn: number) => void;
}

const useCoverAndBg = (): IUseCoverAndBgReturn => {
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const handleBgAudiosChange = (bgAudios: IMusicResponse[]) => {
    mixCutDetailStore.setProjectBgAudio(bgAudios);
    ConfigStore.global_settings.background.audio = bgAudios;
    handleSaveMixCutDetailSave();
  };

  const handleBgChange = (bgColor: string) => {
    mixCutDetailStore.setProjectBgColor(bgColor);
    ConfigStore.global_settings.background.color = bgColor;
    handleSaveMixCutDetailSave();
  };
  const handleBgImagesChange = (images: IBgResponse[]) => {
    mixCutDetailStore.setProjectBgImages(images);
    ConfigStore.global_settings.background.images = images;
    handleSaveMixCutDetailSave();
  };

  const handleCoverChange = (newCover: ICoverItemResponse[]) => {
    mixCutDetailStore.setProjectCover(newCover);
    ConfigStore.global_settings.cover = newCover;
    handleSaveMixCutDetailSave();
  };

  const handleBgAudioVolumn = (volumn: number) => {
    mixCutDetailStore.setProjectBgAudioVolum(volumn);
    ConfigStore.global_settings.background.audio_volume = volumn;
    handleSaveMixCutDetailSave();
  };

  return {
    bgAudioVolumn: ConfigStore.global_settings.background.audio_volume,
    cover: ConfigStore.global_settings.cover,
    bgColor: ConfigStore.global_settings.background.color,
    bgAudio: ConfigStore.global_settings.background.audio,
    bgImages: ConfigStore.global_settings.background.images,
    handleBgAudioVolumn,
    handleCoverChange,
    handleBgChange,
    handleBgAudiosChange,
    handleBgImagesChange,
  };
};

export default useCoverAndBg;
