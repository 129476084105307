// LineLimit.ts
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { Node as ProseMirrorNode, ResolvedPos } from "prosemirror-model";

interface LineLimitOptions {
  singleLine: number; // 每行字符限制，0 表示不限制
}

interface ComposeState {
  text: string;
  from: number;
  to: number;
}

const LineLimitPluginKey = new PluginKey("lineLimit");

const LineLimit = Extension.create<LineLimitOptions>({
  name: "lineLimit",

  addOptions() {
    return {
      singleLine: 0, // 默认不限制
    };
  },

  addProseMirrorPlugins() {
    const { singleLine } = this.options;
    if (singleLine <= 0) {
      return [];
    }

    let composing = false;
    let composeState: ComposeState | null = null;

    // 定义允许的标点符号
    const punctuation = [
      ",",
      "，",
      ".",
      "。",
      ";",
      "；",
      ":",
      "：",
      "?",
      "？",
      "!",
      "！",
      "…",
      "……",
      "-",
      "—",
      "、",
      "'",
      " ",
    ];
    const punctuationRegex = punctuation.map((p) => `\\${p}`).join("");
    // const regex = new RegExp(`[^\\u4e00-\\u9fa5a-zA-Z0-9${punctuationRegex}]`); // 匹配非中文和非标点字符
    const regex = new RegExp(
      `[^\\u4e00-\\u9fa5a-zA-Z0-9${punctuationRegex}ぁ-んァ-ン一-龯々〆〤\\uAC00-\\uD7AF\\u1100-\\u11FF]`,
    ); // 支持日文假名、汉字和韩文字符
    return [
      new Plugin({
        key: LineLimitPluginKey,

        props: {
          handleDOMEvents: {
            compositionstart(view) {
              composing = true;
              return false; // 允许其他事件处理继续
            },
            compositionend(view) {
              composing = false;
              if (!composeState) {
                return false;
              }

              const { from, to } = composeState;
              const resolvedPos: ResolvedPos = view.state.doc.resolve(to);
              const parentNode: ProseMirrorNode = resolvedPos.parent;

              const currentLineLength = parentNode.textContent.length;

              if (currentLineLength <= singleLine) {
                composeState = null;
                return false;
              }

              const over = currentLineLength - singleLine;
              const tr = view.state.tr;
              // 计算要删除的位置
              const deleteFrom = to - over;
              tr.delete(deleteFrom, to);
              view.dispatch(tr);
              composeState = null;
              return true;
            },
          },

          handleTextInput: (view, from, to, text) => {
            if (regex.test(text)) {
              // 阻止不符合的字符被输入
              return true;
            }

            if (composing) {
              composeState = { text, from, to: from + text.length };
              return false;
            }

            const { state, dispatch } = view;
            const $from = state.doc.resolve(from);
            const parent = $from.parent; // 获取当前光标所在的父节点

            // 仅对段落和列表项进行限制
            if (!["paragraph", "listItem"].includes(parent.type.name)) {
              return false;
            }

            const currentText = parent.textContent || "";
            const selectionText = state.doc.textBetween(from, to, "\n");
            const newLength =
              currentText.length - selectionText.length + text.length;

            if (newLength > singleLine) {
              const allowedLength =
                singleLine - (currentText.length - selectionText.length);
              if (allowedLength > 0) {
                const allowedText = text.slice(0, allowedLength);
                dispatch(state.tr.insertText(allowedText, from, to));

                // 获取节点类型
                const nodeType = parent.type;

                // 创建节点分隔符（如换行或新段落）
                if (nodeType.name === "paragraph") {
                  const paragraph = state.schema.nodes.paragraph;
                  if (paragraph) {
                    dispatch(state.tr.split(from + allowedLength));
                  }
                } else if (nodeType.name === "listItem") {
                  // 对于列表项，进行拆分
                  dispatch(state.tr.split(from + allowedLength));
                }
              }
              return true; // 阻止原始输入
            }

            return false;
          },
        },
      }),
    ];
  },
});

export default LineLimit;
