import mixCutDetailStore from "@/stores/mixCutDetailStore";
import {
  ITitle,
  ITitleContentItem,
} from "@/stores/mixCutDetailStore/type/title";
import useMixCutDetailInit from "./useMixCutDetailInit";
import useMixCutDetail from "./useMixCutDetail";
import useMixCutDetailSave from "./useMixCutDetailSave";
import { Message } from "@arco-design/web-react";
import { toIndexGetInitTitleTextSetting } from "@/stores/mixCutDetailStore/init";
import _ from "lodash";
import usePreviewConfig from "../preview/usePreview";
import ConfigStore from "@/stores/mixedConfig/index";
interface IUseTitleReturn {
  titleList: ITitle;
  handleTitleItemAdd: () => void;
  handleTitleItemRemove: (index: number) => void;
  handleTitleItemChange: (
    index: number,
    currentIndex: number,
    data: ITitleContentItem,
  ) => void;
  handleTitleClear: () => void;
  handelTitleItemAddItem: (index: number) => void;
  handelSwapTitleItem: (destination: number, source: number) => void;
  handleTitleItemSimpleRemove: (group: number, index: number) => void;
  handleTitleSimpleItemGlobalToChange: (
    group: number,
    index: number,
    global: boolean,
  ) => void;
}

const useTitle = (): IUseTitleReturn => {
  const { initTitleGroup } = useMixCutDetailInit();
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const { handlePreviewTitleChange } = usePreviewConfig();
  const { global } = useMixCutDetail();
  const handleTitleItemAdd = () => {
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    mixCutDetailStore.setProTitle([
      ...titleList,
      {
        id: Math.floor(Date.now() + Math.random() * 100000),
        content: [
          {
            id: Math.floor(Date.now() + Math.random() * 100000),
            text_setting: { ...toIndexGetInitTitleTextSetting(0) },
            text: "",
            _text: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ]);
    ConfigStore.global_settings.titles = [
      ...titleList,
      {
        id: Math.floor(Date.now() + Math.random() * 100000),
        content: [
          {
            id: Math.floor(Date.now() + Math.random() * 100000),
            text_setting: { ...toIndexGetInitTitleTextSetting(0) },
            text: "",
            _text: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ]
    handleSaveMixCutDetailSave();
  };

  const handleTitleSimpleItemGlobalToChange = (
    groupIndex: number,
    index: number,
    e: boolean,
  ) => {
    // const titleList = mixCutDetailStore.getProjectTitle();
    const titleList = ConfigStore.global_settings.titles;
    if (e) {
      const { x, y } = titleList[groupIndex].content[index].text_setting;
      titleList.forEach((titleGroup) => {
        titleGroup.content.forEach((item) => {
          item.text_setting.x = x;
          item.text_setting.y = y;
        });
      });
    }
    titleList[groupIndex].content[index].text_setting.is_global = true;
    mixCutDetailStore.setProTitle([...titleList]);
    ConfigStore.global_settings.titles = [...titleList];
  };

  const handleTitleItemRemove = (index: number) => {
    if (mixCutDetailStore.getProjectTitle().length === 1) {
      Message.error("必须保留一个台词组，无法删除！");
      return;
    }
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    titleList.splice(index, 1);
    mixCutDetailStore.setProTitle(titleList);
    ConfigStore.global_settings.titles = [...titleList];
    handleSaveMixCutDetailSave();
  };

  const handleTitleItemSimpleRemove = (groupIndex: number, index: number) => {
    const newTitleList = mixCutDetailStore.getProjectTitle();
    newTitleList[groupIndex].content.splice(index, 1);
    mixCutDetailStore.setProTitle([...newTitleList]);
    ConfigStore.global_settings.titles = [newTitleList];
    handleSaveMixCutDetailSave();
    handlePreviewTitleChange(_.cloneDeep(newTitleList[groupIndex]));
  };

  const handleTitleItemChange = (
    index: number,
    currentIndex: number,
    data: ITitleContentItem,
  ) => {
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    titleList[index].content[currentIndex] = data;
    mixCutDetailStore.setProTitle(titleList);
    ConfigStore.global_settings.titles = [...titleList];
    handleSaveMixCutDetailSave();
    handlePreviewTitleChange(_.cloneDeep(titleList[index]));
  };

  const handelTitleItemAddItem = (index: number) => {
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    titleList[index].content.push({
      id: Math.floor(Date.now() + Math.random() * 100000),
      text: "",
      _text: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "",
            },
          ],
        },
      ],
      text_setting: {
        ...toIndexGetInitTitleTextSetting(titleList[index].content.length),
      },
    });
    mixCutDetailStore.setProTitle(titleList);
    ConfigStore.global_settings.titles = [...titleList];
    handleSaveMixCutDetailSave();
    handlePreviewTitleChange(_.cloneDeep(titleList[index]));
  };

  const handelSwapTitleItem = (destination: number, source: number) => {
    const titleList = [...mixCutDetailStore.getProjectTitle()];
    [titleList[destination], titleList[source]] = [
      titleList[source],
      titleList[destination],
    ];
    ConfigStore.global_settings.titles = [...titleList];
    mixCutDetailStore.setProTitle(titleList);
    handleSaveMixCutDetailSave();
  };

  const handleTitleClear = () => {
    if (global) {
      initTitleGroup();
      handleSaveMixCutDetailSave();
    }
  };

  return {
    titleList: ConfigStore.global_settings.titles,
    handleTitleItemAdd,
    handleTitleItemRemove,
    handleTitleItemChange,
    handelTitleItemAddItem,
    handleTitleClear,
    handelSwapTitleItem,
    handleTitleItemSimpleRemove,
    handleTitleSimpleItemGlobalToChange,
  };
};

export default useTitle;
