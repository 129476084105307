import projectImg from "@/assets/create-space/file_img-min.png";
import "./FolderCard.less";
import React, { useState, useRef, useEffect } from "react";
interface IFolderCardProps {
  name: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  changeName?: () => void;
  id?: string | number;
}
import { Input } from "@arco-design/web-react";
import {reNameMaterial} from "@/services/createSpace/material";
const FolderCard: React.FC<IFolderCardProps> = (props) => {
  const { name, onClick, style, changeName, id } = props;
  const [editFlag, setEditFlag] = useState(false);
  const [editName, setEditName] = useState(name);
  const editRef = useRef(null);
  const editHandle = async() => {
    if (name !== editName) {
      await reNameMaterial(editName, id.toString());
      if (changeName) {
        changeName();
      }
    }
    setEditFlag(false);
  };

  useEffect(() => {
    if (editRef) {
      editRef.current?.focus();
    }
  }, [editFlag]);
  return (
    <div
      className="file-upload-folder-folder-card-wrapper"
      style={style}
      >
      <div className="file-upload-folder-img-wrapper">
        <img onClick={() => {
          if (onClick) {
            onClick();
          }
        }}  src={projectImg} alt="" />
      </div>
      {
        !editFlag && <div className="file-name" onMouseMove={() => {
          setEditFlag(true);
        }}>{name}</div>
      }
      {
        editFlag && <Input onPressEnter={() => {
          editHandle();
        }} ref={editRef} style={{ width: '106px', height: '28px' }} defaultValue={name} onChange={(e) => {
          setEditName(e)
        }} onBlur={() => {
          editHandle();
        }} />
      }
    </div>
  );
};

export default FolderCard;
