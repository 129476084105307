/* eslint-disable prefer-const */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
} from "react";
import StarterKit from "@tiptap/starter-kit";
import {
  useEditor,
  EditorContent,
  Extensions,
  JSONContent,
} from "@tiptap/react";
import CharacterCount from "./component/CharacterCount";
import Placeholder from "@tiptap/extension-placeholder";
import BulletList from "@tiptap/extension-bullet-list";
import { BreakTag } from "./component/break/";
import { useEditorHelper } from "./helper";
import CustomListItem from "./component/CustomListItem";
import CustomUndo from "./component/CustomUndo";
import Document from "@tiptap/extension-document";
import { estimateDuration } from "@/utils/perm";
import LineLimit from "./component/LineLimit";
import { timer } from "@/assets/workbenchimage";
import RemoveExtraNewlines from "./component/RemoveExtraNewlines";
import SensitiveWordHighlight from "./component/SensitiveWordHighlight";
import CustomBackspace from "./component/CustomBackspace/";
import "./index.less";
import { toJS } from "mobx";

interface SelectionInfo {
  value?: string;
  range: {
    from: number;
    to: number;
  };
}

interface Icursor {
  RowId: number;
  ColId: number;
  userInfo: {
    colorInfo: string;
    id: string;
    name: string;
    position: number;
    range: {
      from: number;
      to: number;
    };
  }[];
}
interface IMyEditorProps {
  /**
   * content 内容特定JSON对象格式
   */
  content: any;
  /**
   * handlefocus 获取焦点的回调
   */
  handlefocus: () => void;
  /**
   * handleblur 失去焦点的回调
   */
  handleblur?: () => void;
  /**
   * sendInfo 协同发布函数
   */
  sendInfo?: () => void;
  /**
   * selected 返回选中内容以及光标起始和截止位置
   */
  selected?: (selection: SelectionInfo) => void;
  /**
   * 内容类型 无序列表=caption
   */
  type: "caption" | "";
  /**
   * selectInfo 传入选中内容以及光标起始和截止位置
   */
  selectInfo: SelectionInfo;
  /**
   * limit 内容最大字数
   */
  limit?: number;
  /**
   * cursor 协同光标信息
   */
  cursor?: Icursor;
  /**
   * setEditPosition 返回光标起始和截止信息 获取焦点和光标位置发生变化时触发
   */
  setEditPosition?: (position: { from: number; to: number }) => void;
  /**
   * handleUpdate Update回调 返回实时内容
   */
  handleUpdate: (value: JSONContent[]) => void;
  /**
   * editorCurrentStyle 编辑器样式
   */
  editorCurrentStyle?: React.CSSProperties;
  /**
   * limitPosition 字数上限的样式
   */
  limitPosition?: React.CSSProperties;
  /**
   * placeholder 提示词
   */
  placeholder?: string;
  /**
   * ref 可以拿到实例信息
   */
  ref: any;
  /**
   * isFormat 是否启用黏贴格式化
   */
  isFormat?: boolean;
  /**
   * isShowTimer 是否显示预估时长
   */
  isShowTimer?: boolean;
  /**
   * singleLine 单行字数限制 0则不使用
   */
  singleLine?: number;
  /**
   * onForbidChange 敏感词判断回调
   */
  forbid?: string[];
  /**
   * resTextLength 当前字数
   */
  resTextLength?: (num: number) => void;
}

const MyEditor = forwardRef((props: IMyEditorProps, ref) => {
  const {
    content,
    handlefocus,
    sendInfo,
    selected,
    type,
    selectInfo,
    limit,
    handleblur,
    cursor,
    setEditPosition,
    handleUpdate,
    editorCurrentStyle,
    limitPosition = {},
    placeholder = "请输入",
    isFormat = false,
    isShowTimer = false,
    singleLine = 0,
    forbid = [],
    resTextLength = () => {
      //
    },
  } = props;

  // 文本长度
  const [textLength, setTextLength] = useState(0);
  const [isStart, setiIsStart] = useState(false);
  const fasRef = useRef<any>();
  const updata = (contnetVal?) => {
    if (!editor) return;
    let value;
    if (contnetVal) {
      value = {
        type: content[0]?.type === "paragraph" ? "doc" : "bulletList",
        content: contnetVal,
      };
    } else if (isFormat && content[0]?.type !== "paragraph") {
      value = processDocument({
        type: content[0]?.type === "paragraph" ? "doc" : "bulletList",
        content,
      });
    } else {
      value = {
        type: content[0]?.type === "paragraph" ? "doc" : "bulletList",
        content,
      };
    }
    editor.commands.setContent(value);

    const editLength = editor.getText().replace(/\n/g, "").length;
    resTextLength(editLength);
    setTextLength(editLength);
  };

  const processParagraphContent = (content) => {
    if (!content || !content.length) {
      return;
    }
    const newContent = [];
    let tempParagraph = { type: "paragraph", content: [] };
    content.forEach((node) => {
      if (
        node.type === "text" &&
        /[,，.。;；:：?？!！………—、-]/.test(node.text)
      ) {
        const parts = node.text.split(/[,，.。;；:：?？!！………—、-]/);
        parts.forEach((part, index) => {
          const trimmedText = part.trim();
          if (trimmedText) {
            tempParagraph.content.push({ type: "text", text: trimmedText });
          }
          if (index < parts.length - 1) {
            newContent.push(tempParagraph);
            tempParagraph = { type: "paragraph", content: [] };
          }
        });
      } else {
        tempParagraph.content.push(node);
      }
    });

    if (tempParagraph.content.length > 0) {
      newContent.push(tempParagraph);
    }

    return newContent;
  };

  /**
   * 处理文档数据，支持多种结构类型
   * @param {Object} doc - 原始文档对象
   * @returns {Object} - 处理后的文档对象
   */
  const processDocument = (doc) => {
    const newContent = [];

    if (doc.type === "bulletList") {
      const newListItems = [];
      doc.content.forEach((listItem) => {
        listItem.content.forEach((paragraph) => {
          const processedParagraphs = processParagraphContent(
            paragraph.content,
          );
          processedParagraphs?.forEach((processedParagraph) => {
            newListItems.push({
              type: "listItem",
              content: [processedParagraph],
            });
          });
        });
      });
      return {
        ...doc,
        content:
          newListItems && newListItems.length ? newListItems : doc.content,
      };
    } else {
      doc.content.forEach((block) => {
        if (block.type === "paragraph") {
          newContent.push(...processParagraphContent(block.content));
        } else {
          newContent.push(block);
        }
      });
      return { ...doc, content: newContent };
    }
  };

  const extensions: Extensions = [
    Document,
    StarterKit,
    BreakTag,
    CharacterCount.configure({
      limit,
      mode: "textSize",
      textLength,
      cursorPositionInfo: cursor?.userInfo?.length ? cursor : null,
      isFormat,
      singleLine,
    }),
    LineLimit.configure({
      singleLine, // 配置每行字数限制
    }),
    Placeholder.configure({
      placeholder: placeholder, // 占位符文本
      includeChildren: true,
    }),
    BulletList, // 启用项目符号列表
    // OrderedList, // 启用有序列表（可选）
    CustomListItem, // 使用自定义的 ListItem
    // HighlightRangeExtension,
    CustomUndo,
    CustomBackspace,
    RemoveExtraNewlines,
    SensitiveWordHighlight.configure({
      sensitiveWords: forbid, // 传入敏感词数组
    }),
  ];

  const editorRef = useRef<HTMLDivElement>(null);

  const editor = useEditor({
    extensions,
    enableCoreExtensions: true,
    onFocus() {
      handlefocus();
    },
    onBlur() {
      setiIsStart(false);
      if (handleblur) {
        handleblur();
      }
    },
    onUpdate: (e) => {
      if (!editor) return;

      setiIsStart(true);
      const editLength = editor.getText().replace(/\n/g, "").length;
      setTextLength(editLength);
      resTextLength(editLength);
      const val =
        content[0]?.type === "paragraph"
          ? editor.getText().replace(/\n\n/g, "\n")
          : editor
              .getText()
              .replace(/\n\n\n\n/g, "\n")
              .slice(1);
      selected({
        ...selectInfo,
        value: val,
        range: helper.getSelectionSSML(),
      });

      const json = editor.getJSON().content[0]?.type;
      const value =
        json !== "bulletList"
          ? editLength
            ? content
            : [
                {
                  key: "0",
                  type: "listItem",
                  content: [
                    {
                      type: "paragraph",
                    },
                  ],
                },
              ]
          : editor.getJSON().content[0]?.content || [];

      const p = editor.getJSON().content[0]?.content;
      const textValue =
        p && p.length
          ? editor.getJSON().content
          : [
              {
                key: "0",
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ];
      const handleVal = content[0]?.type === "paragraph" ? textValue : value;
      if (json !== "bulletList" && content[0]?.type !== "paragraph") {
        updata(
          editLength == 0 && isStart
            ? content[0]?.type === "paragraph"
              ? textValue
              : value
            : undefined,
        );
      } else {
        if (!e.transaction.scrolledIntoView) {
          let value;
          if (isFormat && content[0]?.type !== "paragraph") {
            value = processDocument({
              type: content[0]?.type === "paragraph" ? "doc" : "bulletList",
              content: handleVal,
            });
          } else {
            value = {
              type: content[0]?.type === "paragraph" ? "doc" : "bulletList",
              content: handleVal,
            };
          }
          editor.commands.setContent(value);
        }
      }
      fasRef.current = editor.getJSON().content;
      handleUpdate(handleVal);
      if (sendInfo) {
        sendInfo();
      }
    },
    onSelectionUpdate: (e) => {
      if (!e.editor.isFocused) {
        return;
      }
      setiIsStart(true);
      if (setEditPosition && editor) {
        let { from, to } = helper.getSelectionSSML();
        if (content[0]?.type !== "paragraph") {
          from -= 2;
          to -= 2;
        }
        setEditPosition({
          from,
          to,
        });
      }

      const selection = window.getSelection();
      const selectionValue = selection?.toString() || "";
      if (selectionValue) {
        selected({
          ...selectInfo,
          value: selectionValue.replace(/\n\n/g, "\n"),
          range: helper.getSelectionSSML(),
        });
      } else {
        const val =
          content[0]?.type === "paragraph"
            ? editor.getText().replace(/\n\n/g, "\n")
            : editor
                .getText()
                .replace(/\n\n\n\n/g, "\n")
                .slice(1);
        selected({
          ...selectInfo,
          value: val,
          range: helper.getSelectionSSML(),
        });
      }
    },
  });

  const [helper] = useEditorHelper(editor);
  const addBreak = (config: { time?: string } = {}) => {
    const { time = "0.5s" } = config;
    if (!editor) {
      return;
    }
    const { from, to } = selectInfo.range;
    if (from !== to) {
      return;
    }
    const addBreakTag = () => {
      (editor.chain().setTextSelection(selectInfo.range) as any)
        .setBreakTime(time)
        .run();
    };
    addBreakTag();
  };

  useImperativeHandle(ref, () => ({
    editor,
    helper,
    addBreak,
    export() {
      return {
        text: helper.exportText(),
        json: helper.exportJSON(),
        ssml: helper.exportSSML(),
      };
    },
  }));
  // 监听 cursor 的变化并更新光标位置
  useEffect(() => {
    if (editor && editor.storage.characterCount) {
      let cursorPositions = cursor?.userInfo?.length ? cursor.userInfo : [];
      if (type.includes("caption")) {
        cursorPositions = cursorPositions.map((v) => ({
          ...v,
          position: v.position + 2,
          range: { from: v.range.from + 2, to: v.range.to + 2 },
        }));
      }
      editor.storage.characterCount.setCursorPositions(cursorPositions);
    }
  }, [cursor, editor, type]);
  useEffect(() => {
    if (editor) {
      // 更新敏感词列表
      editor.storage.sensitiveWordHighlight.setWords(forbid);
      // 触发编辑器重新计算装饰
      editor.view.updateState(editor.state);
    }
  }, [forbid, editor]);
  useEffect(() => {
    if (editor && !isStart && !editor.isFocused) {
      updata();
    }
    return () => {
      setiIsStart(false);
    };
  }, [content]);

  useEffect(() => {
    if (editor) {
      setTextLength(editor.getText().replace(/\n/g, "").length);
      resTextLength(editor.getText().replace(/\n/g, "").length);
    }
  }, [editor]);

  return (
    <div className="ssml-tag-editor">
      {/* <button
        onClick={() => {
          addBreak({
            time: "0.5s",
          });
        }}>
        插入
      </button> */}
      <div
        onClick={() => {
          setiIsStart(true);
          if (setEditPosition && editor) {
            let { from, to } = helper.getSelectionSSML();
            if (content[0]?.type !== "paragraph") {
              from -= 2;
              to -= 2;
            }
            setEditPosition({
              from,
              to,
            });
          }
          if (!window.getSelection()?.toString()) {
            const val =
              content[0]?.type == "paragraph"
                ? editor.getText().replace(/\n\n/g, "\n")
                : editor
                    .getText()
                    .replace(/\n\n\n\n/g, "\n")
                    .slice(1);
            selected({
              value: val,
              range: helper.getSelectionSSML(),
            });
          }
        }}>
        <EditorContent
          editor={editor}
          ref={editorRef}
          style={editorCurrentStyle}
        />
      </div>
      <div className="bottom-info">
        {content[0]?.type !== "paragraph" && isShowTimer ? (
          <div className="estimated-duration">
            <img width={12} src={timer} style={{ marginRight: 3 }} alt="" />
            {estimateDuration(
              [editor.getText().replace(/\n/g, "")],
              0.22,
              false,
            ).toFixed(2)}
            s
          </div>
        ) : (
          <></>
        )}
        {limit && (
          <span className="textarea-word-limit" style={limitPosition}>
            {textLength}/{limit}
          </span>
        )}
      </div>
    </div>
  );
});

MyEditor.displayName = "MyEditor";
export default MyEditor;
