import useMixCutDetailInit from "@/useStore/mixCutDetail/useMixCutDetailInit";
import { Button, Modal, ModalProps } from "@arco-design/web-react";

export interface IVideoGlobaleModalProps extends ModalProps {
  content: string;
}

const VideoGlobalModal: React.FC<IVideoGlobaleModalProps> = (props) => {
  const { content, ...res } = props;
  return (
    <Modal
      {...res}
      footer={
        <>
          <Button
            onClick={() => {
              res.onCancel();
            }}>
            取消
          </Button>
          <Button
            type="primary"
            onClick={() => {
              res.onOk();
            }}
            style={{ marginLeft: 12 }}>
            确定
          </Button>
        </>
      }
      className={`${res.className} vl-modal`}>
      {content}
    </Modal>
  );
};

export default VideoGlobalModal;
