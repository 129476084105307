import http from "@/utils/http";
import axios from "axios";
/**
 * 短信登录/注册
 * **/
export async function login_sms(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/user/login/sms", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 *
 * 账号登录
 * */
export async function user_login(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/user/login", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}

/**
 *
 * 发送验证码
 * */
export async function send_code(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/user/sms/send_code", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 *
 * 校验验证码
 * */
export async function verify_code(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/user/sms/verify_code", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 *
 * 账号注册
 * */
export async function register(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/user/register", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 *
 *  重设密码
 * */
export async function reset_password(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/user/reset_password", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 *
 *  手机号是否存在
 * */
export async function exist_phone_number(params): Promise<any> {
  try {
    const res = await http.post("/api/v1/user/exist_phone_number", params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
/**
 *
 *  获取可切换的所有团队
 * */
export async function team_addop(data): Promise<any> {
  try {
    const res = await http.post("/api/v1/team/", data);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    return err;
  }
}
