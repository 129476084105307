import VideoHoc from "@/components/business/VideoHoc/VideoHoc";
import { IMaterialFile } from "@/stores/mixCutDetailStore/type";
import { IconClose } from "@arco-design/web-react/icon";
import cutImg from "@/assets/cut-mix-detail/cut.png";
import cutted from "@/assets/cut-mix-detail/cutted.png";
import useMaterialPlayInfo from "@/useStore/mixCutDetail/useMaterialPlayInfo";
import "./VideoItemBlock.less";
import { Popconfirm, Image } from "@arco-design/web-react";
import { observer } from "mobx-react";
import ImgMemo from "../../../ImgMemo/ImgMemo";
import getText from '@/assets/cut-mix-detail/text-analysis-min.png'
import hasText from '@/assets/cut-mix-detail/textHasanalysis-min.png'
import LoadingGIF from "@/components/UI/LoadingGIF";
import {useContext, useEffect, useState} from "react";
import {getBindVideoMaterialStatus} from "@/services/mixCutDetail/mixCutDetail";
import {useParams} from "react-router-dom";
export interface IVideoHocProps {
  data: IMaterialFile;
  handleCloseBtnClick: (id: string) => void;
  handleEditBtnClick: () => void;
  style: React.CSSProperties;
  isCropped?: boolean;
  duration: string;
  toGetText?: (id: string) => void;
  onTextAnalysis?: (data: object) => void;
  showAnalysis?: boolean;
  analysisId: string | number,
  videoStatusList: any[]
}

const VideoItemBlock: React.FC<IVideoHocProps> = observer((props) => {
  const {
    duration,
    data,
    handleCloseBtnClick,
    style,
    handleEditBtnClick,
    isCropped = false,
    onTextAnalysis,
    toGetText,
    showAnalysis = false,
    analysisId,
    videoStatusList
  } = props;
  const { toIdFindMaterialItem } = useMaterialPlayInfo();
  const [showLoading, setShowLoading] = useState(false);
  const showIcon = (id) => {
    if (videoStatusList && videoStatusList.length > 0) {
      return videoStatusList.find(item => item.id === id)?.status === 1
    }
  }
  return (
    <VideoHoc
      disMuted={true}
      url={toIdFindMaterialItem(data?.id)?.url}
      style={{ ...style, position: "relative" }}>
      <div className="video-group-video-container">
        {
          showAnalysis && analysisId === data.id && showLoading && <div className="loading-img">
                <LoadingGIF/>
            </div>
        }
        <Popconfirm
          focusLock
          title="删除提示！"
          content="确认删除此视频素材吗"
          onOk={() => {
            handleCloseBtnClick(data.id);
          }}
          onCancel={() => {
            console.log("取消");
          }}>
          <div className="video-close-btn">
            <IconClose className="close-btn"/>
          </div>
        </Popconfirm>
        <ImgMemo
          key={toIdFindMaterialItem(data.id)?.cover}
          src={toIdFindMaterialItem(data.id)?.cover}
          alt=""
          className="video-item-cover-img"
        />
        {/*
        {isCropped ? (
          <img
            src={cutted}
            alt=""
            className="video-item-cutted"
            onClick={() => {
              handleEditBtnClick();
            }}
          />
        ) : (
          <img
            src={cutImg}
            alt=""
            className="video-item-edit"
            onClick={() => {
              handleEditBtnClick();
            }}
          />
        )} */}
        <div className="duration-tip">{duration}</div>
        {
          showIcon(data.id) ? <img onClick={() => {
            onTextAnalysis(data);
          }} className="getText" src={hasText}/> : <Popconfirm
            focusLock
            title="提示！"
            content="是否解析并提取视频中的台词？"
            onOk={() => {
              setShowLoading(true);
              onTextAnalysis(data);
            }}
            okText="提取台词"
            onCancel={() => {
              console.log("取消");
            }}>
            <img className="getText" src={getText}/>
          </Popconfirm>
        }
      </div>
    </VideoHoc>
  );
});

export default VideoItemBlock;
