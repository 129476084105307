import { Extension } from "@tiptap/core";
import { Plugin } from "prosemirror-state";

const RemoveExtraNewlines = Extension.create({
  name: "removeExtraNewlinesOnCopy",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            copy: (view, event) => {
              const { state } = view;
              const { from, to, empty } = state.selection;

              if (empty) {
                return false; // 如果没有选中内容，则不处理
              }

              // 获取选中的文本内容
              const slice = state.doc.slice(from, to);
              const text =
                slice.content.textBetween(0, slice.content.size, "\n") || "";

              // 移除多余的换行符，例如连续多个换行替换为一个
              const processedText = text.replace(/\n/g, "");

              // 设置处理后的文本到剪贴板
              event.clipboardData.setData("text/plain", processedText);

              // 阻止默认的复制行为
              event.preventDefault();
              return true;
            },
          },
        },
      }),
    ];
  },
});

export default RemoveExtraNewlines;
