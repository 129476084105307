import React, { ReactNode, useContext, useRef, useState } from "react";
import {
  Button, Form, Image, Input,
  Message,
  Popconfirm,
  Popover,
  Select,
  Switch,
  Tooltip,
  Modal
} from "@arco-design/web-react";
import OpenDrawer from "../../../VideoConfig/OpenDrawer/OpenDrawer";
import mute from "@/assets/cut-mix-detail/video-group-mute.png";
import notMute from "@/assets/cut-mix-detail/video-group-not-mute.png";
import editImg from "@/assets/cut-mix-detail/pencil.png";
import deleteImg from "@/assets/cut-mix-detail/delete.png";
import copyingImg from "@/assets/cut-mix-detail/color_title.png";
import lineImg from "@/assets/cut-mix-detail/color_sound.png";
import EditText, {
  IforwardRef,
} from "@/pages/CreationSpace/components/EditText/EditText";
import VideoItemBlock from "./VideoItemBlock/VideoItemBlock";
import Ton from "@/components/Hocicon";
import SelfUploadBlock from "../../../Components/SelfUploadBlock/SelfUploadBlock";
import errorImg from "@/assets/cut-mix-detail/error.png";
import { observer } from "mobx-react";
import useCaption from "@/useStore/mixCutDetail/useCaption";
import loadingSvg from "@/assets/create-space/loading.svg";
import useTitle from "@/useStore/mixCutDetail/useTitle";
import useMixCutDetail from "@/useStore/mixCutDetail/useMixCutDetail";
import captionPlayerImg from "@/assets/cut-mix-detail/caption-playing.gif";
import "./VideoGroupItem.less";
import ConfigStore from '@/stores/mixedConfig/index'
import {
  IMaterailGroupSetting,
  IMaterialFile,
  TypeClipRule,
  TypeSpeed,
} from "@/stores/mixCutDetailStore/type";
import { formatTime } from "@/components/business/VideoHoc/VideoHoc";
import { mixCutDetailContext } from "../../../MixCutDetail";
import { unBindVideoMaterial } from "@/services/mixCutDetail/mixCutDetail";
import useMaterialPlayInfo from "@/useStore/mixCutDetail/useMaterialPlayInfo";
import { useParams } from "react-router-dom";
import useVideoGroup from "@/useStore/mixCutDetail/useVideoGroup";
import Modals from "@/components/UI/Modals";
import {RequestStatus} from "@/requestHooks/common";
import iconImg from "@/assets/create-space/project-modal-icon.png";
import FormItem from "@arco-design/web-react/es/Form/form-item";
import VoLongButton from "@/components/VLButton/VLButton";
const speedSelect = [
  { value: "origin", label: "原始时长" },
  { value: "follow_audio", label: "随音频变速" },
];

const ClipRuleSelect = [
  { value: "auto", label: "智能混剪" },
  { value: "single_times", label: "单次混剪" },
  { value: "single_video", label: "单视频混剪" },
];

export interface IVideoGroupItemProps {
  sencesIndex: number;
  error?: string;
  active: boolean;
  handleHeaderClick: () => void;
  name: string;
  materials: IMaterialFile[];
  materialMute: boolean;
  disabled: boolean;
  speed: string;
  clipRule: string;
  hanldeMaterialChange: (materials: IMaterialFile[]) => void;
  handleNameChange: (name: string) => void;
  handleSetting: (settings: IMaterailGroupSetting) => void;
  handleDeleteBtnClick: () => void;
  handleTitleBtnClick: () => void;
  handleCaptionBtnClick: () => void;
  handleUploadBtnClick: (type: "material" | "local") => void;
  handleEditBtnClick: (index: number) => void;
  onClick: () => void;
  // handlePlayerCaption: () => void;
  isDragging: boolean;
  dragHandleProps?: any;
  // handlePlayerCaptionStop: () => void;
  toGetText?: (id: string) => void;
  onTextAnalysis?: (data: object) => void;
  showAnalysis: boolean;
  analysisId: string | number,
  videoStatusList: any,
}

const toMaterialsGetDurationRange = (data: IMaterialFile[]): ReactNode => {
  if (!data || data.length === 0) {
    return null;
  }
  if (data && data.length === 1) {
    return (
      <>
        {`${formatTime(data[0].duration)}`}
        <span>s</span>
      </>
    );
  }
  if (data && data.length > 1) {
    let newData = [...data];
    newData = newData.sort((a, b) => a.duration - b.duration);
    return (
      <>
        {formatTime(newData[0].duration)}
        <span>s</span>~{formatTime(newData[newData.length - 1].duration)}
        <span>s</span>
      </>
    );
  }
};

const VideoGroupItem: React.FC<IVideoGroupItemProps> = observer((props) => {
  const {
    error,
    active,
    handleHeaderClick,
    name,
    materials,
    materialMute,
    disabled,
    speed,
    clipRule,
    handleNameChange,
    handleSetting,
    handleDeleteBtnClick,
    handleTitleBtnClick,
    handleCaptionBtnClick,
    hanldeMaterialChange,
    handleUploadBtnClick,
    handleEditBtnClick,
    isDragging,
    dragHandleProps,
    onClick,
    // handlePlayerCaption,
    sencesIndex,
    // handlePlayerCaptionStop,
    toGetText,
    onTextAnalysis,
    showAnalysis,
    analysisId,
    videoStatusList
  } = props;
  const { titleList } = useTitle();
  const { global } = useMixCutDetail();
  const [openState, setOpenState] = useState(true);
  const { toIdGetIsRepeat } = useVideoGroup();
  const { id: projectId } = useParams();
  const { toIdDeleteMaterailPlayInfo } = useMaterialPlayInfo();
  const EditTextRef = useRef<IforwardRef>(null);
  const { projectCaption } = useCaption();
  const [textVisible, setTextVisible] = useState(true);
  const [textContent, setTextContent] = useState("");
  const editSetting = (key, state) => {
    try {
      handleSetting({
        material_mute: materialMute,
        disabled: disabled,
        speed: speed as TypeSpeed,
        clip_rule: clipRule as TypeClipRule,
        [key]: state,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getTitleCopy = () => {
    if (!global) {
      const titles = (titleList || [])[sencesIndex]?.content
        .map((item) => item.text)
        .join(",");
      return titles || "文案标题";
    }
    return "文案标题";
  };

  const deleteVideo = async (id: string) => {
    const newMaterials = materials.filter((item) => item.id != id);
    if (!toIdGetIsRepeat(id)) {
      toIdDeleteMaterailPlayInfo(id);
      try {
        await unBindVideoMaterial(Number(projectId), Number(id));
      } catch (e) {
        console.log(e);
      }
    }
    hanldeMaterialChange(newMaterials);
  };

  return (
    <div
      onClick={() => {
        onClick();
      }}
      className={`${active ? "active" : ""} video-group-item-wrapper ${error ? "error" : ""}`}>
      <div
        className={`video-group-item-header`}
        {...dragHandleProps} // 应用 dragHandleProps 到 header
        onClick={(e) => {
          if (isDragging) return false;
          if (handleHeaderClick) {
            handleHeaderClick();
            // 使当前页面上所有聚焦的元素失去焦点
            if (
              document.activeElement &&
              document.activeElement !== document.body
            ) {
              (document.activeElement as any).blur();
            }
          }
        }}>
        <div className="name-wrapper">
          <OpenDrawer state={openState} handleStateChange={setOpenState} />
          <EditText
            disabed={true}
            ref={EditTextRef}
            name={name}
            handleInputEndEnter={(e) => {
              if (e.length === 0) {
                Message.error("请填写镜头组名称");
                return;
              }
              if (e.length > 20) {
                Message.error("镜头组名称不能超过20个字");
                return;
              }
              handleNameChange(e);
              EditTextRef.current.toggleIsEdit();
            }}
            style={{ width: "auto" }}
          />
          ({materials.length})
          {error ? (
            <Popover
              className={"video-popover-error"}
              trigger="hover"
              content={
                <p className="content">
                  <img src={errorImg} style={{ width: '20px', height: '20px' }} />
                  <div className="error-message">{error}</div>
                </p>
              }>
              <img src={errorImg} style={{ width: '20px', height: '20px' }} />
            </Popover>
          ) : (
            <></>
          )}
        </div>
        <div className="opeator">
          <Tooltip position="top" trigger="hover" content="编辑镜头名称">
            <img
              src={editImg}
              className="hover-display-img"
              style={{ width: 20, marginRight: 13 }}
              alt=""
              onClick={(e) => {
                EditTextRef.current.toggleIsEdit();
                e.stopPropagation();
              }}
            />
          </Tooltip>

          {!materialMute ? (
            <Tooltip position="top" trigger="hover" content="静音">
              <img
                src={mute}
                className="hover-display-img"
                style={{ width: 20, marginRight: 13 }}
                alt=""
                onClick={(e) => {
                  editSetting("material_mute", true);
                  e.stopPropagation();
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip position="top" trigger="hover" content="解除静音">
              <img
                src={notMute}
                className="hover-display-img"
                style={{ width: 20, marginRight: 13 }}
                alt=""
                onClick={(e) => {
                  editSetting("material_mute", false);
                  e.stopPropagation();
                }}
              />
            </Tooltip>
          )}
          <Popconfirm
            focusLock
            title="删除提示！"
            content="确认删除此镜头组吗"
            onOk={(e) => {
              e.stopPropagation();
              handleDeleteBtnClick();
            }}
            onCancel={(e) => {
              e.stopPropagation();
              console.log("取消");
            }}>
            <Tooltip position="top" trigger="hover" content="删除">
              <img
                onClick={(e) => {
                  e.stopPropagation();
                }}
                src={deleteImg}
                className="hover-display-img"
                style={{ width: 20, marginRight: 13 }}
                alt=""
              />
            </Tooltip>
          </Popconfirm>
          <Tooltip position="top" trigger="hover" content="分镜状态">
            <Switch
              style={{ marginRight: 15 }}
              size={"small"}
              checked={!ConfigStore.scenes[sencesIndex].setting.disabled}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                if (!e) {
                  Message.warning("您已设置该分镜不参与混剪！");
                }
                ConfigStore.scenes[sencesIndex].setting.disabled = !e;
                editSetting("disabled", !e); // 替换store
              }}></Switch>
          </Tooltip>

          <div className="duration-range">
            {toMaterialsGetDurationRange(materials)}
          </div>
        </div>
      </div>
      <div
        className={`${openState ? "" : "hidden"} video-group-item-container`}>
        {materials.length > 14 ? (
          <></>
        ) : (
          <Ton
            style={{ width: 126, height: 161 }}
            contel={
              <SelfUploadBlock style={{ width: "100%", height: "100%" }} />
            }>
            <Ton.Item>
              <Button
                onClick={() => {
                  handleUploadBtnClick("material");
                }}
                type="outline"
                style={{
                  border: "none",
                  background: "white",
                  width: 88,
                  textAlign: "center",
                  marginBottom: 10,
                  padding: 0,
                }}>
                素材库导入
              </Button>
            </Ton.Item>
            {/*<Ton.Item>*/}
            {/*  <Button*/}
            {/*    type="outline"*/}
            {/*    onClick={() => {*/}
            {/*      handleUploadBtnClick("local");*/}
            {/*    }}*/}
            {/*    style={{ border: "none", background: "white", width: 88 }}>*/}
            {/*    本地上传*/}
            {/*  </Button>*/}
            {/*</Ton.Item>*/}
          </Ton>
        )}

        <div className="video-group-material-wrapper">
          {materials.map((item, index) => {
            return (
              <VideoItemBlock
                videoStatusList={videoStatusList}
                toGetText={toGetText}
                onTextAnalysis={onTextAnalysis}
                showAnalysis={showAnalysis}
                analysisId={analysisId}
                isCropped={item.is_cropped}
                handleEditBtnClick={() => {
                  handleEditBtnClick(index);
                }}
                duration={formatTime(item.duration)}
                key={item.media_id}
                data={item}
                handleCloseBtnClick={(id: string) => {
                  deleteVideo(id);
                }}
                style={{
                  width: "125px",
                  height: "160px",
                  marginLeft: 15,
                  borderRadius: 8,
                  overflow: "hidden",
                }}></VideoItemBlock>
            );
          })}
        </div>
      </div>
      <div className="video-group-item-footer">
        <div className="line-title">
          <div
            className="item"
            onClick={() => {
              handleCaptionBtnClick();
            }}>
            <img src={lineImg} style={{ width: 14, marginRight: 12 }} />
            {/* {isLoading ? (
              <>
                <img
                  src={loadingSvg}
                  alt=""
                  style={{ width: 18, height: 18 }}
                />
              </>
            ) : isPlaying ? (
              <img
                onClick={() => {
                  handlePlayerCaptionStop();
                }}
                src={captionPlayerImg}
                style={{ width: 18, marginRight: 12 }}
              />
            ) : (
              <img
                onClick={() => {
                  handlePlayerCaption();
                }}
                src={lineImg}
                style={{ width: 18, marginRight: 12 }}
              />
            )} */}

            <div className="item-container">
              {((projectCaption || [])[sencesIndex] || [])[0]?._preview &&
              (projectCaption || [])[sencesIndex][0]?._preview?.length > 0
                ? (projectCaption || [])[sencesIndex][0]?._preview
                : "台词文案"}
            </div>
          </div>
          <div
            className="item"
            style={{ marginLeft: 10 }}
            onClick={() => {
              handleTitleBtnClick();
            }}>
            <img src={copyingImg} style={{ width: 14, marginRight: 12 }} />
            <div className="item-container">{getTitleCopy()}</div>
          </div>
        </div>
        <div className="opeator-bar">
          <Select
            className="vlong-select"
            size="small"
            value={speed}
            onChange={(e) => {
              editSetting("speed", e);
            }}
            style={{ width: 126, marginRight: 10 }}
            placeholder="智能混剪">
            {speedSelect.map((item) => {
              return (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
          <Select
            style={{ width: 106 }}
            size="small"
            value={clipRule}
            onChange={(e) => {
              editSetting("clip_rule", e);
            }}
            className="vlong-select"
            placeholder="智能混剪">
            {ClipRuleSelect.map((item) => {
              return (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>
    </div>
  );
});

export default VideoGroupItem;
