import http from "@/utils/http";

export async function getEditingSticker(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/editing/sticker", { params });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function getEditingCategory(): Promise<any> {
  try {
    const res = await http.get("/api/v1/editing/category");
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 查询播放信息
export async function getMediaInfoPlay(params): Promise<any> {
  try {
    const res = await http.get("/api/v1/space/media/info/play", { params });
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 获取工程素材列表
export async function getSpaceMaterials(): Promise<any> {
  try {
    const res = await http.get(`/api/v1/space/session/1/materials`);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}
// 获取工程媒体列表
export async function getSpaceMedias(params): Promise<any> {
  try {
    const res = await http.get(`/api/v1/space/session/${params}/medias`);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 单合成接口
export async function getVideoCompose(params): Promise<any> {
  try {
    const res = await http.post(`/api/v1/space/session/video/compose`, params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 修改timeline
export async function setEditingTimeline(params): Promise<any> {
  const id = params.id;
  delete params.id;
  try {
    const res = await http.put(`/api/v1/editing/timeline/${id}`, params);
    if (res) {
      return res;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}

// 剪辑字体列表
export async function getEditingFonts(): Promise<any> {
  try {
    const res = await http.get(`/api/v1/editing/assets/fonts`);
    if (res) {
      return res.data;
    } else {
      console.log("接口错误");
    }
  } catch (err) {
    console.log(err);
  }
}
