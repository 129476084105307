import { makeAutoObservable } from "mobx";
class CommonStores {
  public timbreList = [];
  constructor() {
    makeAutoObservable(this);
  }

  public setTimbreList (timbreList) {
    this.timbreList = timbreList;
  }
}

const commonStore = new CommonStores();
export default commonStore;
