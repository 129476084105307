import { useEffect, useState } from "react";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../UIComponents/VideoConfigCard/VideoConfigCard";
import OpenDrawer from "../../VideoConfig/OpenDrawer/OpenDrawer";
import BgMusicTabs from "./BgMusicTabs/BgMusicTabs";
import SmallSearch from "@/pages/VideoClips/MixCutDetail/BgConfig/BgMusicConfig/BgMusicTabs/BgMusicContainer/SmallSearch/SmallSearch";
import {
  IMusicResponse,
  bindingMusic,
  cancelCollectMusic,
  collectMusic,
  deleteCustomizeMusic,
  getBindingVideo,
  getFavoriteMusicList,
  getMusicList,
  toIdGetMusicList,
  toFieldGetMusicList,
} from "@/services/mixCutDetail/mixCutDetail";
import { Message } from "@arco-design/web-react";
import MaterialFileModal from "@/components/business/MaterialFileModal/MaterialFIleModal";
import { toJS } from "mobx";
import allowTypeStore from "@/stores/allowTypeStore";
import { useParams } from "react-router-dom";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import useSimpleReuqest from "@/requestHooks/mixCutDetail/useSimpleRequest";
import ChoseDetailPopover from "@/pages/VideoClips/MixCutDetail/ChoseDetailPopover/ChoseDetailPopover";
import useCoverAndBg from "@/useStore/mixCutDetail/useCoverAndBg";
import {debounce, throttle} from "lodash";
import { observer } from "mobx-react";
import CommonUpload from '@/components/business/CommonUpload'
const customMusicList = [
  {
    name: "收藏音乐",
    collection_id: 0,
  },
  // {
  //   name: "自定义",
  //   collection_id: -1,
  // },
];

const BgMusicConfig: React.FC = () => {
  const { id } = useParams();
  const {
    data: mp3List,
    loading: mp3ListLoadingState,
    handleRequest: getPublicMusic,
  } = useSimpleReuqest<number, IMusicResponse[]>({
    request: toIdGetMusicList,
  });
  const {
    data: favoriteMusicList,
    loading: favoriteMusicLoadingState,
    handleRequest: getFavoriteMusic,
  } = useSimpleReuqest<null, IMusicResponse[]>({
    request: getFavoriteMusicList,
  });
  const [keyWord, setKeyWord] = useState("");
  const [tabList, setTabList] = useState<any[]>([]);
  const [selfMusicList, setSelfMusicList] = useState<IMaterialDetail[]>([]);
  const [activeId, setActiveId] = useState(0);
  const [visibleState, setVisibleState] = useState(true);
  const [uploadVisible, setUploadVisible] = useState(false);
  const { bgAudio, handleBgAudiosChange } = useCoverAndBg();
  const [searchMusic, setSearchMusicList] = useState([]);
  const [searchLoading,setSearchLoading] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);
  const init = async () => {
    setSearchFlag(false)
    const data = await getMusicList();
    setTabList([...customMusicList, ...data]);
    getPublicMusic(data[0].collection_id);
    setActiveId(data[0].collection_id);
    const selfMusicListData = await getBindingVideo(id);
    setSelfMusicList(selfMusicListData);
  };

  const handleMaterialFileModalOkBtnClick = async (e: any) => {
    try {
      const data = await bindingMusic(
        id,
        e.map((item) => Number(item.id)),
      );
      const result = await getBindingVideo(id);
      setSelfMusicList(result);
      setUploadVisible(false);
    } catch (e) {
      console.log(e);
      Message.error("绑定音乐失败！");
    }
  };

  const handleListActiveIdChange = async (e: number) => {
    setSearchFlag(false);
    if (e !== 0 && e !== -1) {
      try {
        getPublicMusic(e);
      } catch (e) {
        console.log(e);
        Message.error("请求音乐列表失败！");
      }
    }
    if (e === 0) {
      getFavoriteMusic(null);
    }
    setActiveId(e);
  };

  const onMusicCollect = async (musicId: number) => {
    try {
      setSearchFlag(false);
      await collectMusic(musicId);
      getPublicMusic(activeId);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelCollectBtnClick = async (musicId: number) => {
    setSearchFlag(false);
    try {
      await cancelCollectMusic(musicId);
      if (activeId === 0) {
        await getFavoriteMusic(null);
        return;
      }
      if (activeId !== -1) {
        await getPublicMusic(activeId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteCustomMusic = async (musiceId: number) => {
    // try {
    //   const deleteId = deleteCustomizeMusic(id, musiceId);
    //   Message.success("删除自定义音乐成功！");
    //   if (deleteId) {
    //     const result = await getBindingVideo(id);
    //     setSelfMusicList(result);
    //   }
    // } catch (e) {
    //   console.log(e);
    //   Message.error("删除自定义音乐失败！");
    // }
    console.log(musiceId);
  };

  const handleCheckStateChange = (e: boolean, id: number) => {
    if (e) {
      const item = (searchFlag ? searchMusic : activeId === 0 ? favoriteMusicList : mp3List).find((item) => item.id === id);
      handleBgAudiosChange([
        ...bgAudio,
        { ...item, duration: item.duration * 1000 },
      ]);
    } else {
      const index = bgAudio.findIndex((item) => item.id === id);
      bgAudio.splice(index, 1);
      handleBgAudiosChange([...bgAudio]);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (keyWord) {
      handleSearchMusic()
    } else {
      setSearchMusicList([])
      handleListActiveIdChange(activeId);
    }
  }, [keyWord]);

  const handleSearchMusic = throttle(() => {
    setSearchLoading(true)
    setSearchFlag(true)
    toFieldGetMusicList(keyWord).then(res => {
      setSearchMusicList(res)
      setSearchLoading(false)
    }).catch(e => {
      setSearchLoading(false)
    })
  }, 1000)

  return (
    <VideoConfigCard>
      <VideoConfigCardHeader
        headerLeft={
          <>
            <OpenDrawer
              state={visibleState}
              handleStateChange={(e) => {
                setVisibleState(e);
              }}></OpenDrawer>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setVisibleState(!visibleState);
              }}>
              背景音乐
            </span>
          </>
        }
        headerRight={<>
          <div className="flex--between--center">
            <SmallSearch value={keyWord} onChange={setKeyWord} style={{ marginRight: '10px' }} />
            <ChoseDetailPopover
              showField={"title"}
              handleTagCloseBtnClick={(e) => {
                handleCheckStateChange(false, e as number);
              }}
              handleClearBtnClick={() => {
                handleBgAudiosChange([]);
              }}
              opeatorFiled={"id"}
              data={bgAudio}
              title={"背景音乐"}
            />
          </div>
        </>
      }></VideoConfigCardHeader>
      <VideoConfigCardContainer style={{ padding: 0, overflow: "hidden" }}>
        {visibleState ? (
          <BgMusicTabs
            favoriteLoading={favoriteMusicLoadingState}
            publicLoading={mp3ListLoadingState}
            searchLoading={searchLoading}
            handleCancelCollectBtnClick={(id: number) => {
              handleCancelCollectBtnClick(id);
            }}
            handleDeleteCustomMusicItem={(id: number) => {
              deleteCustomMusic(id);
            }}
            handleMusicCollectBtcClick={(id: number) => {
              onMusicCollect(id);
            }}
            selfMusicList={selfMusicList}
            list={tabList}
            musicList={ searchFlag ? searchMusic : activeId === 0 ? favoriteMusicList : mp3List}
            favoriteMusicList={favoriteMusicList}
            activeId={activeId}
            handleListActiveIdChange={(e) => {
              handleListActiveIdChange(e);
            }}
            handleSelfUploadBlockBtnClick={() => {
              setUploadVisible(true);
            }}
          />
        ) : (
          <></>
        )}
      </VideoConfigCardContainer>
      <CommonUpload
        onCancel={() => {
          setUploadVisible(false);
        }}
        allowType={toJS(allowTypeStore.getAllMusicTypeString())}
        visible={uploadVisible}
        allowMetarial={[2, 4]}
        maxCollectionLength={20}
        defaultCollection={[]}
        handleOkBtnClick={(e) => {
          handleMaterialFileModalOkBtnClick(e);
        }}
      />
    </VideoConfigCard>
  );
};

export default observer(BgMusicConfig);
