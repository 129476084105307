// extensions/CustomBackspace.ts
import { Extension } from "@tiptap/core";
import { Editor } from "@tiptap/react";

const CustomBackspace = Extension.create({
  name: "customBackspace",

  addKeyboardShortcuts() {
    return {
      Backspace: ({editor}) => {
        const { state } = editor.view;
        const { $from, empty } = state.selection;

        // 仅在无选区时处理
        if (!empty) return false;

        // 向上遍历节点树，查找是否在 listItem 内
        let node = $from.node();
        let depth = $from.depth;
        let isInListItem = false;

        while (depth > 0) {
          node = $from.node(depth);
          if (node.type.name === "listItem") {
            isInListItem = true;
            break;
          }
          depth--;
        }

        if (!isInListItem) return false;

        // 检查 listItem 是否为空
        const hasOnlyEmptyParagraph =
          node.content.childCount === 0 ||
          (node.content.childCount === 1 &&
            node.firstChild?.type.name === "paragraph" &&
            node.firstChild.content.size === 0);

        if (hasOnlyEmptyParagraph) {
          // 阻止默认的退格行为
          // 可选：在此处执行自定义逻辑，例如将空的 listItem 转换为段落
          editor.commands.setNode('paragraph');
          return true;
        }

        // 允许默认的退格行为
        return false;
      },
    };
  },
});

export default CustomBackspace;
