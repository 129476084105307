import { ISences, TypeClipRule, TypeSpeed } from "./type";
import { AlignmentType, GlobalCaption, ICaption } from "./type/caption";
import { ITitleContentItem, ITitleItem } from "./type/title";
import { ITransitionItem } from "./type/transition";
import commonStore from "@/stores/common";
export const initISencesSetting = {
  material_mute: false,
  disabled: false,
  speed: "origin" as TypeSpeed,
  clip_rule: "auto" as TypeClipRule,
};

export const initISencesItem: Omit<ISences, "id"> = {
  name: "镜头名称",
  setting: {
    ...initISencesSetting,
  },
  materials: [],
};

export const initCaptionItem: Omit<ICaption, "id"> = {
  content: [],
  _content_json: null,
  _preview: "",
  text_setting: {
    font: "Microsoft YaHei",
    font_size: 30,
    color: "#ffffff",
    background_color: "",
    bold: false,
    italic: false,
    alignment: "center",
    is_global: false,
    effect: {},
    x: 0.5,
    y: 0.8,
    angle: 0,
    outline: 0,
    shadow: 0,
    spacing: 0,
    line_spacing: 0,
  },
  voice_setting: {
    speaker_name: commonStore.timbreList?.length > 0 ? `${commonStore.timbreList[0]?.name}_${commonStore.timbreList[0]?.speaker_style}` : '悦儿_通用女声',
    ssml: true,
    speaker_id: commonStore.timbreList?.length > 0 ? commonStore.timbreList[0]?.speaker_id : "BV001_streaming",
    speed_ratio: 1,
    volume_ratio: 0.7,
    pitch_ratio: 1,
    emotion: "",
    is_global: false,
    default_speed_rate: 0,
    platform: commonStore.timbreList?.length > 0 ? commonStore.timbreList[0]?.platform : "volcengine" ,
    lang: commonStore.timbreList?.length > 0 ? commonStore.timbreList[0]?.lang : "cn",
    gender: commonStore.timbreList?.length > 0 ? commonStore.timbreList[0]?.gender : 1,
  },
};

export const getToSencesInitTransitionItem = (
  transtionIndex: number,
): Record<string, ITransitionItem> => {
  return {
    [transtionIndex.toString()]: {
      duration: 500,
      start_sence: transtionIndex,
      effects: [],
      is_global: false,
    },
  };
};

export const initTitleTextSetting = {
  font: "Microsoft YaHei",
  font_size: 30,
  color: "#ffffff",
  background_color: "",
  bold: false,
  italic: false,
  alignment: "center" as AlignmentType,
  effect: {},
  start: 0,
  outline: 0,
  shadow: 0,
  spacing: 0,
  line_spacing: 0,
  is_global: false,
};

export const toIndexGetInitTitleTextSetting = (index: number) => {
  return {
    ...initTitleTextSetting,
    x: 0.5,
    y: 0.1 * (index + 1),
    angle: 0,
    start: 0,
  };
};

export const getInitGlobalCaption = (): GlobalCaption => {
  return [
    {
      id: Math.floor(Date.now() + Math.random() * 10000),
      content: [],
      _content_json: null,
      _preview: "",
      text_setting: {
        is_global: false,
        font: "Microsoft YaHei",
        font_size: 30,
        color: "#ffffff",
        background_color: "",
        bold: false,
        italic: false,
        alignment: "center",
        effect: {},
        x: 0.5,
        y: 0.8,
        angle: 0,
        outline: 0,
        shadow: 0,
        spacing: 0,
        line_spacing: 0,
      },
      voice_setting: {
        speaker_name: "",
        speaker_id: "",
        speed_ratio: 1,
        volume_ratio: 0.7,
        pitch_ratio: 1,
        emotion: "",
        ssml: true,
        default_speed_rate: 0,
        platform: '',
        lang: '',
        gender: 1
      },
    },
  ];
};
