import React, { useEffect, useRef, useState } from "react";
import { Image, Menu, Message, Popover } from "@arco-design/web-react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IconCaretDown,
  IconLeft,
  IconRight,
} from "@arco-design/web-react/icon";
import _ from "lodash";
import cs from "classnames";
import "./index.less";
import { toJS } from "mobx";
import RolePermission, {
  getUserPermissions,
} from "@/components/business/RolePermission";
import User from "@/stores/userStore";
import { coming_soon_icon } from "@/assets/user-center";
const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
type Props = {
  menuList: {
    id: string;
    title: string;
    path?: string;
    icon: string[];
    hot: boolean;
    font?: boolean;
    permissions?: string;
    children?: {
      id: string;
      subTitle: string;
      path: string;
      power?: boolean;
      font?: boolean;
      permissions?: string;
    }[];
  }[];
};
function Index(props: Props) {
  const { menuList } = props;
  const [collapse, setCollapse] = useState(true);
  const navigate = useNavigate();
  const locations = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const toPathFun = (item) => {
    if (
      User?.teamInfo?.team_user_role?.status == 0 &&
      User?.teamInfo?.team_user_role?.expired_at == 0 &&
      !User.isExpired_at_package
    ) {
      User.setExclusiveModal();
      return;
    }
    if (item.path != "/") {
      if (item?.blank) {
        window.open(item.path, "_blank");
      } else {
        navigate(item.path);
      }
    } else {
      Message.info({
        icon: <img width={26} src={coming_soon_icon} />,
        content: "敬请期待呦~",
      });
    }
  };
  const getItemsByPath = (targetPath) => {
    return menuList
      .filter((item) => {
        // 首先检查当前项是否有path属性并且与目标路径匹配
        if (item.path === targetPath) {
          return true;
        }
        // 如果当前项有children数组，则递归检查children
        if (item.children && item.children.length > 0) {
          return item.children.some((child) => child.path === targetPath);
        }
        // 如果没有匹配，返回false
        return false;
      })
      .map((item) => {
        // 如果匹配的是父项直接返回，如果是子项则返回子项对象
        if (item.path === targetPath) {
          return item;
        } else {
          return item.children.find((child) => child.path === targetPath);
        }
      });
  };
  useEffect(() => {
    if (locations.pathname !== "/") {
      const path = locations.pathname.split("/")[1];
      const item: any = getItemsByPath(`/${path}`)[0];
      if (!item) {
        return;
      }
      setOpenKeys([item.id?.split("-") ? item.id.split("-")[0] : ""]);
      setSelectedKeys([item.id]);
    }
  }, [locations]);
  useEffect(() => {
    User.setLogoWidth(collapse ? 107 : 166);
  }, [collapse]);
  const content = (item, index) => {
    if (item.children && item.children.length) {
      return (
        <div className="menu-item-c-collapse">
          {item.children.map((v, i) => {
            if (v.path != "/creation-script") {
              return (
                <RolePermission.Unclickable
                  key={i}
                  mixture={item.title == "团队管理" ? true : false}
                  requiredPermissions={v.permissions}>
                  <div
                    className="menu-item-c-collapse-item"
                    onClick={() => {
                      if (
                        User?.teamInfo?.team_user_role?.status == 0 &&
                        User?.teamInfo?.team_user_role?.expired_at == 0 &&
                        !User.isExpired_at_package
                      ) {
                        User.setExclusiveModal();
                        return;
                      }
                      if (v.path != "/") {
                        navigate(v.path);
                        if (v?.blank) {
                          window.open(v.path, "_blank");
                        } else {
                          navigate(v.path);
                        }
                      } else {
                        Message.info({
                          icon: <img width={26} src={coming_soon_icon} />,
                          content: "敬请期待呦~",
                        });
                      }
                    }}>
                    <div
                      style={{ background: v.icon.color }}
                      className="menu-item-c-collapse-svg">
                      <img src={v.icon.svg} width={20} />
                    </div>
                    <p
                      style={{
                        color: selectedKeys[0] == v.id ? "#3662ec" : "",
                      }}
                      className="menu-item-c-collapse-text">
                      {v.subTitle}
                    </p>
                  </div>
                </RolePermission.Unclickable>
              );
            }
          })}
        </div>
      );
    }
  };
  const handleCollapseOnClick = (opt) => {
    if (opt.children && opt.children.length) {
      return;
    }
    if (
      User?.teamInfo?.team_user_role?.status == 0 &&
      User?.teamInfo?.team_user_role?.expired_at == 0 &&
      !User.isExpired_at_package
    ) {
      User.setExclusiveModal();
      return;
    }
    if (opt.path != "/") {
      if (opt?.blank) {
        window.open(opt.path, "_blank");
      } else {
        navigate(opt.path);
      }
    } else {
      Message.info({
        icon: <img width={26} src={coming_soon_icon} />,
        content: "敬请期待呦~",
      });
    }
  };

  return (
    <div className="menu-root">
      <div
        className="collapse-box"
        onClick={() => {
          setCollapse(!collapse);
        }}>
        {collapse ? <IconRight /> : <IconLeft />}
      </div>
      {collapse ? (
        <div className="menu-container-collapse">
          {menuList.map((item, index) => {
            return (
              <RolePermission.Unclickable
                requiredPermissions={item.permissions}
                mixture={item.title == "团队管理" ? true : false}
                key={index}>
                <div
                  className="menu-item-collapse"
                  style={{
                    marginTop: item.id == "6" ? "auto" : 0,
                    position: item.id == "6" ? "relative" : "static",
                    top: item.id == "6" ? 16 : 0,
                  }}
                  onClick={(e) => {
                    handleCollapseOnClick(item);
                    e.stopPropagation();
                    e.preventDefault();
                  }}>
                  <Popover position="right" content={content(item, index)}>
                    <div className="menu-item-collapse-text">
                      <div className="menu-icon">
                        <Image
                          src={
                            item.icon[
                              selectedKeys[0]?.split("-")
                                ? selectedKeys[0].split("-")[0] == item.id
                                  ? 1
                                  : 0
                                : selectedKeys[0] == item.id
                                  ? 1
                                  : 0
                            ]
                          }
                          preview={false}
                          width={26}
                        />
                        {item.hot && (
                          <div className="menu-hot">
                            <span>HOT</span>
                          </div>
                        )}
                      </div>
                      <p
                        style={{
                          color: selectedKeys[0]?.split("-")
                            ? selectedKeys[0].split("-")[0] == item.id
                              ? "#3662ec"
                              : ""
                            : selectedKeys[0] == item.id
                              ? "#3662ec"
                              : "",
                        }}
                        className={cs(item.font ? "DOUYU-text" : "")}>
                        {item.title}
                      </p>
                    </div>
                  </Popover>
                </div>
              </RolePermission.Unclickable>
            );
          })}
        </div>
      ) : (
        <div
          className="menu-container"
          style={{ width: !collapse ? 166 : 106 }}>
          <Menu
            theme="light"
            icons={{
              horizontalArrowDown: <IconCaretDown />,
            }}
            accordion
            collapse={collapse}
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onClickSubMenu={(key: string, event, keyPath: string[]) => {
              setOpenKeys(keyPath);
            }}
            onClickMenuItem={(key: string) => {
              setSelectedKeys([key]);
            }}>
            {menuList && menuList.length
              ? menuList.map((item) => {
                  if (item.children && item.children.length) {
                    return (
                      <SubMenu
                        className="layout-subMenu"
                        key={item.id}
                        style={{
                          display:
                            !(User.teamInfo?.team_user_role?.roles || []).some(
                              (v) => v.code == "superadmin",
                            ) && item.title == "团队管理"
                              ? "none"
                              : "flex",
                        }}
                        title={
                          <div
                            className={cs(!collapse ? "menu-flex" : "")}
                            style={{ textAlign: "center" }}>
                            <div
                              className={cs(
                                "menu-icon",
                                !collapse ? "menu-flex" : "",
                              )}>
                              <Image
                                src={
                                  selectedKeys[0]?.split("-")[0] == item.id
                                    ? item.icon[1]
                                    : item.icon[0]
                                }
                                preview={false}
                                width={26}
                              />
                              {item.hot && (
                                <div className="menu-hot">
                                  <span>HOT</span>
                                </div>
                              )}
                            </div>
                            <div
                              className={cs(
                                !collapse ? "menu-flex" : "",
                                item.font ? "DOUYU-text" : "",
                              )}>
                              {item.title}
                            </div>
                          </div>
                        }>
                        {_.get(item, "children", []).map((v) => {
                          if (v.power) {
                            return;
                          }
                          return (
                            <RolePermission.Unclickable
                              key={v.id}
                              requiredPermissions={v.permissions}>
                              <MenuItem
                                className="menu-item"
                                key={v.id}
                                onClick={() => {
                                  setTimeout(() => {
                                    toPathFun(v);
                                  });
                                }}>
                                {v.subTitle}
                              </MenuItem>
                            </RolePermission.Unclickable>
                          );
                        })}
                      </SubMenu>
                    );
                  } else {
                    return (
                      <RolePermission.Unclickable
                        requiredPermissions={item.permissions}
                        key={item.id}>
                        <div
                          className={`layout-subMenu`}
                          style={{
                            marginTop: item.id == "6" ? "auto" : 0,
                            paddingTop: item.id == "6" ? 16 : 0,
                          }}>
                          <MenuItem
                            key={item.id}
                            className={cs(
                              !collapse ? "menu-flex" : "menu-item no-children",
                            )}
                            onClick={() => {
                              setOpenKeys([]);
                              setSelectedKeys([item.id]);
                              setTimeout(() => {
                                toPathFun(item);
                              });
                            }}>
                            <div
                              className={cs(
                                "menu-icon",
                                !collapse ? "menu-flex" : "",
                              )}>
                              <Image
                                src={
                                  item.icon[selectedKeys[0] == item.id ? 1 : 0]
                                }
                                preview={false}
                                width={26}
                                style={{ marginLeft: !collapse ? 12 : 0 }}
                              />
                              {item.hot && (
                                <div className="menu-hot">
                                  <span>HOT</span>
                                </div>
                              )}
                            </div>
                            <div
                              className={cs(
                                !collapse ? "menu-flex" : "",
                                item.font ? "DOUYU-text" : "",
                              )}>
                              <span>{item.title}</span>
                            </div>
                          </MenuItem>
                        </div>
                      </RolePermission.Unclickable>
                    );
                  }
                })
              : ""}
          </Menu>
        </div>
      )}
    </div>
  );
}

export default Index;
