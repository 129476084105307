/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-case-declarations */
import { get } from "lodash";
import { GetMediaInfo, GetEditingProjectMaterials } from "./mock";
import {
  getEditingCategory,
  getEditingFonts,
  getEditingSticker,
  getMediaInfoPlay,
  getSpaceMaterials,
  getSpaceMedias,
  getVideoCompose,
  setEditingTimeline,
} from "@/services/videoEditing/video";
import VideoRes from "@/stores/VideoResult";
import { toJS } from "mobx";
import { toIdGetEngineer } from "@/services/createSpace/engineering";
import { Message } from "@arco-design/web-react";
import User from "@/stores/userStore";

/**
 * 将服务端的素材信息转换成 WebSDK 需要的格式
 */

export function transMediaList(data) {
  if (!data) return [];

  if (Array.isArray(data)) {
    return data.map((item) => {
      const basicInfo = item.MediaBasicInfo;
      const fileBasicInfo = item.FileInfoList[0].FileBasicInfo;
      const mediaId = basicInfo.MediaId;
      const result = {
        mediaId,
        mediaType: basicInfo.MediaType,
      };

      switch (result.mediaType) {
        case "video":
          result.video = {
            title: fileBasicInfo.FileName,
            duration: Number(fileBasicInfo.Duration),
            coverUrl: basicInfo.CoverURL,
            // 源视频的宽高、码率等数据，用于推荐合成数据，不传入或是0时无推荐数据
            width: Number(fileBasicInfo.Width) || 0,
            height: Number(fileBasicInfo.Height) || 0,
            bitrate: Number(fileBasicInfo.Bitrate) || 0,
          };
          const spriteImages = basicInfo.SpriteImages;
          if (spriteImages) {
            try {
              const spriteArr = JSON.parse(spriteImages);
              const sprite = spriteArr[0];
              const config = JSON.parse(sprite.Config);
              result.video.spriteConfig = {
                num: config.Num,
                lines: config.SpriteSnapshotConfig?.Lines,
                cols: config.SpriteSnapshotConfig?.Columns,
                cellWidth: config.SpriteSnapshotConfig?.CellWidth,
                cellHeight: config.SpriteSnapshotConfig?.CellHeight,
              };
              result.video.sprites = sprite.SnapshotUrlList;
            } catch (e) {
              console.log(e);
            }
          }
          break;
        case "audio":
          result.audio = {
            title: fileBasicInfo.FileName,
            duration: Number(fileBasicInfo.Duration),
            coverURL: "", // 您可以给音频文件一个默认的封面图
          };
          break;
        case "image":
          result.image = {
            title: fileBasicInfo.FileName,
            coverUrl: fileBasicInfo.FileUrl,
            // 图片的宽高等数据，用于推荐合成数据，不传入或是0时无推荐数据
            width: Number(fileBasicInfo.Width) || 0,
            height: Number(fileBasicInfo.Height) || 0,
          };
          break;
        default:
          break;
      }

      return result;
    });
  } else {
    return [data];
  }
}

export function createEditor({
  container,
  locale = "zh-CN",
  mode = "project",
}) {
  // console.log("version: ", window.AliyunVideoEditor.version);
  const config = {
    // 模板模式 参考模板模式接入相关文档：https://help.aliyun.com/document_detail/453481.html?spm=a2c4g.453478.0.0.610148d1ikCUxq
    mode: mode,
    // 默认字幕文案
    defaultSubtitleText: "默认文案",
    licenseConfig: {
      rootDomain: "zxaigc.com",
      licenseKey: "zPtL9R8wGPumTmq2C434ab2bc0b5341ea8f7703afef6793a8",
    },
    // 自定义画布比例
    defaultAspectRatio: "9:16",
    // 自定义画布比例列表
    customAspectRatioList: [
      "1:1",
      "2:1",
      "4:3",
      "3:4",
      "9:16",
      "16:9",
      "21:9",
      "16:10",
    ],
    // 自定义按钮文案
    customTexts: {
      updateButton: "保存",
      produceButton: "合成",
      // logoUrl: 'https://www.example.com/assets/example-logo-url.png' 自定义logo
    },
    // 自定义人声
    // customVoiceGroups,
    // 自定义字体
    // customFontList: customFontList,
    // 页面容器
    container: container,
    // 多语言
    // zh-CN:中文  默认值
    // en-US:英文
    locale: locale,
    // 媒资库默认情况下播放地址会过期，所以需要动态获取
    useDynamicSrc: true,
    // 获取动态播放地址，只和播放有关
    getDynamicSrc: async (mediaId, mediaType, mediaOrigin, InputURL) => {

      if (mediaOrigin === "mediaURL") {
        return mediaId;
      }
      // const res = await getMediaInfoPlay({ media_id: mediaId });

      var body;
      switch (mediaOrigin) {
        case "mediaId":
          return mediaId;
        case "mediaURL":
          return mediaId;
        // case "public":
        // break;
        default:
          switch (mediaType) {
            case "video":
            case "audio":
              body = await fetch(
                "/api/v1/space/media/info/play?media_id=" + mediaId,
              ).then((res) => res.json());
              break;
            default:
              body = await fetch(
                "/api/v1/space/media/info?media_id=" + mediaId,
              ).then((res) => res.json());
              break;
          }
      }
      const fileInfoList = get(body, "data.play_info_list", []);
      if (fileInfoList.length === 0) {
        return "";
      }
      // 这里可以选择使用不同分辨率的文件
      // let targetFile = fileInfoList.find(
      //   (item) => item.FileBasicInfo?.FileType === "transcode_file",
      // );
      // if (!targetFile) {
      //   targetFile = fileInfoList[0];
      // }
      return fileInfoList[0]["play_url"];
    },
    // 获取剪辑工程关联素材
    getEditingProjectMaterials: async () => {
      const res = await getSpaceMedias(
        window.location.pathname.split("/").pop() || 0,
      );
      return transMediaList(res.data);
    },
    // 资源库导入素材按钮相应函数
    // searchMedia: async () => {
    //   return [];
    // },
    // // 解绑工程与素材
    // deleteEditingProjectMaterials: async (mediaId, mediaType) => {
    //   return;
    // },
    // 获取工程的时间线
    getEditingProject: async () => {
      const modifiedTime = "2020-06-06T13:12:34Z";
      const timelineString = VideoRes.videoTimeLine;
      const timeline = timelineString.timeline
        ? JSON.parse(timelineString.timeline)
        : undefined;
      return {
        timeline: timeline,
        title: timelineString.title,
        modifiedTime: modifiedTime,
      };
    },
    // 更新工程
    updateEditingProject: async ({ timeline, title }) => {
      let res;
      if (title) {
        res = await setEditingTimeline({
          id: VideoRes.videoTimeLine.id,
          timeline_data: VideoRes.videoTimeLine.timeline,
        });
        VideoRes.setVideoTimeLine({
          ...VideoRes.videoTimeLine,
          title: title,
        });
      }
      if (timeline && Object.keys(timeline).length) {
        res = await setEditingTimeline({
          id: VideoRes.videoTimeLine.id,
          timeline_data: JSON.stringify(timeline),
        });
        VideoRes.setVideoTimeLine({
          ...VideoRes.videoTimeLine,
          timeline: JSON.stringify(timeline),
          title: timeline.title,
        });
      }
      if (res.code == 0) {
        Message.success("保存成功");
      }
      return Promise.resolve();
    },
    // 合成视频
    produceEditingProjectVideo: async (opt) => {
      VideoRes.setVideoInfo(opt);
      if (
        Object.keys(
          User.dataIntegration?.benefits?.find((v) => {
            return v.benefit_type == 1;
          }),
        ).length
      ) {
        VideoRes.setVideoVisible();
      } else {
        Message.warning("暂无权益");
      }
    },
    // 获取音频地址，用于提升视频的音频波形图绘制速度
    // getAudioByMediaId: (mediaId: string) =>Promise<string>;
    // 获取贴纸分类
    getStickerCategories: async () => {
      const res = await getEditingCategory();
      if (!res.code) {
        return res.data.items.map((v) => ({ ...v, id: `${v.id}` }));
      }
      return [];
    },
    // 获取贴纸
    getStickers: ({ categoryId, page, size }) => {
      return new Promise((resolve, reject) => {
        getEditingSticker({
          page: page,
          page_size: size,
          category_id: Number(categoryId),
        }).then((res) => {
          const fileList = res?.data?.items
            .map((v) => ({
              ...v,
              image_url: `${res.data.base_url}/${v.image_url}`,
            }))
            .map((item) => ({
              mediaId: `${item.media_id}`,
              src: item.image_url,
            }));
          resolve({
            total: res.data.total,
            stickers: fileList,
          });
        });
      });
    },
    customFontList: async () => {
      const resultFonts = await getEditingFonts()
      const { custom, official } = JSON.parse(resultFonts.value)
      return [...official.map(v => v.key), ...custom]
    }
    // 智能字幕
    // submitASRJob: async (mediaId, startTime, duration) => {},
  };
  const init = async () => {
    await config.getStickerCategories();
    await config.customFontList();
    await window.AliyunVideoEditor.init(config);
  };

  return {
    init,
    destroy() {
      window.AliyunVideoEditor.destroy();
    },
  };
}
