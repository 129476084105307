import Header from "./Header/Header";
import PreviewingVideo from "./PreviewingVideo/PreviewingVideo";
import VideoConfig from "./VideoConfig/VideoConfig";
import VideoGroup from "./VideoGroup/VideoGroup";
import React, {
  Profiler,
  createContext,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import useVideoGroup from "@/useStore/mixCutDetail/useVideoGroup";
import useMixCutDetail from "@/useStore/mixCutDetail/useMixCutDetail";
import {
  toIdGetEngineer,
  toIdGetEngineerContent,
} from "@/services/createSpace/engineering";
import useMixCutDetailInit from "@/useStore/mixCutDetail/useMixCutDetailInit";
import VideoGlobalModal from "./TipModal/VideoGlobalModal";
import mixCutDetailCheck from "@/useStore/mixCutDetail/mixCutDetailCheck";
import moment from "moment";
import { toIdGetCustomScript } from "@/services/createSpace/script";
import useParseScript, {
  getCaption,
} from "@/useStore/mixCutDetail/useParseScript";
import usePreviewConfig from "@/useStore/preview/usePreview";
import useJsonToRenderObj from "@/useStore/mixCutDetail/useJsonToRenderObj";
import usePreviewInfo from "@/useStore/mixCutDetail/usePreviewInfo";
import { Button, Message, Modal } from "@arco-design/web-react";
import useMixCutDetailSave from "@/useStore/mixCutDetail/useMixCutDetailSave";
import TextAnalysis from "./Components/TextAnalysis";
import SensitiveDrawer from "./Components/SensitiveDrawer";
import { v4 as uuid } from "uuid";
import "./MixCutDetail.less";
import LoadingGIF from "@/components/UI/LoadingGIF";
import {
  bindingVideo,
  getBindVideoMaterial,
  getBindVideoMaterialStatus,
  getSensitiveWord,
} from "@/services/mixCutDetail/mixCutDetail";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import VoLongButton from "@/components/VLButton/VLButton";
import useCaption from "@/useStore/mixCutDetail/useCaption";
import {
  initCaptionItem,
  toIndexGetInitTitleTextSetting,
} from "@/stores/mixCutDetailStore/init";
import ConfigStore from "@/stores/mixedConfig/index";
import { getSpeech } from "@/services/createSpace/material";
export interface IMixCutDetailContext {
  videoGroupActive: number;
  videoGroupTransitionId: string;
  setVideoGroupActive: React.Dispatch<React.SetStateAction<number>>;
}

export const mixCutDetailContext = createContext<IMixCutDetailContext>(null);

type ActiveType = "transition" | "title" | "caption";
const MixCutDetail = observer(() => {
  const { id, script_id } = useParams();
  const { parseCutsomScript } = useParseScript();
  const { maxCount } = usePreviewInfo();
  const regExp = new RegExp(/[,，.。;；:：?？!！………—、-]/, "g");
  const {
    projectCaption,
    projectSences,
    setProjectSences,
    handleProjectName,
    handleProjectSencesAdd,
    handleProjectSencesDelete,
    handleProjectVideoChange,
    handleChangeVideoItemStartAndDuration,
  } = useVideoGroup();
  const { quickSaveMixCutDetail } = useMixCutDetailSave();
  const { handleToChangeVideoIndexSetTitleAndCaption } = usePreviewConfig();
  const { global, projectMsg, handleProjectMsgSet, toggleGlobaltoItem } =
    useMixCutDetail();
  const { handletoOriginJsonRender } = useJsonToRenderObj();
  const { initAllMixCutDetal } = useMixCutDetailInit();
  const [videoConfigActiveIndex, setVideoConfigActiveIndex] = useState(0);
  const [videoGroupActive, setVideoGroupActive] = useState(0);
  const [videoGroupTransitionId, setVideoGroupTransitionId] =
    useState<string>("0");
  const {
    checkJson,
    confirmCaptionsErrorIsEmpty,
    confirmSencesErrorIsEmpty,
    // combSenceMaterials,
  } = mixCutDetailCheck();
  const [tipItemModalVisible, setTipItemModalVisible] = useState(false);
  const [editorActiveIndex, setEditorActiveIndex] = useState<{
    groupIndex: number;
    activeIndex: number;
    type: ActiveType;
  }>({
    groupIndex: null,
    activeIndex: null,
    type: "" as "title",
  });
  const [textVisible, setTextVisible] = useState(false);
  const [senVisible, setSenVisible] = useState(false);
  const [textContent, setTextContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [analysisId, setAnalysisId] = useState("");
  const [videoStatusList, setVideoStatusList] = useState([]);
  const [total_words, setTotal_words] = useState(0); // 总字数
  const [total_sensitive, setTotal_sensitive] = useState(0); // 总敏感词
  const [sensitiveList, setSensitiveList] = useState([]);
  const [sensitiveData, setSensitiveData] = useState([]);
  const [deleteWord, setDeleteWord] = useState([]);
  const [deleteTitle, setDeleteTitle] = useState([]);
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  // useEffect(() => {
  //   console.log(JSON.stringify(ConfigStore), 'ConfigStore')
  // }, [JSON.stringify(ConfigStore)]);
  const init = async (id: number, script?: number) => {
    try {
      const result = await toIdGetEngineer(id);
      handleProjectMsgSet({
        ...result,
        updated_at: moment
          .unix(result.updated_at)
          .format("YYYY-MM-DD HH:mm") as any,
      });
      if (script) {
        const data = await toIdGetCustomScript(script);
        parseCutsomScript(data);
      } else {
        const contentResult = await toIdGetEngineerContent(id);
        const content = JSON.parse(contentResult);
        if (Object.keys(content || {}).length === 0) {
          initAllMixCutDetal();
        } else {
          handletoOriginJsonRender(content);
          try {
            const videoBindMaterial = await getBindVideoMaterial(id);
            if (
              (videoBindMaterial || []).length === 0 &&
              Object.keys(content?._materials || {}).length > 0
            ) {
              bindingVideo(id, Object.keys(content?._materials || {}) || []);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    } catch (e) {
      if (e === "资源不存在") {
        Message.error("工程已被删除！");
        setTimeout(() => {
          window.close();
        }, 3000);
        return;
      }
      Message.error(e);
      console.log(e);
    }
  };

  useEffect(() => {
    getStatusList();
  }, []);

  const getStatusList = () => {
    getBindVideoMaterialStatus(id).then((res) => {
      setVideoStatusList(res.items || []);
    });
  };

  const handleTipModalOkBtnClick = () => {
    toggleGlobaltoItem();
    setVideoGroupActive(0);
    setVideoConfigActiveIndex(0);
  };

  useEffect(() => {
    if (videoGroupActive === 0 && videoConfigActiveIndex === 2) {
      setVideoConfigActiveIndex(0);
    }
    handleToChangeVideoIndexSetTitleAndCaption(videoGroupActive);
  }, [videoGroupActive]);
  const onRenderCallback = (id, phase, actualDuration) => {
    // console.log(`Render duration: ${actualDuration}ms`);
  };
  const executeBasedOnScope = async (
    groupIndex: number,
    index: number,
    type: ActiveType,
  ) => {
    if (global) {
      setTipItemModalVisible(true);
      setEditorActiveIndex({ groupIndex, activeIndex: index, type });
    } else {
      setVideoConfigActiveIndex(index);
      setVideoGroupActive(groupIndex);

      if (type === "transition") {
        setVideoGroupTransitionId(groupIndex.toString());
      }
    }
  };
  // 确认提取
  const onTextAnalysis = async (data) => {
    setShowAnalysis(true);
    setAnalysisId(data.id);
    getSearchText(data);
  };
  const getSearchText = async (data) => {
    const res = await getSpeech({
      media_id: data.media_id,
      material_id: data.id,
      session_id: Number(id),
    });
    if (Array.isArray(res?.subVoices)) {
      setTextContent(res.subVoices.map((item) => item.text).join(","));
      setShowAnalysis(false);
      setTextVisible(true);
      getStatusList();
    } else {
      setShowAnalysis(false);
      Message.error("未提取到台词");
      return false;
    }
  };
  // 导入台词
  const handleTextAnalysis = () => {
    if (
      projectCaption[videoGroupActive].map((item) => item._content_json)
        .length === 15
    ) {
      Message.error("最多导入台词组15条");
      return false;
    }
    const textArr = textContent.split(regExp);
    const _content_json = [];
    textArr.forEach((item) => {
      _content_json.push({
        type: "listItem",
        content: [
          {
            type: "paragraph",
            content: [
              {
                type: "text",
                text: item,
              },
            ],
          },
        ],
      });
    });
    const captions = [...mixCutDetailStore.getProjectCaption()];
    captions[videoGroupActive] = [
      ...captions[videoGroupActive],
      {
        ...initCaptionItem,
        id: Math.floor(Date.now() + Math.random() * 10000),
        _content_json: _content_json,
        _preview: textContent.replaceAll(regExp, "\n\n\n\n"),
        content: textArr.map((text) => {
          return `<speak>${text}</speak>`;
        }),
      },
    ];
    mixCutDetailStore.setProjectCaption([...captions]);
    captions.forEach((item, index) => {
      ConfigStore.scenes[index].caption = item;
    });
    setTextVisible(false);
    handleSaveMixCutDetailSave();
  };

  useEffect(() => {
    init(Number(id), Number(script_id));
  }, [id]);

  const returnContent = (content, line) => {
    console.log(JSON.stringify(content), line, 'line')
    if (!line || line.length === 0) {
      return content;
    }

    // 筛选出启用了 replaceFlag 的项，并按字符串长度降序排序
    const replaceLines = line
      .filter(item => item.replaceFlag)

    return content.map(item => {
      let currentString = item;
      // for (const item of deleteWord) {
      //   const regex = new RegExp(item, 'g');
      //   currentString = currentString.replace(regex, '');
      // }
      // 遍历每个待替换项并执行替换操作
      for (const matchedLine of replaceLines) {
        const regex = new RegExp(matchedLine.line, 'g');
        currentString = currentString.replace(regex, matchedLine.replaceLine);
      }

      return currentString;
    });
  };

  const returnTransformedContent = (_content_json, line) => {
    // 创建一个映射来存储替换规则
    const replacementMap = new Map();
    if (line && line.length > 0) {
      for (const rule of line) {
        if (rule.replaceFlag) {
          replacementMap.set(rule.line, rule.replaceLine);
        }
      }
    }

    if (_content_json && _content_json.length > 0) {
      return _content_json.map(item => {
        const originalText = item.content[0].content[0].text;
        let newText = originalText;

        // 检查是否存在需要替换的文本
        for (const [searchText, replaceText] of replacementMap) {
          if (newText.includes(searchText)) {
            newText = newText.replace(searchText, replaceText);
          }
        }

        return {
          type: item.type,
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: newText,
                },
              ],
            },
          ],
        };
      });
    } else {
      return _content_json;
    }
  };
  useEffect(() => {
    getSensitiveWords();
  }, []);
  const getSensitiveWords = async() => {
    const { data } = await getSensitiveWord(Number(id));
    setTotal_words(data?.total_words);
    setTotal_sensitive(data?.total_sensitive);
    setSensitiveList(data?.scenes);
    const result = data?.scenes && data?.scenes.length > 0 ? data?.scenes.map(item => item?.caption_groups.map(li => li?.sensitive_words || []).flat()).flat() : [];
    setSensitiveData(result)
  };

  // 敏感词编辑提交
  const handleOk = ({data, deleteWord, deleteTitle}) => {
    setDeleteWord(deleteWord)
    setDeleteTitle(deleteTitle)
    const titles = mixCutDetailStore.getProjectTitle();
    titles.map((item, index) => {
      return item.content.map((item, idx) => {
        if (data[videoGroupActive]?.title_groups[idx]?.line && data[videoGroupActive]?.title_groups[idx]?.line?.length) {
          data[videoGroupActive]?.title_groups[idx]?.line.forEach((li, i) => {
            if (li.replaceFlag) {
              item.text = item.text.replace(
                li.line,
                li?.replaceLine,
              );
              item._text = item._text.map((l, ii) => {
                return {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: l.content[0].text.replace(
                        li.line,
                        li?.replaceLine,
                      ),
                    },
                  ],
                }
              })
            }
          })
        }
      });
    });
    const captions = [...mixCutDetailStore.getProjectCaption()];
    console.log(JSON.stringify(captions), 'captions')
    captions.map((caption, videoGroupActive) => {
      caption.map((item, index) => {
        const conData = returnContent(
          item.content,
          data[videoGroupActive]?.content[index].line,
        )
        console.log(conData, 'conData')
        item.content = conData;
        item._content_json = returnTransformedContent(
          item._content_json,
          data[videoGroupActive]?.content[index].line,
        );
        item._preview = item._preview
          .substring(8)
          .split("\n\n\n\n")
          .map((l, i) => {
            return data[videoGroupActive]?.content[index].line && data[videoGroupActive]?.content[index]?.line.length > 0 && data[videoGroupActive]?.content[index].line[i]?.replaceFlag ? l.replace(
              data[videoGroupActive]?.content[index].line[i]?.line,
              data[videoGroupActive]?.content[index].line[i]?.replaceLine,
            ) : l;
          })
          .join("\n\n\n\n");
      });
    })
    mixCutDetailStore.setProjectCaption([...captions]);
    captions.forEach((item, index) => {
      ConfigStore.scenes[index].caption = item;
    });
    mixCutDetailStore.setProTitle([...titles]);
    ConfigStore.global_settings.titles = [...titles];
    setIsOpen(false);
    handleSaveMixCutDetailSave();
  };

  return (
    <Profiler id="App" onRender={onRenderCallback}>
      <mixCutDetailContext.Provider
        value={{
          videoGroupActive,
          setVideoGroupActive,
          videoGroupTransitionId,
        }}>
        <div className="mix-cut-detail-wrapper">
          <Header
            updateTime={projectMsg?.updated_at}
            handleResultBtnClick={async () => {
              if (
                projectSences.filter((item) => !item.setting.disabled)
                  .length === 0
              ) {
                Message.error("当前无可用镜头！");
                return;
              }
              if (!maxCount) {
                return Message.warning("当前混剪配置无法生成视频！");
              }
              setSenVisible(true);
              const { data } = await getSensitiveWord(Number(id));
              const result = data?.scenes.map(item => item?.caption_groups.map(li => li?.sensitive_words || []).flat()).flat();
              setSensitiveData(result)
              if (data?.total_sensitive > 0) {
                setTimeout(() => {
                  setSenVisible(false);
                  setTotal_words(data?.total_words);
                  setTotal_sensitive(data?.total_sensitive);
                  setSensitiveList(data?.scenes);
                  setIsOpen(true);
                  return false;
                }, 1000);
              } else {
                setSenVisible(false);
                window.open(
                  `/video-clip-result/${id}/${mixCutDetailStore.outputCount}`,
                  "_blank",
                );
                checkJson();
                await quickSaveMixCutDetail();
              }
            }}
            name={projectMsg?.title}
            handleChangeName={(e) => {
              handleProjectName(e);
            }}></Header>
          <div className="mix-cut-detail-container">
            <VideoGroup
              videoStatusList={videoStatusList}
              analysisId={analysisId}
              showAnalysis={showAnalysis}
              handleHeaderItemClick={(index) => {
                setVideoGroupActive(index);
              }}
              handleVideoGroupAdd={() => {
                handleProjectSencesAdd();
              }}
              handleVideoGroupDelete={(index) => {
                handleProjectSencesDelete(index);
              }}
              data={projectSences}
              handleProjectVideoChange={handleProjectVideoChange}
              handleDataChange={(e) => {
                setProjectSences(e);
              }}
              handleCaptionBtnClick={(e) => {
                executeBasedOnScope(e, 0, "caption");
              }}
              handleTitleBtnClick={(e) => {
                executeBasedOnScope(e, 1, "title");
              }}
              handleChangeVideoItemStartAndDuration={
                handleChangeVideoItemStartAndDuration
              }
              // handlePlayerCaption={handlePlayerCaption}
              // handlePlayerCaptionStop={handlePlayerCaptionStop}
              handleTransitionBtnClick={(e) => {
                executeBasedOnScope(e, 2, "transition");
              }}
              onTextAnalysis={(e) => {
                onTextAnalysis(e);
              }}></VideoGroup>
            <VideoConfig
              sensitiveData={sensitiveData}
              handleGlobalChange={() => {
                setVideoConfigActiveIndex(0);
                setVideoGroupActive(0);
                handleTipModalOkBtnClick();
              }}
              videoConfigActiveIndex={videoConfigActiveIndex}
              handleVideoConfigActiveIndexChange={setVideoConfigActiveIndex}
              CationConfig={projectCaption}
              global={global}></VideoConfig>
            <PreviewingVideo></PreviewingVideo>
          </div>
          <VideoGlobalModal
            title={"切换局部配置警告"}
            visible={tipItemModalVisible}
            onCancel={() => {
              setTipItemModalVisible(false);
            }}
            onOk={() => {
              setTipItemModalVisible(false);
              handleTipModalOkBtnClick();
            }}
            content={
              "切换到分镜配置模式，全局配置下的台词组，标题组，还有转场信息将全部丢失，是否继续？"
            }
          />
          <TextAnalysis
            handCancel={() => {
              setTextVisible(false);
            }}
            textVisible={textVisible}
            textContent={textContent}
            handleTextAnalysis={() => {
              handleTextAnalysis();
            }}
          />
          <SensitiveDrawer
            handleOk={(data) => {
              handleOk(data);
            }}
            sensitiveList={sensitiveList}
            total_words={total_words}
            total_sensitive={total_sensitive}
            handleCancel={() => {
              setIsOpen(false);
            }}
            isOpen={isOpen}
          />
          <Modal
            style={{ width: "276px" }}
            closable={false}
            visible={senVisible}
            footer={null}
            maskClosable={false}
            getPopupContainer={() => {
              return document.body;
            }}>
            <div className="loading-box">
              <LoadingGIF />
              <div className="text">敏感词检测中...</div>
            </div>
          </Modal>
        </div>
      </mixCutDetailContext.Provider>
    </Profiler>
  );
});

export default MixCutDetail;
