import { useEffect, useRef, useState } from "react";
import MisCutVideoMessageBar from "./MixCutVideoMessageBar/MisCutVideoMessageBar";
import Title from "../UIComponents/Title/Title";
import MixCutConfigWrapper from "../UIComponents/MixCutConfigWrapper/MixCutConfigWrapper";
import { IconLoading } from "@arco-design/web-react/icon";
import { getEditingPreview } from "@/services/videoallyis";
import { useParams } from "react-router-dom";
import useMixCutDetailMsg from "@/useStore/mixCutDetail/useMixCutDetailMsg";
import mixCutDetailCheck from "@/useStore/mixCutDetail/mixCutDetailCheck";
import useMixCutDetail from "@/useStore/mixCutDetail/useMixCutDetail";
import Player from "@/components/UI/Player/index";
import { InputNumber, Select } from "@arco-design/web-react";
import usePreviewConfig from "@/useStore/preview/usePreview";
import { combTimeline } from "@/utils/perm";
import previewConfing from "@/stores/previewStore";
import { observer } from "mobx-react";
import clipIdStore from "@/stores/previewStore/clipId";
import usePreviewInfo from "@/useStore/mixCutDetail/usePreviewInfo";
import preivewInfoStore from "@/stores/mixCutDetailStore/previewInfo";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import "./PreviewingVideo.less";
const { Option } = Select;
const PreviewingVideo = observer(() => {
  const { timer, maxCount } = usePreviewInfo();
  const { id } = useParams();
  const { mixCutDetailMsg, handleChangeMixCutDetailMsg } = useMixCutDetailMsg();
  const {
    confirmCaptionsErrorIsEmpty,
    confirmSencesErrorIsEmpty,
    checkJson,
    combSenceMaterials,
  } = mixCutDetailCheck();
  const { toIdAndConfigSetSetting } = usePreviewConfig();
  const { handleGetOriginJson } = useMixCutDetail();
  const [loading, setLoading] = useState(false);
  const [timeLine, setTimeLine] = useState<any>(null);
  const [cutResult, setCutResult] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(mixCutDetailMsg?.aspect);
  const playerRef = useRef<any>(true);

  useEffect(() => {
    setTimeLine({
      timeline: combTimeline(
        previewConfing.getMaterial(),
        previewConfing.getCaption(),
        previewConfing.getTitle(),
      ),
    });
  }, [previewConfing.version]);

  const parseJsonToTimeLine = async () => {
    checkJson();
    const result = combSenceMaterials(handleGetOriginJson());
    setCutResult(result);
    if (confirmCaptionsErrorIsEmpty() && confirmSencesErrorIsEmpty()) {
      try {
        setLoading(true);
        const result = await getEditingPreview({
          project_id: Number(mixCutDetailMsg.project_id),
          session_id: Number(id),
          data: JSON.stringify(handleGetOriginJson()),
        });
        console.log(result.timeline, "result.timeline");
        setTimeLine(JSON.parse(result.timeline));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <MixCutConfigWrapper
      style={{
        boxSizing: "border-box",
        width: 400,
        borderTop: "none",
        overflowY: "auto",
      }}>
      <Title title="配置预览" desc="即时查看视频配置效果"></Title>
      <div className="video-wrapper" id="video-wrapper-preview">
        {loading ? (
          <div className="loading-wrapper">
            <IconLoading style={{ color: "white", fontSize: 30 }} />
            <p>后台正在解析，请稍后</p>
          </div>
        ) : (
          <></>
        )}
        {
          <>
            <>
              {
                <Player
                  previewInfo={timeLine}
                  clipWatch={(id, config) => {
                    toIdAndConfigSetSetting(id, config);
                  }}
                  aspectRatio={mixCutDetailMsg?.aspect}
                  clipId={clipIdStore.getClipId()}
                  PlayerFunRef={playerRef}
                />
              }
            </>
          </>
        }
      </div>
      <div className="preview-select-wrapper">
        {playerRef.current?.aspectRatioOptions ? (
          <>
            <span>切换比例：</span>
            <Select
              placeholder="选择比例"
              style={{ width: 100 }}
              onChange={(e) => {
                setAspectRatio(e);
                handleChangeMixCutDetailMsg({ ...mixCutDetailMsg, aspect: e });
                clipIdStore.setClipId(null);
              }}
              value={mixCutDetailMsg?.aspect}>
              {playerRef.current?.aspectRatioOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </>
        ) : (
          <></>
        )}
        <span style={{ marginLeft: "10px" }}>产出数量：</span>
        <InputNumber
          placeholder="请输入产出数量"
          min={1}
          max={1000}
          value={mixCutDetailStore.outputCount}
          onChange={(e) => {
            mixCutDetailStore.outputCount = e;
          }}
          style={{ width: 100 }}
        />
      </div>

      <MisCutVideoMessageBar
        videoNum={maxCount || 0}
        maxDuration={timer.maxDuration}
        minDuration={timer.minDuration}
      />
    </MixCutConfigWrapper>
  );
});

export default PreviewingVideo;
