import { RequestStatus } from "@/requestHooks/common";
import useCreateFolder from "@/requestHooks/createSpace/material/useCreateFolder";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  ModalProps,
} from "@arco-design/web-react";
import { IconInfoCircle } from "@arco-design/web-react/icon";
import iconImg from "@/assets/create-space/project-modal-icon.png";
import { useEffect, useState } from "react";
import Modals from "@/components/UI/Modals";
import FormItem from "@arco-design/web-react/es/Form/form-item";

interface ICreateFolderModal extends ModalProps {
  projectId: number;
  handleOnBtnClick: () => void;
  folderName: string;
}

const CreateFolderModal: React.FC<ICreateFolderModal> = (props) => {
  const { projectId, handleOnBtnClick, folderName, ...rest } = props;
  const { loading, handleCreateFolder } = useCreateFolder();
  const [formRef] = Form.useForm();
  useEffect(() => {
    if (props.visible) {
      formRef.resetFields();
    }
  }, [props.visible]);

  const handleSaveFolder = async () => {
    try {
      const formResult = await formRef.validate();
      const exName = folderName ? folderName + "/" : "";
      const result = await handleCreateFolder({
        path: exName + formResult.folderName,
        projectId: projectId,
      });
      if (result) {
        handleOnBtnClick();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modals
      width={486}
      title="新建文件夹"
      subMiteBtn={
        <Button
          loading={loading === RequestStatus.LOADING}
          type="primary"
          onClick={async () => {
            handleSaveFolder();
          }}>
          确认
        </Button>
      }
      cancelBtn={
        <Button
          onClick={() => {
            rest.onCancel();
          }}>
          取消
        </Button>
      }
      titleIcon={<Image width={26} preview={false} src={iconImg} />}
      closeIcon={null}
      visible={rest.visible}>
      <div className="modal-content">
        <Form
          layout="vertical"
          form={formRef}
          style={{ width: "100%" }}
          autoComplete="off">
          <FormItem
            rules={[
              {
                required: true,
                message: "文件夹名称是必填项",
              },
              {
                validator: (value, cb) => {
                  if (value && value.length > 20) {
                    return cb("文件夹名称不能超过20个字符");
                  }
                  return cb();
                },
              },
            ]}
            field="folderName"
            label="文件夹名称">
            <Input placeholder="请输入文件夹名称" />
          </FormItem>
        </Form>
      </div>
    </Modals>
  );
};
export default CreateFolderModal;
