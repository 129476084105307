import { getPorjectList } from "@/services/createSpace/graphQ/getProjectList";
import { deleteProject, getProjectList } from "@/services/createSpace/project";
import navStore, {
  IProjectItem,
  ProjectNavTypeEnum,
} from "@/stores/spaceStore/projectStore";
import { Message } from "@arco-design/web-react";
import { useEffect } from "react";

const useNavProject = () => {
  const handleCreateNavItem = (
    item: IProjectItem,
    type?: ProjectNavTypeEnum,
  ) => {
    const newNavList = [...navStore.getProjectList()];
    navStore.setProjectList([
      ...newNavList,
      {
        ...item,
        type: type ? type : ProjectNavTypeEnum.DEFAULT,
      },
    ]);
  };

  const handleSetNavList = (list: IProjectItem[]) => {
    navStore.setProjectList(list);
  };

  const handleModifyNavList = (navItem: IProjectItem) => {
    const newNavList = [...navStore.getProjectList()];
    const findIndex = newNavList.findIndex((item) => item.id === navItem.id);
    newNavList.splice(findIndex, 1, navItem);
    navStore.setProjectList(newNavList);
  };

  const handleToIdDeleteNavItem = (id: number) => {
    const newNavList = [...navStore.getProjectList()];
    const index = newNavList.findIndex((item) => item.id === id);
    newNavList.splice(index, 1);
    navStore.setProjectList(newNavList);
    handleRequestProjectList();
  };

  const handleRequestProjectList = () => {
    getPorjectList({}).then((res) => {
      const projectItems = (res || []).map((item) => ({
        name: item.name,
        id: item.id,
        type: item.type,
        owner_name: item.owner_name,
        memberIds: item.members,
        sort: "desc",
        filed: "create_at",
      }));
      handleSetNavList(projectItems);
      const firstProjectId = res ? res[0]?.id || "" : "";
      navStore.setProjectId(firstProjectId || null);
    });
  };

  useEffect(() => {
    if (!navStore.getProjectList() || navStore.getProjectList().length === 0) {
      handleRequestProjectList();
    }
  }, []);

  const handleDeleteProjectItem = async (id: number) => {
    try {
      await deleteProject(id);
      handleRequestProjectList();
      Message.success("删除项目成功！");
    } catch (e) {
      Message.error(e);
    }
  };

  const toKeyWordGetNavItem = (name: string) => {
    const regex = new RegExp(name, "i");
    return navStore.getProjectList().filter((item) => regex.test(item.name));
  };

  const handleProjectIdChange = (id: number) => {
    navStore.setProjectId(id);
  };

  return {
    projectList: navStore.getProjectList(),
    projectId: navStore.getProjectId(),
    handleCreateNavItem,
    handleModifyNavList,
    handleToIdDeleteNavItem,
    handleSetNavList,
    toKeyWordGetNavItem,
    handleProjectIdChange,
    handleDeleteProjectItem,
    handleRequestProjectList,
  };
};

export default useNavProject;
