import React, { useState, useRef, useEffect } from "react";
import {
  Drawer,
  Button,
  Message,
  Input,
  Checkbox,
  Collapse,
} from "@arco-design/web-react";
import "./index.less";
const CollapseItem = Collapse.Item;
const CheckboxGroup = Checkbox.Group;
import MinganTitle from "@/assets/cut-mix-detail/mingan-title-min.png";
import TitleGroup from "@/pages/VideoClips/MixCutDetail/FontTitle/TitleGroup/TitleGroup";
import VoLongButton from "@/components/VLButton/VLButton";
import SenDeleteImg from "@/assets/cut-mix-detail/sensi-delete-min.png";
import SenEdit from "@/assets/cut-mix-detail/sensi-edit-min.png";
import ZhanImg from "@/assets/cut-mix-detail/zhunhuan.png";
import HasZhuanImg from "@/assets/cut-mix-detail/haszhuan.png";
type Props = {
  isOpen: boolean;
  handleCancel: () => void;
  total_words: number;
  total_sensitive: number;
  sensitiveList: any;
  handleOk: (data) => void;
};
const SensitiveDrawer = (props: Props) => {
  const {
    isOpen,
    handleCancel,
    total_words,
    total_sensitive,
    sensitiveList,
    handleOk,
  } = props;
  const [titleGroupIndex, setTitleGroupIndex] = useState(0);
  const [editName, setEditName] = useState("");
  const [groupId, setGroupId] = useState("0-0");
  const [data, setData] = useState([]);
  const inputRef = useRef(null);
  const [checkKey, setCheckKey] = useState(["0"]);
  const [editId, setEditId] = useState(0);
  const [editFlag, setEditFlag] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [deleteWord, setDeleteWord] = useState([]);
  const [deleteTitle, setDeleteTitle] = useState([]);
  useEffect(() => {
    const res = [...data]
    if (checkAll) {
      res.map((item) => {
        if (item.content?.length > 0) {
          item.content.map(li => {
            if (li.line?.length > 0) {
              li.line.map(l => {
                item.checkId.push(l.id)
              })
            }
          })
        }
        if (item.title_groups?.length > 0) {
          item.title_groups.map(li => {
            if (li.line?.length > 0) {
              li.line.map(l => {
                item.checkId.push(l.id)
              })
            }
          })
        }
      })
    } else {
      res.map((item) => {
        item.checkId = []
      })
    }
    setData(res)
  }, [checkAll]);
  useEffect(() => {
    const res = [];
    if (isOpen) {
      sensitiveList.forEach((item, index) => {
        res.push({
          title: item.caption_groups[0].name,
          dialogueGroup: item.caption_groups.map((li, idx) => {
            return {
              name: "台词组" + (idx + 1),
              id: `${index}-${idx}`,
            };
          }),
          groupId: `${index}-0`,
          content: item.caption_groups.map((li, idx) => {
            return {
              id: `${index}-${idx}`,
              line: li.sensitive_words?.length
                ? li.sensitive_words.map((l, i) => {
                    return {
                      id: `${index}-${idx}-${i}`,
                      line: l,
                      replaceFlag: false,
                      replaceLine: li.replacements[i],
                    };
                  })
                : [],
            };
          }),
          title_groups: item.title_groups?.length>0&&
          item.title_groups.map((li, idx) => {
            return {
              id: `${index}-${idx}`,
              line: li.sensitive_words?.length
                ? li.sensitive_words.map((l, i) => {
                  return {
                    id: `${index}-${idx}-${i}`,
                    line: l,
                    replaceFlag: false,
                    replaceLine: li.replacements[i],
                  };
                })
                : [],
            };
          }),
          checkId: [],
        });
      });
      setData(res);
    } else {
      setCheckAll(false)
    }
  }, [isOpen]);

  useEffect(() => {
    console.log(data, "data初始化");
  }, [data]);
  // 删除
  const deleteHandle = (index, id, textId) => {
    const newData = [...data];
    const word = [...deleteWord]
    const title = [...deleteTitle]
    if (titleGroupIndex === 0) {
      word.push(newData[index].content.find(item => item.id === id).line.find(li => li.id === textId).line)
      setDeleteWord(word);
      newData[index].content.find((item) => item.id === id).line = newData[
        index
      ].content
        .find((item) => item.id === id)
        .line.filter((item, idx) => item.id !== textId);
    } else {
      title.push(newData[index].title_groups.find(item => item.id === id).line.find(li => li.id === textId).line)
      setDeleteTitle(title)
      newData[index].title_groups.find((item) => item.id === id).line = newData[
        index
        ].title_groups
        .find((item) => item.id === id)
        .line.filter((item, idx) => item.id !== textId);
    }
    setData(newData);
  };
  const groupChange = (val, index) => {
    if (val) {
      const newData = [...data];
      if (titleGroupIndex === 0) {
        newData[index].checkId = data[index].content
          .map((item) => item.line)
          .flat()
          .map((item) => item.id);
      } else {
        newData[index].checkId = data[index].title_groups
          .map((item) => item.line)
          .flat()
          .map((item) => item.id);
      }
      setData(newData);
    } else {
      const newData = [...data];
      newData[index].checkId = [];
      setData(newData);
    }
  };
  // 单个点击
  const checkClick = (index, id) => {
    const newData = [...data];
    if (data[index].checkId.includes(id)) {
      newData[index].checkId = newData[index].checkId.filter(
        (item) => item !== id,
      );
      setData(newData);
    } else {
      newData[index].checkId = [...newData[index].checkId, id];
      setData(newData);
    }
  };
  // 编辑
  const editHanlde = (index, id, textId) => {
    setEditFlag(true);
    if (titleGroupIndex === 0) {
      setEditId(textId);
      setEditName(
        data[index].content
          .find((item) => item.id === id)
          .line.find((item) => item.id === textId).line,
      );
    } else {
      setEditId(textId);
      setEditName(
        data[index].title_groups
          .find((item) => item.id === id)
          .line.find((item) => item.id === textId).line,
      );
    }
  };
  // 编辑名称
  const changeName = (name) => {
    setEditName(name);
  };
  // 批量删除
  const deleteAll = () => {
    const deleteList = data && data.map((item) => item.checkId).flat();
    const len = (data && data.map((item) => item.checkId).flat().length) || 0;
    const word = [...deleteWord]
    const title = [...deleteTitle]
    if (!len) {
      Message.error("请选择要删除的台词");
    } else {
      const newData = [...data];
      newData.map((item) => {
        if (item.checkId?.length) {
          if (titleGroupIndex === 0) {
            item.content.forEach((ite) =>
              ite.line.forEach(it => {
                if (item.checkId.includes(it.id)) {
                  word.push(it.line)
                }
              })
            )
            console.log(word, 'word')
            setDeleteWord(word)
            return {
              ...item,
              content: item.content.map((item) =>
                item.line = item.line.filter((it) => !deleteList.includes(it.id)),
              ),
              checkId: [],
            }
          } else {
            item.title_groups.forEach((ite) =>
              ite.line.forEach(it => {
                if (item.checkId.includes(it.id)) {
                  title.push(it.line)
                }
              })
            )
            console.log(title, 'title')
            setDeleteTitle(title)
            return {
              ...item,
              title_groups: item.title_groups.map((item) =>
                item.line = item.line.filter((it) => !deleteList.includes(it.id)),
              ),
              checkId: [],
            }
          }
        }
      });
      setData(newData);
    }
  };
  // 批量替换
  const clickAllReplace = () => {
    setEditFlag(true);
    const replaceList = data && data.map((item) => item.checkId).flat();
    const len = (data && data.map((item) => item.checkId).flat().length) || 0;
    if (!len) {
      if (titleGroupIndex === 0) {
        Message.error("请选择要替换的台词");
      } else {
        Message.error("请选择要替换的标题");
      }
    } else {
      const newData = [...data];
      newData.map((item) => {
        if (item.checkId?.length) {
          if (!checkAll) {
            if (titleGroupIndex === 0) {
              item.content = item.content.map((item) => {
                return {
                  ...item,
                  line: item.line.map((it) =>
                    replaceList.includes(it.id)
                      ? { ...it, replaceFlag: true }
                      : it,
                  ),
                };
              });
              item.checkId = [];
            } else {
              item.title_groups = item.title_groups.map((item) => {
                return {
                  ...item,
                  line: item.line.map((it) =>
                    replaceList.includes(it.id)
                      ? { ...it, replaceFlag: true }
                      : it,
                  ),
                };
              });
              item.checkId = [];
            }
          } else {
            item.content = item.content.map((item) => {
              return {
                ...item,
                line: item.line.map((it) =>
                  replaceList.includes(it.id)
                    ? { ...it, replaceFlag: true }
                    : it,
                ),
              };
            });
            item.title_groups = item.title_groups.map((item) => {
              return {
                ...item,
                line: item.line.map((it) =>
                  replaceList.includes(it.id)
                    ? { ...it, replaceFlag: true }
                    : it,
                ),
              };
            });
            item.checkId = []
          }
        }
      });
      setCheckAll(false);
      setData(newData);
    }
  };
  useEffect(() => {
    if (editId && inputRef) {
      inputRef.current.focus();
    }
  }, [editId]);

  const changeCollapse = (e) => {
    // setGroupId(data[e].dialogueGroup[0].id)
  };
  const getContent = (content, id) => {
    const data = [];
    if (titleGroupIndex === 0) {
      content.forEach((item) => {
        if (item.id === id) {
          data.push(item);
        }
      });
    } else {
      return content
    }
    return data || [];
  };
  const changeGroupId = (index, idx) => {
    const newData = [...data];
    newData[index].groupId = `${index}-${idx}`;
    setData(newData);
  };
  const getScenes = (item) => {
    if (titleGroupIndex === 0) {
      return item.content;
    } else {
      return item.title_groups;
    }
  };
  // 单个替换
  const replaceHandle = (index, id, textId) => {
    console.log(index, id, textId);
    setEditFlag(true);
    const newData = [...data];
    if (titleGroupIndex === 0) {
      newData[index].content.map((item) => {
        if (item.id === id) {
          item.line = item.line.map((it) => {
            if (it.id === textId) {
              return { ...it, replaceLine: it.replaceLine, replaceFlag: true };
            } else {
              return it;
            }
          });
        }
      });
    } else {
      newData[index].title_groups.map((item) => {
        if (item.id === id) {
          item.line = item.line.map((it) => {
            if (it.id === textId) {
              return { ...it, replaceLine: it.replaceLine, replaceFlag: true };
            } else {
              return it;
            }
          });
        }
      });
    }
    setData(newData);
  };
  return (
    <div>
      <Drawer
        width={555}
        title={
          <div className="mingan-header">
            <img src={MinganTitle} alt="" />
            <span>敏感词检测</span>
          </div>
        }
        visible={isOpen}
        onOk={() => {
          handleOk({
            data,
            deleteWord,
            deleteTitle
          });
        }}
        onCancel={() => {
          handleCancel();
        }}>
        <div className="sensitive-desc">
          <span style={{ marginRight: "5px" }}>共检测：{total_words}字</span>{" "}
          敏感词：<span style={{ color: "#F53F3F" }}>{total_sensitive}</span> 字
        </div>
        <div className="sensitive-btns">
          <TitleGroup
            handleActiveIndexChange={(e) => {
              // const newData = [...data];
              // newData.forEach((item) => {
              //   item.checkId = [];
              // });
              // setData(newData);
              setTitleGroupIndex(e);
            }}
            data={[{ title: "台词组" }, { title: "标题组" }]}
            active={titleGroupIndex}></TitleGroup>
          <div className="flex--center">
            <Checkbox
              checked={checkAll}
              onChange={(e) => {
               setCheckAll(e);
              }}
              style={{ marginRight: "10px" }}
            >全选</Checkbox>
            {/*<div*/}
            {/*  onClick={() => {*/}
            {/*    deleteAll();*/}
            {/*  }}*/}
            {/*  className="all-delete"*/}
            {/*  style={{ marginRight: "5px", background: "#fff" }}>*/}
            {/*  批量删除*/}
            {/*</div>*/}
            <VoLongButton
              style={{ fontSize: "14px" }}
              type="primary"
              onClick={() => {
                clickAllReplace();
              }}>
              一键批量替换
            </VoLongButton>
          </div>
        </div>
        <div className="sensitive-content">
          {data.map((item, index) => {
            return (
              <Collapse
                onChange={(e) => {
                  changeCollapse(e);
                }}
                accordion={true}
                defaultActiveKey={checkKey}
                triggerRegion={"icon"}
                key={index}
                bordered={false}>
                <CollapseItem
                  header={
                    <div className="sensitive-content-nav">
                      <div className="title">
                        <Checkbox
                          checked={!!item.checkId.length}
                          onChange={(val, e) => {
                            e.stopPropagation();
                            groupChange(val, index);
                          }}
                          style={{ marginRight: "13px" }}
                        />
                        {item.title}
                      </div>
                    </div>
                  }
                  extra={
                    titleGroupIndex === 0 && (
                      <div className="btn-group">
                        {item.dialogueGroup.map((li, idx) => {
                          return (
                            <div
                              onClick={() => {
                                changeGroupId(index, idx);
                              }}
                              key={idx}
                              className={
                                item.groupId === li.id ? "active btns" : "btns"
                              }>
                              {li.name}
                            </div>
                          );
                        })}
                      </div>
                    )
                  }
                  name={String(index)}>
                  <div className="sensitive-item">
                    <CheckboxGroup
                      value={item.checkId}
                      style={{ display: "block" }}>
                      {getContent(getScenes(item), item.groupId).map(
                        (it, i) => {
                          return (
                            <>
                              {
                                it.line.map((ite, ii) => {
                                  return (
                                    <div
                                      className="sensitive-item-content"
                                      key={`${titleGroupIndex}-${i}-${ii}`}>
                                      <div className="sensitive-item-header">
                                        <div className="con-left">
                                          <Checkbox
                                            onChange={() => {
                                              checkClick(index, ite.id);
                                            }}
                                            value={ite.id}
                                            style={{ marginRight: "10px" }}
                                          />
                                          {editId === ite.id && (
                                            <Input
                                              onBlur={() => {
                                                ite.replaceLine = editName;
                                                ite.replaceFlag = true;
                                                setEditId(0);
                                              }}
                                              ref={inputRef}
                                              className="editInput"
                                              onChange={(e) => {
                                                changeName(e);
                                              }}
                                            />
                                          )}
                                          {
                                            <span
                                              className={
                                                ite.replaceFlag
                                                  ? "edit-replace"
                                                  : "line"
                                              }>
                                              {ite.replaceFlag
                                                ? ite.replaceLine
                                                : ite.line}
                                            </span>
                                          }
                                        </div>
                                        <div className="con-btn">
                                          {/*<img*/}
                                          {/*  style={{ marginRight: "8px" }}*/}
                                          {/*  src={SenDeleteImg}*/}
                                          {/*  alt=""*/}
                                          {/*  onClick={() => {*/}
                                          {/*    deleteHandle(*/}
                                          {/*      index,*/}
                                          {/*      it.id,*/}
                                          {/*      ite.id,*/}
                                          {/*    );*/}
                                          {/*  }}*/}
                                          {/*/>*/}
                                          <img
                                            src={SenEdit}
                                            alt=""
                                            onClick={() => {
                                              editHanlde(index, it.id, ite.id);
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="sensitive-item-info">
                                        <div style={{ marginRight: "9px" }}>
                                          替换词：{ite.replaceLine}
                                        </div>
                                        <img
                                          onClick={() => {
                                            replaceHandle(index, it.id, ite.id);
                                          }}
                                          src={
                                            ite.replaceFlag
                                              ? HasZhuanImg
                                              : ZhanImg
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              {/*{titleGroupIndex === 1 && it.replaceLine && (*/}
                              {/*  <div className="sensitive-item-content" key={i}>*/}
                              {/*    <div className="sensitive-item-header">*/}
                              {/*      <div className="con-left">*/}
                              {/*        <Checkbox*/}
                              {/*          onChange={() => {*/}
                              {/*            checkClick(index, it.id);*/}
                              {/*          }}*/}
                              {/*          value={it.id}*/}
                              {/*          style={{marginRight: "10px"}}*/}
                              {/*        />*/}
                              {/*        {editId === it.id && (*/}
                              {/*          <Input*/}
                              {/*            onBlur={() => {*/}
                              {/*              it.replaceLine = editName;*/}
                              {/*              setEditId(0);*/}
                              {/*            }}*/}
                              {/*            ref={inputRef}*/}
                              {/*            className="editInput"*/}
                              {/*            onChange={(e) => {*/}
                              {/*              changeName(e);*/}
                              {/*            }}*/}
                              {/*          />*/}
                              {/*        )}*/}
                              {/*        <span*/}
                              {/*          className={*/}
                              {/*            it.replaceFlag*/}
                              {/*              ? "edit-replace"*/}
                              {/*              : "line"*/}
                              {/*          }>*/}
                              {/*                {it.replaceFlag*/}
                              {/*                  ? it.replaceLine*/}
                              {/*                  : it.line}*/}
                              {/*              </span>*/}
                              {/*      </div>*/}
                              {/*      <div className="con-btn">*/}
                              {/*        <img*/}
                              {/*          style={{marginRight: "8px"}}*/}
                              {/*          src={SenDeleteImg}*/}
                              {/*          alt=""*/}
                              {/*          onClick={() => {*/}
                              {/*            deleteHandle(index, it.id, it.id);*/}
                              {/*          }}*/}
                              {/*        />*/}
                              {/*        <img*/}
                              {/*          src={SenEdit}*/}
                              {/*          alt=""*/}
                              {/*          onClick={() => {*/}
                              {/*            editHanlde(index, it.id, null);*/}
                              {/*          }}*/}
                              {/*        />*/}
                              {/*      </div>*/}
                              {/*    </div>*/}
                              {/*    <div className="sensitive-item-info">*/}
                              {/*      <div style={{marginRight: "9px"}}>*/}
                              {/*        替换词：{it.replaceLine}*/}
                              {/*      </div>*/}
                              {/*      <img*/}
                              {/*        onClick={() => {*/}
                              {/*          replaceHandle(index, it.id, null);*/}
                              {/*        }}*/}
                              {/*        src={*/}
                              {/*          it.replaceFlag*/}
                              {/*            ? HasZhuanImg*/}
                              {/*            : ZhanImg*/}
                              {/*        }*/}
                              {/*        alt=""*/}
                              {/*      />*/}
                              {/*    </div>*/}
                              {/*  </div>*/}
                              {/*)}*/}
                            </>
                          );
                        },
                      )}
                    </CheckboxGroup>
                  </div>
                </CollapseItem>
              </Collapse>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
};

export default SensitiveDrawer;
