import { useEffect, useState } from "react";
import TitleGroupTag from "../../FontTitle/TitleGroup/TitleGroupTag/TitleGroupTag";
import VideoConfigCard, {
  VideoConfigCardContainer,
  VideoConfigCardHeader,
} from "../../UIComponents/VideoConfigCard/VideoConfigCard";
import CoverGroup from "./CoverGroup/CoverGroup";
import CommonUpload from "@/components/business/CommonUpload";
import {
  IBgResponse,
  bindingBgPicture,
  deleteCustomBackground,
  getBindingBgPicture,
  getPublicBg,
} from "@/services/mixCutDetail/mixCutDetail";
import { Message, Spin } from "@arco-design/web-react";
import { observer } from "mobx-react";
import useCoverAndBg from "@/useStore/mixCutDetail/useCoverAndBg";
import useRequest from "@/requestHooks/mixCutDetail/useRequest";
import CustomCover from "./CustomCover/CustomCover";
import MaterialFileModal from "@/components/business/MaterialFileModal/MaterialFIleModal";
import allowTypeStore from "@/stores/allowTypeStore";
import { toJS } from "mobx";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import { useParams } from "react-router-dom";
import "./CoverConfig.less";
import ChoseDetailPopover from "../../ChoseDetailPopover/ChoseDetailPopover";
const CoverConfig = observer(() => {
  const [titleList, setTitleList] = useState(["推荐", "自定义"]);
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const { bgImages, handleBgImagesChange } = useCoverAndBg();
  const { data: publicBgCover, loading } = useRequest({ request: getPublicBg });
  const [customData, setCustomData] = useState<IMaterialDetail[]>([]);
  const [uploadVisible, setUploadVisible] = useState(false);
  const handleActiveIdsChange = (e: boolean, id: number) => {
    if (e) {
      const arr = activeIndex === 0 ? publicBgCover : customData;
      const item = (arr as IBgResponse[]).find(
        (item) => Number(item.id) === id,
      ) as IBgResponse | undefined;
      if (item) {
        bgImages.push(item);
        handleBgImagesChange([...bgImages]);
      }
    } else {
      const index = bgImages.findIndex((item) => item.id === id);
      bgImages.splice(index, 1);
      handleBgImagesChange([...bgImages]);
    }
  };

  const handleMaterialFileModalOkBtnClick = async (e: IMaterialDetail[]) => {
    try {
      await bindingBgPicture(
        Number(id),
        e.map((item) => Number(item.id)),
      );
      const newCustomData = await getBindingBgPicture(id);
      setCustomData(newCustomData);
      setUploadVisible(false);
    } catch (e) {
      console.log(e);
      Message.error("绑定封面图片失败!");
    }
  };

  const handleUnBindCustomCover = async (bgId: number) => {
    try {
      await deleteCustomBackground(id, bgId);
      const data = await getBindingBgPicture(id);
      handleBgImagesChange([
        ...bgImages.filter((item) => Number(item.id) !== Number(bgId)),
      ]);
      setCustomData(data);
    } catch (e) {
      console.log(e);
      Message.error("删除自定义背景图失败！");
    }
  };

  const init = async () => {
    const newCustomData = await getBindingBgPicture(id);
    console.log(newCustomData);
    setCustomData(newCustomData);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <VideoConfigCard style={{ marginTop: 10 }}>
        <VideoConfigCardHeader
          headerLeft={<>背景图</>}
          headerRight={
            <ChoseDetailPopover
              showField={"name"}
              handleTagCloseBtnClick={(e) => {
                handleActiveIdsChange(false, e as number);
              }}
              handleClearBtnClick={() => {
                handleBgImagesChange([]);
              }}
              opeatorFiled={"id"}
              data={bgImages}
              title={"背景图"}
            />
        }
        />
        <VideoConfigCardContainer>
          <div className="bg-cover-config-header">
            <TitleGroupTag
              style={{ width: "fit-content" }}
              titleList={titleList}
              activeIndex={activeIndex}
              handleActiveIndex={setActiveIndex}
              isEdit={false}
            />
          </div>

          {activeIndex === 0 ? (
            <Spin loading={loading === "loading"}>
              <CoverGroup
                data={publicBgCover as any}
                activeIds={bgImages as IBgResponse[]}
                handleCoverChange={handleActiveIdsChange}
              />
            </Spin>
          ) : (
            <></>
          )}
          {activeIndex === 1 ? (
            <CustomCover
              handleCloseBtnClick={handleUnBindCustomCover}
              handleCustomBtnClick={() => {
                setUploadVisible(true);
              }}
              data={customData}
              handleCoverChange={(e, id) => {
                handleActiveIdsChange(e, id);
              }}
              activeIds={bgImages as IBgResponse[]}
            />
          ) : (
            <></>
          )}
          <CommonUpload
            defalutValue={'material'}
            defaultCollection={bgImages
              .filter((item) => (item as any).play_info)
              .map((item) => item.id.toString())}
            allowType={toJS(allowTypeStore.getAllPictureTypeString())}
            handleOkBtnClick={handleMaterialFileModalOkBtnClick}
            importCollection={customData?.length > 0 && customData.map(item => item.id)}
            allowMetarial={[3, 4]}
            visible={uploadVisible}
            onCancel={() => {
              setUploadVisible(false);
            }}
            maxCollectionLength={20}
            showAddFile={false}
          />
        </VideoConfigCardContainer>
      </VideoConfigCard>
    </>
  );
});

export default CoverConfig;
