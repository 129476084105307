import {Button, Message, Modal} from "@arco-design/web-react";
import VoLongButton from "@/components/VLButton/VLButton";
import React from "react";
type Props = {
  handleTextAnalysis: () => void;
  textContent: string;
  textVisible: boolean;
  handCancel: () => void;
}
const TextAnalysis = (props: Props) => {
  const { handleTextAnalysis, textContent, textVisible, handCancel } = props;
  return (
    <Modal
      style={{ width: '500px' }}
      closable={false}
      visible={textVisible}
      footer={null}
      onCancel={() => {
        handCancel();
      }}
      getPopupContainer={() => {
        return document.body
      }}
    >
      <div className="text-box">
        <div className="text-header">
          <div className="title">台词解析完成如下：</div>
          <div className="btns">
            <Button onClick={() => {
              const typedDom: any = document.querySelector(
                `.text-content`,
              );
              navigator.clipboard.writeText(typedDom.innerText);
              Message.success("复制成功");
              handCancel()
            }} type={"text"} style={{ fontSize: 12 }}>复制</Button>
            <VoLongButton
              style={{marginLeft: "10px", fontSize: '12px' }}
              type="primary"
              onClick={() => {
                handleTextAnalysis();
              }}>
              导入台词
            </VoLongButton>
          </div>
        </div>
        <div className="text-content">
          { textContent }
        </div>
      </div>
    </Modal>
  )
}
export default TextAnalysis
