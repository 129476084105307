// 路由映射表
import { Navigate, Outlet } from "react-router-dom";
import Login from "../pages/Login";
import Teamanagement from "../pages/TeamManagement/index";
import Layout from "../layouts/BasicLayout";
import CreationScript from "../pages/CreationScript/index";
import CreateVideoanalysis from "../pages/CreateVideo";
import CreateVideodetil from "../pages/CreateVideo/detil/index";
import Invitation from "../pages/Invitation";
import CreateIntercept from "./createIntercept";
import { lazy } from "react";
import MixCutDetail from "@/pages/VideoClips/MixCutDetail/MixCutDetail";
import TeamOverview from "@/pages/TeamOverview";
import VideoEditing from "@/pages/VideoEditing";
import { getTokenToLocalStorage } from "@/utils/token";
import ProtectedRoute from "./ProtectedRoute";
import RedirectRoute from "./RedirectRoute";
// const TextEditor = lazy(() => import("../pages/TestModule/TestEditor/Index"));
// 使用路由懒加载（lazy）
// const Home = lazy(() => import("../pages/home"));

const CreativeScript = lazy(() => import("@/pages/CreateScript/Index"));
const UserCenter = lazy(() => import("@/pages/UserCenter"));
const TaskList = lazy(() => import("@/pages/TaskCenter"));
const Workbench = lazy(() => import("@/pages/Workbench"));

const RecyclingStation = lazy(
  () => import("../pages/CreationSpace/RecyclingStation/RecyclingStation"),
);
const CreativeSpace = lazy(
  () => import("../pages/CreationSpace/CreativeSpace/index"),
);

const VideoClips = lazy(
  () => import("../pages/VideoClips/IntelligenceMixCut/IntelligenceMixCut"),
);

const AIThemeCreation = lazy(
  () => import("../pages/AICreation/AIThemeCreation"),
);

const AIThemeCreationTable = lazy(
  () => import("../pages/AICreation/AIThemeCreationTable/AIThemeCreationTable"),
);

const AIThemeScriptCreate = lazy(
  () => import("../pages/AICreation/AIThemeScriptCreate/AIThemeScriptCreate"),
);

const VideoClipResult = lazy(
  () => import("../pages/VideoClips/VideoClipResult/VideoClipResult"),
);
const routers = [
  {
    path: "/",
    element: <Navigate to="/work-bench" />,
  },
  {
    path: "/",
    element: (
      <RedirectRoute>
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      </RedirectRoute>
    ),
    children: [
      {
        path: "/work-bench",
        element: <Workbench />,
      },
      {
        path: "/creative-script",
        element: <CreativeScript />,
      },
      {
        path: "/ai-creation",
        element: <AIThemeCreation />,
      },
      {
        path: "/creative-space/recycling-station",
        element: <RecyclingStation />,
      },
      {
        path: "/creative-space",
        element: <CreativeSpace />,
      },
      {
        path: "/video-clips",
        element: <VideoClips />,
      },
      {
        path: "/ai-creation",
        element: <AIThemeCreation />,
      },
      {
        path: "/aithemecreationtable",
        element: <AIThemeCreationTable />,
      },
      {
        path: "/aithemescriptcreate",
        element: <AIThemeScriptCreate />,
      },
      {
        path: "/creation-script/:id",
        element: (
          <CreateIntercept>
            <CreationScript />
          </CreateIntercept>
        ),
      },
      {
        path: "/creation-script",
        element: (
          <CreateIntercept>
            <CreationScript />
          </CreateIntercept>
        ),
      },
      {
        path: "/team-anagement",
        element: <Teamanagement />,
      },
      {
        path: "/team-overview",
        element: <TeamOverview />,
      },
      {
        path: "/analysis-video",
        element: <CreateVideoanalysis />,
      },
      {
        path: "/analysis-video/:id",
        element: <CreateVideodetil />,
      },
      {
        path: "/task-center",
        element: <TaskList />,
      },

      {
        path: "/user",
        element: <UserCenter />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "/mix-detail/:id/:script_id?",
    element: <MixCutDetail />,
  },
  {
    path: "/video-clip-result/:projectId/:count",
    element: <VideoClipResult />,
  },
  {
    path: "/invite",
    element: <Invitation />,
  },
  {
    path: "/video-editing",
    element: <VideoEditing />,
  },
  {
    path: "/video-editing/:id",
    element: <VideoEditing />,
  },
  // {
  //   path: "*",
  //   element: <Navigate to="/login" />,
  // }, //其他没有被注册过的路径统一重定位到login
];
export default routers;
