/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-empty-interface */

import React, {
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  HTMLAttributes,
  CSSProperties,
} from "react";
import "./index.less";
import debounce from "lodash.debounce";

interface ItemProps {
  children?: ReactNode;
  /**
   * flexMode 布局模式
   */
  flexMode?: "auto" | 1;
  style?: CSSProperties; // Add style property
}

const Item: React.FC<ItemProps> = ({ children, flexMode = "auto" }) => {
  return (
    <div
      className="grid-item"
      style={{ flex: flexMode === "auto" ? "0 1 auto" : 1 }}>
      {children}
    </div>
  );
};
interface HideProps {
  maxRows: number;
}

const Hide: React.FC<HideProps> = () => null;
Hide.displayName = "GridContainerHide"; //

interface GridContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  dataLength: number;
  boxGap?: number;
  minWidth?: number;
}

const GridContainer: React.FC<GridContainerProps> & {
  Item: React.FC<ItemProps>;
  Hide: React.FC<HideProps>;
} = ({ children, dataLength, boxGap = 16, minWidth = 0, ...rest }) => {
  const [placeholders, setPlaceholders] = useState<number[]>([]);
  const [isBetween, setIsBetween] = useState<boolean>(true);
  const [placeholdersWidth, setPlaceholdersWidth] = useState<number>(0);
  const [visibleItemsCount, setVisibleItemsCount] = useState<
    number | undefined
  >(undefined);
  const [rowLength, setRowLength] = useState<number>(0);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const boxRef = useRef<HTMLDivElement>(null);
  const dataLengthRef = useRef<number>(0);

  useEffect(() => {
    dataLengthRef.current = dataLength;
  }, [dataLength]);

  const { filteredChildren, maxRows } = React.useMemo(() => {
    const childrenArray = React.Children.toArray(children);
    let maxRowsValue: number | undefined = undefined;
    const filtered = childrenArray.filter((child) => {
      if (
        React.isValidElement(child) &&
        (child.type as any).displayName === "GridContainerHide"
      ) {
        maxRowsValue = (child.props as HideProps).maxRows;
        return false; // 排除 Hide 组件
      }
      return true;
    });
    return { filteredChildren: filtered, maxRows: maxRowsValue };
  }, [children]);

  useLayoutEffect(() => {
    const handleResize = debounce(() => {
      if (boxRef.current) {
        const containerWidth = boxRef.current.clientWidth;
        const itemElements = boxRef.current.querySelectorAll(
          ".grid-item:not(.placeholder)",
        );
        let itemsPerRow = 1;

        if (itemElements.length > 0) {
          const firstItem = itemElements[0] as HTMLElement;
          const { width: firstItemWidth } = firstItem.getBoundingClientRect();
          setPlaceholdersWidth(firstItemWidth);
          const rowLength =
            Math.floor((containerWidth + boxGap + 1) / (minWidth + boxGap)) ||
            1;
          setRowLength(rowLength);
          if (minWidth !== 0 && dataLength < rowLength) {
            const arr = document.querySelectorAll(".grid-item");
            arr.forEach((element) => {
              const htmlElement = element as HTMLElement;
              htmlElement.style.width = `${minWidth}px`;
              htmlElement.style.flex = `none`;
            });
          }
          // 计算每行项目数
          itemsPerRow =
            Math.floor(
              (containerWidth + boxGap + 1) / (firstItemWidth + boxGap),
            ) || 1;
        }
        setIsBetween(itemsPerRow !== 1);
        let placeholdersToAdd = 0;
        if (maxRows) {
          const maxItems = itemsPerRow * maxRows;
          setVisibleItemsCount(maxItems);
          // 计算占位符数量
          const remainingItems = maxItems % itemsPerRow;

          placeholdersToAdd =
            remainingItems === 0 ? 0 : itemsPerRow - remainingItems;
        } else {
          const remainingItems = dataLengthRef.current % itemsPerRow;
          placeholdersToAdd =
            remainingItems === 0 ? 0 : itemsPerRow - remainingItems;
          setVisibleItemsCount(undefined);
        }

        // 确保占位符数量不超过每行项目数
        if (placeholdersToAdd > itemsPerRow) {
          placeholdersToAdd = itemsPerRow;
        }
        setPlaceholders(
          placeholdersToAdd > 0
            ? Array.from({ length: placeholdersToAdd }, (_, i) => i)
            : Array.from(
                { length: itemsPerRow * maxRows - dataLength },
                (_, i) => i,
              ),
        );

        // 设置初始化完成
        setIsInitialized(true);
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }

    // 初始计算
    handleResize();

    return () => {
      resizeObserver.disconnect();
      handleResize.cancel();
    };
  }, [boxGap, filteredChildren, maxRows]);

  let itemsToRender: React.ReactNode[];

  if (visibleItemsCount !== undefined) {
    // 仅渲染可见项目
    itemsToRender = filteredChildren.slice(0, visibleItemsCount);
  } else {
    itemsToRender = filteredChildren;
  }

  return (
    <div
      className="grid-box"
      ref={boxRef}
      style={{
        gap: boxGap,
        justifyContent:
          isBetween && minWidth !== 0 && dataLength >= rowLength
            ? "space-between"
            : "flex-start",
        // 移除负边距以确保布局一致性
        // marginBottom: -boxGap,
      }}
      {...rest}>
      {isInitialized &&
        React.Children.map(itemsToRender, (child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { key: child.key || index });
          }
          return child;
        })}
      {/* 添加占位符 */}
      {isInitialized &&
        placeholders.map((_, i) => (
          <div
            className="grid-item placeholder"
            style={{ width: placeholdersWidth }}
            key={`placeholder-${i}`}></div>
        ))}
    </div>
  );
};

GridContainer.Item = Item;
GridContainer.Hide = Hide;

export default GridContainer;
