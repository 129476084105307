import { ProjectTypeEnum } from "@/pages/CreationSpace/components/ProjectCard/ProjectCard";
import empty from "@/assets/create-space/script-clear.png";
import FolderCard from "../FolderCard/FolderCard";
import CheckBoxHoc from "../CheckBoxHoc/CheckBoxHoc";
import MaterialCard from "../MaterialCard/MaterialCard";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import "./MaterialContianer.less";
import { Message } from "@arco-design/web-react";
import _ from "lodash";
import { observer } from "mobx-react";
import {Fragment, useRef, useState, useEffect} from "react";
import {fileUploadStore} from "@/stores/UploadStroe";
import { v4 as uuid } from "uuid";
import UploadButtonBg from "@/components/business/CommonUpload/UploadButtonBg/UploadButtonBg";
import LocalUploadCard from "@/components/business/CommonUpload/LocalUpload/LocalUploadCard/LocalUploadCard";
import * as console from "console";
export const getCheckboxState = (arr: string[], name: string) => {
  return arr.indexOf(name) > -1;
};

interface IMaterialContaienrPorps {
  importCollection?: string[];
  collection: IMaterialDetail[];
  handleCollectionChange: (data: IMaterialDetail[]) => void;
  data: IMaterialDetail[];
  handleFolderClick: (fileInfo: any) => void;
  maxCollectionLength?: number;
  allCollectionLength: number;
  tip: string;
  filterCondition?: (item: IMaterialDetail) => boolean;
  allowType?: string;
  currentFolderId: string | number;
  projectId: number;
  taskId: string;
  onChange: (e: boolean, item: IMaterialDetail) => void;
  onScrollBottom?: () => void;
  searchName: string;
  changeName: () => void;
}

const MaterialContainer: React.FC<IMaterialContaienrPorps> = (props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [ids, setIds] = useState<string[]>([]);
  const {
    collection,
    handleCollectionChange,
    data,
    allCollectionLength,
    handleFolderClick,
    importCollection,
    maxCollectionLength,
    filterCondition,
    tip,
    allowType,
    currentFolderId,
    projectId,
    taskId,
    onChange,
    onScrollBottom,
    searchName,
    changeName
  } = props;

  const handleChangeCollection = (item: IMaterialDetail, e: boolean) => {
    if (e) {
      if (maxCollectionLength === allCollectionLength) {
        return Message.error(tip);
      }
      const newCollection = [...collection];
      newCollection.push(item);
      handleCollectionChange(newCollection);
    } else {
      const index = collection.findIndex((step) => item.id === step.id);
      if (index > -1) {
        const newCollection = [...collection];
        newCollection.splice(index, 1);
        handleCollectionChange(newCollection);
      }
    }
  };
  const handleFilesChange = () => {
    const groupName = `分组${fileUploadStore.uploadTasks.length > 0 ? fileUploadStore.uploadTasks.length : 0}`;
    const files = fileUploadRef.current?.files;
    const batchIds = uuid();
    setIds([...ids, batchIds]);
    if (files) {
      if (files.length > 100) {
        Message.error("最大限制100个文件数！");
        return;
      }
      const fileArray = Array.from(files);
      fileUploadStore.addUploadTasks(
        fileArray,
        Number(currentFolderId) as any,
        projectId,
        groupName,
        batchIds,
        taskId,
      );
      fileUploadRef.current.value = "";
    }
  };
  const handleUploadClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };
  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= (scrollHeight)) {
        onScrollBottom();
      }
    }
  };
  const toGetMaterList = (name: string) => {
    if (name) {
      return data.filter((item) => item.name.includes(name));
    } else {
      return data;
    }
  }

  useEffect(() => {
    const element = scrollRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollRef.current]); // 空依赖数组意味着只在组件挂载时添加监听器

  return (
    <div className="material-folder-container">
      <div ref={scrollRef} className="upload-material-folder-contaienr">
        {
          <>
            <input
                type="file"
                style={{display: "none"}}
                accept={allowType}
                multiple={true}
                ref={fileUploadRef}
                onChange={handleFilesChange}
            />
            <div
                className="Ton-wrapper"
                style={{
                  width: "106px",
                  height: "140px",
                  marginRight: 16,
                  marginBottom: 7,
                }}>
                <UploadButtonBg
                    style={{
                      width: "106px",
                      height: "112px",
                      marginRight: 16,
                      marginBottom: 7,
                      background: "#f5f8ff",
                    }}
                    onClick={() => {
                      handleUploadClick();
                    }}
                />
            </div>
            {(fileUploadStore?.uploadTasks || [])
              .filter((uploadTasks) => uploadTasks.taskId === taskId)
              .map((batch) =>
                batch.taskList
                  .filter(
                    (item) =>
                      item.folder_id === currentFolderId &&
                      item.project_id === projectId,
                  )
                  .map((task) => (
                    <Fragment key={task.id}>
                      <LocalUploadCard
                        onChange={onChange}
                        checked={
                          collection.findIndex(
                            (step) => step?.id === task?.detailMaterial?.id,
                          ) > -1
                        }
                        item={task.detailMaterial}
                        cover={task.img}
                        type={task.type as CardTypeEnum}
                        error={task.error}
                        name={task.file.name}
                        state={task.status}
                        handleCloseBtnClick={() => {
                          fileUploadStore.cancelUploadTask(task.id);
                        }}
                        handleCencelBtnClick={() => {
                          fileUploadStore.cancelUploadTask(task.id);
                        }}
                        progress={task.progress}
                      />
                    </Fragment>
                  )),
              )}
            {(toGetMaterList(searchName)).map((item, index) => {
              return (
                <div
                  key={item.id}
                  style={{marginRight: 10, marginBottom: 7}}
                >
                  {item.type === (ProjectTypeEnum.FOLDER as any) ? (
                    <FolderCard changeName={() => {
                      changeName()
                    }} id={item.id} onClick={() => {handleFolderClick(item)}} name={item.name}/>
                  ) : (
                    <>
                      <CheckBoxHoc
                        style={{height: 112, borderRadius: 8}}
                        checked={
                          collection.findIndex((step) => step.id === item.id) > -1
                        }
                        disabled={
                          filterCondition ? !filterCondition(item) : false
                        }
                        onCheckedChange={(e) => {
                          handleChangeCollection(item, e);
                        }}>
                        <MaterialCard
                          onClick={() => {
                            handleChangeCollection(
                              item,
                              !(
                                collection.findIndex(
                                  (step) => step.id === item.id,
                                ) > -1
                              ),
                            );
                          }}
                          isImport={(importCollection || []).includes(item.id)}
                          type={item.type as CardTypeEnum}
                          img={item?.play_info?.cover_url}
                        />
                      </CheckBoxHoc>
                      <div className="file-upload-small-material-card-content">
                        <p title={item.name}>{item.name}</p>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
            </>
        }
        {
          fileUploadStore?.uploadTasks?.length === 0 && toGetMaterList(searchName)?.length === 0 && <div className="upload-modal-empty">
              <img src={empty} alt=""/>
              <p>暂无数据</p>
          </div>
        }
      </div>
    </div>
  );
};

export default observer(MaterialContainer);
