import "./SimpleBream.less";
import {Button, Image, Input} from "@arco-design/web-react";
import React from "react";
import {add_folder_icon} from "@/assets/creationscriptimage";
const InputSearch = Input.Search;
export interface IBreamProps {
  list: any[];
  handleBreamCrumbItemClick: (item: any) => void;
  onSearch?: (e: any) => void;
  addFile: () => void;
  showAddFile?: boolean;
}
import { fileUploadStore } from '@/stores/UploadStroe'
const SimpleBream: React.FC<IBreamProps> = (props) => {
  const { list, handleBreamCrumbItemClick, onSearch, addFile, showAddFile = false } = props;
  return (
    <div className="crumb-box">
      <ul className="bread-crumb">
        <li key={-1}>
        <span
          className="small-item"
          onClick={() => {
            handleBreamCrumbItemClick({
              name: fileUploadStore.checkProjectName,
              id: fileUploadStore.checkProjectId,
            });
          }}>
          {fileUploadStore.checkProjectName} <span style={{paddingLeft: '10px', paddingRight: '10px'}}>/</span>
        </span>
        </li>
        {list.map((item, index) => {
          return (
            <li key={item.id}>
              {index ? <span className="interval">/</span> : ""}
              <span
                className={`${index === list.length - 1 ? "active" : ""}  small-item`}
                onClick={() => {
                  handleBreamCrumbItemClick(item);
                }}>
              {item.name}
            </span>
            </li>
          );
        })}
      </ul>
      <div className={'flex--start'}>
        {
          list.length < 3 && showAddFile && <Button
                type="outline"
                onClick={() => {
                  addFile()
                }}
                icon={
                  <Image
                    src={add_folder_icon}
                    style={{marginRight: 4, marginTop: -5}}
                    width={16}
                    preview={false}
                  />
                }
                style={{
                  borderRadius: "4px",
                  height: 38,
                  marginRight: 10
                }}>
                <span style={{paddingLeft: 8}}>新建文件夹</span>
            </Button>
        }
        <InputSearch
          allowClear
          placeholder="搜索"
          style={{ width: "210px", height: 38 }}
          onChange={(e) => {
            onSearch(e);
          }}
        />
      </div>
    </div>
  );
};

export default SimpleBream;
