import React, { useMemo } from "react";
import { Image, Tooltip, Popover, Progress } from "@arco-design/web-react";
import { compound_icon, mytask_icon } from "@/assets/taskcenter";
import { IconRight } from "@arco-design/web-react/icon";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "./index.less";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import User from "@/stores/userStore";
interface ITaskListProps {
  item?: any;
  id?: string;
  onClick: () => void;
  setVisible: (opt: boolean) => void;
  timer?: string;
  type?: string;
}

const MaterialList: React.FC<ITaskListProps> = ({
  item,
  onClick,
  setVisible,
  timer,
  type,
}) => {
  const navigate = useNavigate();

  const uploadLengthFUn = () => {
    let count = 0;
    if (type == "compound") {
      count = item.filter((v) => v.status == 1).length;
    } else {
      item.forEach((element) => {
        if (element.status === "parseLoading" || element.status === "success") {
          count++;
        }
      });
    }

    return count;
  };
  const EllipsisFilename = (filename) => {
    // 使用正则表达式拆分文件名和扩展名
    const match = filename.match(/^(.*?)(\.[^.]*)$/);
    const name = match ? match[1] : filename;
    const extension = match ? match[2] : "";

    return (
      <div className="ellipsis-filename">
        <span className="filename-name">{name}</span>
        <span className="filename-extension">{extension.slice(1)}</span>
      </div>
    );
  };
  const calculateValue = (percentage) => {
    if (percentage < 0 || percentage > 100) {
      throw new Error("Percentage must be between 0 and 100.");
    }
    // 根据百分比计算对应的数值
    const value = 25 * (percentage / 100);
    // 向下取整到小数点后两位（可选，根据题目要求）
    const roundedValue = Math.floor(value * 100) / 100;
    return roundedValue;
  };

  const isExamineFun = () => {
    return item.every((item) => item.isExamine);
  };
  console.log("====================================");
  console.log(toJS(item));
  console.log("====================================");
  return (
    <div
      className="my-task-root"
      onClick={() => {
        navigate(`/task-center`, {
          state: type == "compound" ? "compound" : "upload",
        });
        setVisible(false);
        onClick();
      }}>
      <div className="task-image">
        <div
          style={{ display: isExamineFun() ? "none" : "block" }}
          className="unread-circle"></div>
        <Image src={compound_icon} preview={false} width={70} />
        {!item.every((v) => v.status == 1) && !uploadLengthFUn() ? (
          <div className="task-mask">
            <div className="circle-container">
              <svg viewBox="0 0 16 16" className="arco-progress-circle-svg">
                <circle
                  className="arco-progress-circle-mask"
                  fill="none"
                  cx="8"
                  cy="8"
                  r="4"
                  strokeWidth="8"
                  style={{
                    strokeDasharray:
                      25 +
                      calculateValue(
                        item[0].progress == 100 ? 99 : item[0].progress,
                      ),
                    strokeDashoffset: 25,
                  }}></circle>
                <circle
                  className="arco-progress-circle-path"
                  fill="none"
                  cx="8"
                  cy="8"
                  r="4"
                  strokeWidth="8"></circle>
              </svg>
            </div>
            <p>
              {item[0]?.status == "queued"
                ? "等待中..."
                : `${type == "compound" ? "合成" : "上传"}中${Math.floor(item[0].progress == 100 ? 99 : item[0].progress)}%`}
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="task-middle-box">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className={`my-task-type ${type == "compound" ? "type-color-p" : "type-color-g"}`}>
            {type == "compound" ? "合成" : "上传"}
          </span>
          <h2>
            <span>
              {item.length == 1
                ? EllipsisFilename(item[0].name)
                : `${item[0].name.slice(0, 20)}... 等${item.length}个文件`}
            </span>
          </h2>
        </div>
        <p className="task-user-info">
          {/* {item.uploadCount[0]}/{item.uploadCount[1]} */}
          <span>
            {type == "compound" ? "合成" : "上传"}数:{" "}
            {`${uploadLengthFUn()} / ${item.length}`}{" "}
          </span>
          <span>· 时间: {timer}</span>
        </p>
        <p>
          <span>操作人: {User?.teamInfo?.team_user_role?.nickname || ""}</span>
        </p>
      </div>
      <div>
        <IconRight fontSize={20} />
      </div>
    </div>
  );
};

export default observer(MaterialList);
