import { Popover, Progress, Tooltip, Image } from "@arco-design/web-react";
import { IconClose } from "@arco-design/web-react/icon";
import exclamationImg from "@/assets/upload-modal/exclamation-point.png";
import loadingImg from "@/assets/create-space/loading.svg";
import musicUpload from "@/assets/create-space/upload-music.png";
import WaitImg from "@/assets/create-space/shalou.png";
import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import "./UploadFileNode.less";

export type UploadNodeState =
  | "queued"
  | "uploading"
  | "success"
  | "error"
  | "cancelled"
  | "parseError"
  | "parseLoading";

export interface IUploadFileNodeProps {
  name?: string;
  state: UploadNodeState;
  progress?: number;
  coverImg?: string;
  style?: React.CSSProperties;
  error?: string;
  type: string;
  handleCloseBtnClick?: () => void;
  handleCencelBtnClick?: () => void;
}

const getWaitElement = (handleCloseBtnClick: () => void) => {
  return (
    <div className="wait-wrapper">
      <div className="wait-msg">
        <Image style={{ display: 'block', margin: '0 auto', marginBottom: '5px' }} src={WaitImg} width={22} height={22} />
        <span>等待中</span>
        {/*<IconClose*/}
        {/*  style={{ fontSize: 10, cursor: "pointer" }}*/}
        {/*  onClick={() => {*/}
        {/*    if (!handleCloseBtnClick) {*/}
        {/*      console.error("请传入handleCloseBtnClick配置项");*/}
        {/*    } else {*/}
        {/*      handleCloseBtnClick();*/}
        {/*    }*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
      {/*<Progress percent={0} color={"#333333"} showText={false} />*/}
    </div>
  );
};

const getUploading = (
  progress: number,
  handleCencelBtnClick: () => void,
  img: string,
  type: string,
) => {
  return (
    <div className="uploading-wrapper">
      <div className="cover-img-wrapper">
        {type !== CardTypeEnum.MUSIC ? (
          <img src={img} alt="" className="cover-img" />
        ) : (
          <img src={musicUpload} style={{ objectFit: "contain" }}></img>
        )}
      </div>
      <div className="cover-mask"></div>
      <div className="uploading-progress-wrapper">
        <div className="opearte">
          <div>{progress}%</div>
          <IconClose
            style={{ fontSize: 10, cursor: "pointer", color: "white" }}
            onClick={() => {
              if (!handleCencelBtnClick) {
                console.error("请传入handleCloseBtnClick配置项");
              } else {
                handleCencelBtnClick();
              }
            }}
          />
        </div>
        <Progress
          percent={progress || 0}
          showText={false}
          color="#ffffff"
          style={{ position: "relative", zIndex: 10 }}
        />
      </div>
      <p
        onClick={() => {
          if (!handleCencelBtnClick) {
            console.error("请传入handleCencelBtnClick配置项");
          } else {
            handleCencelBtnClick();
          }
        }}>
        取消上传
      </p>
    </div>
  );
};

const getFileParseErrorElement = () => {
  return <div className="parse-error-wrapper">文件解析失败</div>;
};

const getFileParseLoading = (img: string, type: string) => {
  return (
    <div className="parse-loading-wrapper">
      <div className="cover-img-wrapper">
        {type !== CardTypeEnum.MUSIC ? (
          <img src={img} alt="" className="cover-img" />
        ) : (
          <img src={musicUpload} style={{ objectFit: "contain" }}></img>
        )}
      </div>
      <div className="cover-mask"></div>
      <div className="parse-progress-wrapper">
        <img
          src={loadingImg}
          style={{ width: "20px", height: "20px" }}
          alt=""
          className="loading-img"
        />
      </div>
      <p>云端处理中</p>
    </div>
  );
};

const getUploadErrorElement = (
  state: number,
  img: string,
  error?: string,
  type?: string,
) => {
  return (
    <div className="upload-error-wrapper">
      <div className="cover-img-wrapper">
        {type !== CardTypeEnum.MUSIC ? (
          <img src={img} alt="" className="cover-img" />
        ) : (
          <img src={musicUpload} style={{ objectFit: "contain" }}></img>
        )}
      </div>
      <div className="cover-mask"></div>
      <div className="upload-progress-wrapper">
        <div className="error-tip-wrapper">
          <Tooltip content={error} className={"upload-error-tip"}>
            <img src={exclamationImg} alt="" style={{ cursor: "pointer" }} />
          </Tooltip>
        </div>
        <Progress
          percent={state}
          color="#F98282"
          showText={false}
          className={"error-progress"}
        />
      </div>
      <p>上传失败</p>
    </div>
  );
};

const UploadFileNode: React.FC<IUploadFileNodeProps> = (props) => {
  const {
    name,
    state,
    progress,
    style,
    handleCloseBtnClick,
    handleCencelBtnClick,
    coverImg,
    type,
    error,
  } = props;
  const toStateGetElement = (
    state: UploadNodeState,
    img: string,
    type?: string,
    error?: string,
  ) => {
    const obj = {
      error: getUploadErrorElement(55, img, error, type),
      uploading: getUploading(progress, handleCencelBtnClick, img, type),
      queued: getWaitElement(handleCloseBtnClick),
      parseError: getFileParseErrorElement(),
      parseLoading: getFileParseLoading(img, type),
    };
    return obj[state];
  };
  return (
    <div className="upload-node-wrapper" style={style}>
      <div
        className="tip-block-wrapper"
        style={{ borderRadius: 8, overflow: "hidden" }}>
        {toStateGetElement(state, coverImg, type, error)}
      </div>
      <p className="name">{name}</p>
    </div>
  );
};

export default UploadFileNode;
