import React, { useState, useEffect, useRef } from "react";
import {
  Dropdown,
  Image,
  Menu,
  Message,
  Modal,
  Popover,
  Tooltip,
  Trigger,
} from "@arco-design/web-react";
import "./index.less";
import { edit_icon, logoImg } from "@/assets/headimage";
import {
  IconApps,
  IconCaretRight,
  IconCheck,
  IconRobot,
} from "@arco-design/web-react/icon";
import {
  change_team_icon,
  create_team_icon,
  logout_icon,
  user_center_icon,
} from "@/assets/user-center";
import { getIsTeam, getTeamsSwitch, setUserName } from "@/services/user/user";
import { inject, observer } from "mobx-react";
import CreateTeam from "./components/CreateTeam";
import { setTokenToLocalStorage } from "@/utils/token";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ChangeTeam from "./components/ChangeTeam";
import MyService from "./components/MyService";
import UserInfo from "@/stores/userStore";
import _ from "lodash";
import RolePermission from "../RolePermission";
import { Permissions } from "../RolePermission/permissions";
import { fileUploadStore } from "@/stores/UploadStroe";
import ChangeModal from "./components/ChangeModal";
import { toJS } from "mobx";
import ExclusiveModal from "./components/ExclusiveModal";
import VideoRes from "@/stores/VideoResult";
import { teamVideoMixCutCount } from "@/services/mixCutDetail/mixCutDetail";
import dayjs from "dayjs";
const MenuItem = Menu.Item;

const MyIcon = (src, flex = false) => {
  return flex ? (
    <div className={"my-icon"}>
      <img src={src} width={14} />
    </div>
  ) : (
    <img src={src} width={14} style={{ marginRight: 12 }} />
  );
};

const User: React.FC = () => {
  const [visible, setVisible] = React.useState(false);
  const [visibleTeam, setVisibleTeam] = React.useState(false);
  const [visibleService, setVisibleService] = useState(false);
  const [teamId, setTeamId] = useState<number>(-1);
  const [userInputIsFocus, setUserInputIsFocus] = useState(false);
  const [switching, setSwitching] = useState(false);
  const [expiresAt, setExpiresAt] = useState<number>(0);
  const userInputRef = useRef(null);
  const [values, setValues] = useState<string>("");
  const locations = useLocation();
  const navigate = useNavigate();
  const [changeModal, setChangeModal] = useState<boolean>(false);
  const [hoverEdit, setHoverEdit] = useState<boolean>(false);
  const handleChangeModal = () => {
    setChangeModal(!changeModal);
  };
  const setChangeTeam = async (id?) => {
    fileUploadStore.uploadTasks = [];
    setSwitching(true);
    const res = await getTeamsSwitch(id || teamId);
    if (res?.code == 0) {
      setTokenToLocalStorage(res.data.token || "");
      window.location.href = "/work-bench";
      Message.success("切换成功");
      VideoRes.task_no = [];
      VideoRes.videoResList = [];
      if (UserInfo.CreateTeamMoadl) {
        UserInfo.setChangeTeamModal();
      }
      setSwitching(false);
    } else {
      Message.error(res);
    }
    setVisible(false);
    UserInfo.getTeamInfo();
  };
  const formatMemorySize = (mb) => {
    if (typeof mb !== "number" || mb < 0) {
      throw new Error("输入必须是一个非负的数值。");
    }

    const GB = 1024;
    const TB = GB * 1024;

    if (mb >= TB) {
      const tb = mb / TB;
      // 保留两位小数，去除多余的零
      return `${parseFloat(tb.toFixed(2))} TB`;
    } else if (mb >= GB) {
      const gb = mb / GB;
      // 保留两位小数，去除多余的零
      return `${parseFloat(gb.toFixed(2))} GB`;
    } else {
      return `${mb} MB`;
    }
  };

  const getTeamVideoMixCutCount = async () => {
    const res = await teamVideoMixCutCount();
    if (res?.items) {
      setExpiresAt(res?.items[0].expires_at);
    }
  };
  const editNameFun = async () => {
    const res = await setUserName({
      nick_name: values,
    });
    if (res?.code == 0) {
      UserInfo.getUserInfo();
    } else {
      Message.error(res);
    }
    setUserInputIsFocus(false);
  };
  useEffect(() => {
    if (visible) {
      UserInfo.getTeamInfo();
      getTeamVideoMixCutCount();
    }
  }, [visible]);
  useEffect(() => {
    UserInfo.getUserInfo();
    UserInfo.getDataIntegration();
  }, []);

  useEffect(() => {
    if (userInputIsFocus) {
      userInputRef.current.focus();
    }
  }, [userInputIsFocus]);

  const teamContent = () => {
    return (
      <div className="team-content">
        <ChangeModal
          visible={changeModal}
          handleChangeMoadl={handleChangeModal}
          handleClick={setChangeTeam}
        />
        {UserInfo.teamInfo.teamList.items &&
        UserInfo.teamInfo.teamList.items.length ? (
          UserInfo.teamInfo.teamList.items.map((item, index) => (
            <div
              key={index}
              onClick={(e) => {
                if (item.member_state == 4) {
                  return;
                }
                if (item.status != 1) {
                  setTeamId(item.id);
                  if (
                    !toJS(
                      fileUploadStore.uploadTasks.map((v) => v.taskList),
                    ).every((subArray) =>
                      subArray.every((item) => item.status !== "uploading"),
                    )
                  ) {
                    handleChangeModal();
                  } else {
                    if (
                      _.get(UserInfo, "teamInfo.team_user_role.team.id", "") ==
                      item.id
                    ) {
                      Message.warning("您已在当前团队");
                      return;
                    }
                    setChangeTeam(item.id);
                  }
                }
              }}>
              <Tooltip
                content={
                  item.member_state == 4 ? (
                    <div style={{ padding: 5 }}>
                      无法切换，当前账号已在此团队中被停用
                    </div>
                  ) : (
                    ""
                  )
                }>
                <MenuItem
                  key={item.id}
                  style={{
                    cursor:
                      item.status == 1 || item.member_state == 4
                        ? "not-allowed"
                        : "pointer",
                    marginBottom: 5,
                  }}>
                  <div className="team-logo">
                    <div className="team-name">
                      <img src={logoImg} width={20} alt="" />
                    </div>
                    <p className="team-text">{item.name}</p>
                  </div>

                  <div>
                    {item.status == 1 ? (
                      <div className="team-status">停用</div>
                    ) : (
                      <></>
                    )}
                    {item.name ==
                    _.get(UserInfo, "teamInfo.team_user_role.team.name", "") ? (
                      <IconCheck style={{ color: "#447FFF" }} />
                    ) : (
                      <></>
                    )}
                  </div>
                </MenuItem>
              </Tooltip>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    );
  };
  const handleOnClickMenu = (key) => {
    switch (key) {
      case "2-1":
        UserInfo.setCreateTeamModal();
        break;
      case "2-2":
        navigate("/user");
        break;
      case "2-3":
        localStorage.removeItem("VideoResult");
        localStorage.removeItem("uploadTasks");
        localStorage.removeItem("isExpired_at_package");
        window.location.href = "/login";
        break;
      default:
        break;
    }
    if (key != "1") {
      if (
        toJS(fileUploadStore.uploadTasks.map((v) => v.taskList)).every(
          (subArray) => subArray.every((item) => item.status !== "uploading"),
        )
      ) {
        setVisible(false);
      }
    }
  };
  const teamMenu = () => {
    return (
      <Menu mode="pop" onClickMenuItem={(e) => handleOnClickMenu(e)}>
        <Popover
          position="left"
          trigger="hover"
          popupVisible={visibleTeam}
          onVisibleChange={setVisibleTeam}
          content={teamContent()}>
          <MenuItem
            key="1"
            style={{
              display:
                UserInfo.teamInfo.teamList?.items?.length >= 1
                  ? "flex"
                  : "none",
            }}>
            {MyIcon(change_team_icon, true)}
            切换团队
          </MenuItem>
        </Popover>

        <MenuItem
          key="2-1"
          style={{
            display: UserInfo.teamInfo.teamList?.team_id > 0 ? "none" : "flex",
          }}>
          {MyIcon(create_team_icon)}
          创建团队
        </MenuItem>
        <MenuItem key="2-2">
          {MyIcon(user_center_icon)}
          个人中心
        </MenuItem>
        <MenuItem key="2-3">
          {MyIcon(logout_icon)}
          退出登录
        </MenuItem>
      </Menu>
    );
  };
  const teamInformation = (type) => {
    return UserInfo.dataIntegration?.benefits?.find((v) => {
      return v.benefit_type == type;
    });
  };
  const content: any = () => {
    return (
      <div className="user-box">
        <div className="user-header">
          <div
            style={{
              background: UserInfo.userInfo?.avatar
                ? ""
                : UserInfo.userInfo?.avatar_color,
            }}>
            {UserInfo.userInfo?.avatar ? (
              <img
                width={46}
                style={{
                  borderRadius: "50%",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                src={UserInfo.userInfo?.avatar}
                alt=""
              />
            ) : (
              UserInfo.userInfo?.nickname.slice(-2)
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <div>
              <div
                onMouseMove={() => {
                  if (!userInputIsFocus) {
                    setHoverEdit(true);
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onMouseLeave={() => {
                  if (!userInputIsFocus) {
                    setHoverEdit(false);
                  }
                }}>
                <p className="user-name">
                  {userInputIsFocus ? (
                    <input
                      className="user-input"
                      ref={userInputRef}
                      value={values}
                      onChange={(e) => {
                        setValues(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          editNameFun();
                        }
                      }}
                      onBlur={editNameFun}
                    />
                  ) : (
                    values || UserInfo.userInfo?.nickname
                  )}
                </p>
                <div>
                  <img
                    style={{
                      opacity: hoverEdit ? 1 : 0,
                      position: "absolute",
                      cursor: "pointer",
                      top: 24,
                    }}
                    src={edit_icon}
                    width={20}
                    alt=""
                    onClick={() => {
                      setHoverEdit(false);
                      setUserInputIsFocus(true);
                      setValues(UserInfo.userInfo?.nickname);
                    }}
                  />
                </div>
              </div>
              <p
                className="team-name"
                style={{
                  borderTop: `1px solid ${userInputIsFocus ? "#d8d8d8" : "transparent"}`,
                }}>
                团队名:
                {_.get(UserInfo.teamInfo, "team_user_role.team.name", "---")}
              </p>
            </div>
          </div>
        </div>
        <RolePermission.ShowHide
          requiredPermissions={Permissions.EntitlementCard}>
          <div className="user-vip-box">
            <div className="legitimate-box">
              <div>摘星权益</div>
              <div
                onClick={() => setVisibleService(true)}
                style={{ display: "none" }}>
                权益升级
              </div>
            </div>
            <div className="legitimate-info">
              <div>
                <p>{teamInformation(1)?.benefit_value || 0}</p>
                <p>视频点数</p>
              </div>
              <div>
                <p>
                  {formatMemorySize(teamInformation(3)?.benefit_value || 0)}
                </p>
                <p>剩余空间</p>
              </div>
              <div>
                <p>{teamInformation(2)?.benefit_value || 0}</p>
                <p>剩余席位</p>
              </div>
            </div>
            {expiresAt ? (
              <div className="term-of-validity">
                <div></div>
                <p>
                  有效期至
                  {dayjs(expiresAt * 1000).format("YYYY-MM-DD")}
                </p>
                <div></div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </RolePermission.ShowHide>
        <div className="user-tool">
          <div>{teamMenu()}</div>
        </div>
      </div>
    );
  };
  const isTeamFun = async () => {
    const res = await UserInfo.getTeamStatus();
    if (res && locations.pathname != "/work-bench") {
      window.location.href = "/work-bench";
    }
  };
  useEffect(() => {
    isTeamFun();
  }, []);
  return (
    <div>
      <MyService
        visible={visibleService}
        setVisible={() => {
          setVisibleService(false);
        }}
      />
      <CreateTeam
        isTeam={UserInfo.isHaveTeam}
        handleChangeMoadl={UserInfo.setCreateTeamModal}
        visible={UserInfo.CreateTeamMoadl}
      />
      <ChangeTeam
        handleChangeMoadl={UserInfo.setChangeTeamModal}
        visible={UserInfo.ChangeTeamMoadl}
        handleCreate={UserInfo.setCreateTeamModal}
        handleClickConfirm={(id) => {
          setChangeTeam(id);
        }}
      />
      <ExclusiveModal
        visible={UserInfo.ExclusiveModal}
        handleChangeMoadl={UserInfo.setExclusiveModal}
      />
      <Popover
        position="br"
        popupVisible={visible}
        onVisibleChange={() => {
          setVisible(!visible);
          setUserInputIsFocus(false);
        }}
        content={content}>
        <div
          className="user-root"
          style={{
            background: UserInfo.userInfo?.avatar
              ? ""
              : UserInfo.userInfo?.avatar_color,
          }}>
          {UserInfo.userInfo?.avatar ? (
            <img
              width={42}
              style={{
                borderRadius: "50%",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "top",
              }}
              src={UserInfo.userInfo?.avatar}
              alt=""
            />
          ) : (
            UserInfo.userInfo?.nickname.slice(-2)
          )}
        </div>
      </Popover>
    </div>
  );
};

export default observer(User);
