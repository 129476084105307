import { makeAutoObservable, action, toJS } from "mobx";
import { compare, applyPatch } from "fast-json-patch";
class CreationScriptStores {
  dataSource = [];

  constructor() {
    makeAutoObservable(this);
  }

  updateData = action((index, type, value) => {
    const newData = [...this.dataSource];
    newData[index][type] = value;
    this.dataSource = newData;
  });

  changeCaption = action((index, type, value) => {
    const newData = [...this.dataSource];
    newData[index][type] = value;
    this.dataSource = newData;
  });
  addCaptions = action((key) => {
    const newData = [...this.dataSource];
    newData.forEach((item) => {
      item[`caption_${key}`] = [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
      ];
    });
    this.dataSource = newData;
  });
  delData = action((newData) => {
    this.dataSource = JSON.parse(JSON.stringify(newData));
  });
  compareUpdate = action((newData) => {
    // 计算旧数据和新数据之间的差异
    const patch = compare(this.dataSource, newData);
    // 应用差异到旧数据上
    applyPatch(this.dataSource, patch);
  });
}

const creationStores = new CreationScriptStores();
export default creationStores;
