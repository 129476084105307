import {makeAutoObservable, observable} from "mobx";
import { IMaterialPlayInfo, ISences } from "./type";
import transition from './transition';
import {ITransition, ITransitionEffectItem} from "@/stores/mixCutDetailStore/type/transition";
class MixedConfig {
  version = "1.0.0";
  _materials = {}
  scenes: ISences[]
  transition: ITransition
  global_settings = {
    enabled: null,
    titles: null,
    captions: null,
    transition: null,
    background: {
      audio: [],
      color: "white",
      images: [],
      audio_volume: 50,
    },
    cover: null,
  }
  constructor() {
    makeAutoObservable(this, {
      _materials: observable,
      scenes: observable,
      transition: observable,
      global_settings: observable
    });
  }
  initSence () {
    // this.scenes = [new scene()];
    // this.transition = [new transition()];
    // this.global_settings = new global_settings();
  }
  // 设置配置
  public setMaterials (materials: object) {
    this._materials = materials;
  }
  // 设置场景
  public setScene (scene) {
    this.scenes = scene;
  }
  // 设置转场
  public setTransition (transition: ITransition) {
    this.transition = transition;
  }
  // 设置全局配置
  public setGlobalSettings (global_settings) {
    this.global_settings = global_settings;
  }
  // 设置版本号
  public setVersion (version: string) {
    this.version = version;
  }

  toJSON () {
    return {
      version: this.version,
      _materials: this._materials,
      scenes: this.scenes,
      transition: this.transition,
      global_settings: this.global_settings
    }
  }
}
const store = new MixedConfig();
export default store;
