// SensitiveWordHighlight.ts
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

interface SensitiveWordHighlightOptions {
  sensitiveWords: string[];
}

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // 转义正则字符
};

const SensitiveWordHighlight = Extension.create<SensitiveWordHighlightOptions>({
  name: "sensitiveWordHighlight",

  addOptions() {
    return {
      sensitiveWords: [],
    };
  },

  addStorage() {
    let sensitiveWords = this.options.sensitiveWords;

    return {
      getWords: () => sensitiveWords,
      setWords: (words: string[]) => {
        sensitiveWords = words;
      },
    };
  },

  addProseMirrorPlugins() {
    const pluginKey = new PluginKey("sensitiveWordHighlight");

    return [
      new Plugin({
        key: pluginKey,
        props: {
          decorations: ({ doc }) => {
            const decorations: Decoration[] = [];
            const words = this.storage.getWords();

            doc.descendants((node, pos) => {
              if (node.isText) {
                const text = node.text || "";
                words.forEach((word) => {
                  if (word === "") return; // 忽略空字符串
                  const escapedWord = escapeRegExp(word);
                  const regex = new RegExp(escapedWord, "g");
                  let match;
                  while ((match = regex.exec(text)) !== null) {
                    const start = pos + match.index;
                    const end = start + word.length;
                    decorations.push(
                      Decoration.inline(start, end, {
                        class: "sensitive-word",
                      }),
                    );
                  }
                });
              }
            });

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});

export default SensitiveWordHighlight;
