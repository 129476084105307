import { useState } from "react";
import {
  creativesscript_g_icon,
  creativesscript_icon,
  matrixmarketing_icon,
  taskcenter_g_icon,
  taskcenter_icon,
  teammanagement_g_icon,
  teammanagement_icon,
  videoclip_g_icon,
  videoclip_icon,
  workbench_g_iocn,
  workbench_iocn,
  creativespace_g_icon,
  creativespace_icon,
  videoclip_w_icon,
  onekey_w_icon,
  pictureposter_w_icon,
  creativesscript_w_icon,
  videoparsing_w_icon,
  hello_icon,
  faddish_icon,
  team_icon,
  team_people_icon,
} from "@/assets/workbenchimage";
import { Permissions } from "@/components/business/RolePermission/permissions";
import { getTokenToLocalStorage } from "@/utils/token";
function useData() {
  const [menuList] = useState([
    {
      id: "1",
      title: "工作台",
      path: "/work-bench",
      icon: [workbench_g_iocn, workbench_iocn],
      hot: false,
    },
    {
      id: "2",
      title: "创作空间",
      path: "/creative-space",
      icon: [creativespace_g_icon, creativespace_icon],
      hot: false,
      permissions: Permissions.CreativeSpace,
    },
    {
      id: "3",
      title: "创意脚本",
      children: [
        {
          id: "3-1",
          subTitle: "爆款脚本",
          path: "/creative-script",
          icon: {
            svg: faddish_icon,
            color: "linear-gradient( 131deg, #417AFF 0%, #73CEFF 100%)",
          },
          permissions: Permissions.HotScript,
        },
        {
          id: "3-2",
          subTitle: "创建脚本",
          path: "/creation-script",
          power: true,
        },
        {
          id: "3-3",
          subTitle: "AI主题创作",
          path: "/ai-creation",
          icon: {
            svg: creativesscript_w_icon,
            color:
              "linear-gradient( 129deg, #38BEDE 0%, rgba(65,199,231,0.6157) 100%)",
          },
          permissions: Permissions.AIThemeCreation,
        },
        {
          id: "3-6",
          subTitle: "AI视频解析",
          path: "/analysis-video",
          icon: {
            svg: videoparsing_w_icon,
            color: "linear-gradient( 132deg, #FF8F1F 0%, #FFD9B2 100%)",
          },
          permissions: Permissions.AIVideoAnalysis,
        },
      ],
      icon: [creativesscript_g_icon, creativesscript_icon],
      hot: false,
      permissions: Permissions.CreativeScript,
    },
    {
      id: "4",
      title: "视频剪辑",
      children: [
        {
          id: "4-1",
          subTitle: "智能混剪",
          path: "/video-clips",
          icon: {
            svg: videoclip_w_icon,
            color: "linear-gradient( 131deg, #417AFF 0%, #73CEFF 100%)",
          },
          permissions: Permissions.IntelligentEditing,
        },

        {
          id: "4-2",
          subTitle: "一键成片",
          path: "/",
          // accomplish
          icon: {
            svg: onekey_w_icon,
            color: "linear-gradient( 135deg, #427BFF 0%, #6EC6FF 100%)",
          },
        },
        {
          id: "4-3",
          subTitle: "图文海报",
          path: "/",
          // graphicediting
          icon: {
            svg: pictureposter_w_icon,
            color: "linear-gradient( 131deg, #964CF7 0%, #D3B2FE 100%)",
          },
        },
      ],
      icon: [videoclip_g_icon, videoclip_icon],
      hot: false,
    },
    {
      id: "5",
      title: "团队管理",
      children: [
        {
          id: "5-1",
          subTitle: "团队概览",
          path: "/team-overview",
          icon: {
            svg: team_icon,
            color: "linear-gradient( 131deg, #417AFF 0%, #73CEFF 100%)",
          },
        },
        {
          id: "5-2",
          subTitle: "团队成员",
          path: "/team-anagement",
          icon: {
            svg: team_people_icon,
            color: "linear-gradient( 135deg, #8E3FF6 0%, #CEA9FF 100%)",
          },
        },
      ],
      icon: [teammanagement_g_icon, teammanagement_icon],
      hot: false,
      permissions: Permissions.TeamManagement,
    },

    {
      id: "7",
      title: "矩阵营销",
      path: `/matrix`,
      icon: [matrixmarketing_icon, matrixmarketing_icon],
      blank: true,
      hot: true,
      font: true,
      permissions: Permissions.MatrixMarketing,
    },
    // {
    //   id: "3000",
    //   title: "测试模块",
    //   path: "/testupload",
    //   icon: [0, 0],
    //   hot: false,
    // },
    {
      id: "6",
      title: "任务中心",
      path: "/task-center",
      icon: [taskcenter_g_icon, taskcenter_icon],
      hot: false,
      permissions: Permissions.TaskCenter,
    },
  ]);
  return { menuList };
}

export default useData;
