import {
  Message,
  Modal,
  ModalProps,
  Spin,
  Tooltip,
  Button,
  Image
} from "@arco-design/web-react";
import { observer } from "mobx-react";
import MaterialContainer from "./MaterialContainer/MaterialContainer";
import { useEffect, useState, useRef } from "react";
import BatchCollection from "@/pages/CreationSpace/CreativeSpace/CreativeContainer/components/BatchAction/BatchCollection";
import useNavProject from "@/useStore/createspace/useProject";
import { getMaterialUploadData } from "@/services/createSpace/graphQ/getMetarialList";
import SimpleBream from "./SimpleBream/SimpleBream";
import ProjectList from "./ProjectList";
import avtiveImg from "@/assets/create-space/project-modal-icon.png";
import { throttle } from "lodash";
import { fileUploadStore } from '@/stores/UploadStroe'
import {
  IMaterialDetail,
  getSimpleMedia,
} from "@/services/createSpace/graphQ/getSimpleMedia";
import { IconClose } from "@arco-design/web-react/icon";
import "./index.less";
import CreateFolderModal from "@/pages/CreationSpace/CreativeSpace/CreativeContainer/components/SearchOpeator/CreateFolderModal/CreateFolderModal";
import WarningImg from '@/assets/warning.png'
interface IMaterialFileModalProps extends ModalProps {
  importCollection?: string[]; // 默认导入字样
  defaultCollection?: string[];
  defalutValue?: "material" | "local";
  allowType: string;
  handleOkBtnClick: (e: IMaterialDetail[]) => void;
  allowMetarial?: number[]; // 1视频 2图片 3音频 4文件夹
  disabledRecordStation?: boolean;
  maxCollectionLength?: number;
  filterCondition?: (item: IMaterialDetail) => boolean;
  showAddFile?: boolean;
}

const MaterialFileModal: React.FC<IMaterialFileModalProps> = observer(
  (props) => {
    const {
      allowType,
      handleOkBtnClick,
      allowMetarial,
      defalutValue,
      importCollection,
      defaultCollection,
      disabledRecordStation = false,
      maxCollectionLength,
      filterCondition,
      showAddFile,
      ...res
    } = props;
    const {
      projectList,
    } = useNavProject();
    const [showProject, setShowProject] = useState(false);
    const [collection, setCollection] = useState<IMaterialDetail[]>([]);
    const [projectId, setProjectId] = useState<number>(null);
    const [folderList, setFolderList] = useState<any[]>([
      { name: "素材库", id: 0 },
    ]);
    const refPage = useRef(1)
    const refTotal = useRef(0);
    const [checkFlag, setCheckFlag] = useState(false);
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [clickLoading, setClickLoading] = useState(false);
    const [materList, setMaterList] = useState<IMaterialDetail[]>([]);
    const [searchName, setSearchName] = useState("");
    const [parentId, setParentId] = useState<number>(0);
    const [createFolderModalVisible, setCreateFolderModalVisible] = useState(false);
    const [concatFlag, setConcatFlag] = useState(false);
    useEffect(() => {
      if (projectId) {
        getMaterialList();
      }
    }, [projectId, parentId, page]);

    const getMaterialList = () => {
      setLoading(true);
      getMaterialUploadData({
        projectId: projectId,
        parentId: parentId,
        type: allowMetarial,
        page: page,
        page_size: 30,
      }).then(res => {
        setLoading(false);
        setClickLoading(false);
        if (concatFlag) {
          const arr = materList.concat(res.data)
          setMaterList(arr);
        } else {
          setMaterList(res.data || []);
        }
        setTotal(res.total)
        refTotal.current = res.total;
        setConcatFlag(false);
      })
    }
    const floderItemClick = (item: IMaterialDetail | { name: string; id: number }) => {
      setClickLoading(true);
      setPage(1);
      if (folderList.length === 0) {
        setFolderList([{ name: "素材库", id: 0 }, item]);
        setParentId(0)
      } else {
        setFolderList([...folderList, item]);
        setParentId(Number(item.id))
      }
      // getMaterialList();
    }
    const resetPageTotal = () => {
      setPage(1);
      refPage.current = 1;
      setTotal(0);
      refTotal.current = 0;
    }

    const handleBreamCrumbItemClick = (
      item: IMaterialDetail | { name: string; id: number },
    ) => {
      resetPageTotal();
      if (item.id === fileUploadStore.checkProjectId) {
        setProjectId(0)
        setParentId(0)
        setMaterList([])
        setShowProject(true)
        return;
      }
      const index = folderList.findIndex((step) => step.id === item.id);
      if (index === folderList.length - 1) {
        return;
      }
      let clickId = 0;
      if (index === 0) {
        setFolderList([{ name: "素材库" }]);
        clickId = 0 as any;
        setParentId(0)
      } else {
        const newFolderList = folderList.slice(0, index + 1);
        setFolderList([...newFolderList]);
        clickId = newFolderList[newFolderList.length - 1]?.id;
      }
      setParentId(clickId as number);
      // getMaterialList()
    };
    const toCollectionGetMaterialInfo = () => {
      if (!collection || collection.length === 0) {
        return null;
      }
      return collection.map((item) => {
        return {
          ...item,
          type: item.type === "MOVIE" ? 1 : item.type,
        };
      });
    };

    const localCollection = (e: boolean, materialItem: IMaterialDetail) => {
      let newCollection = [...collection];
      if (e) {
        if (maxCollectionLength && collection.length === maxCollectionLength) {
          Message.error("以超过最大选择数，无法再次添加！");
          return;
        }
        newCollection = [...newCollection, materialItem];
      } else {
        const index = newCollection.findIndex(
          (item) => materialItem.id === item.id,
        );
        newCollection.splice(index, 1);
      }
      setCollection(newCollection);
    };

    useEffect(() => {
      console.log(fileUploadStore.checkProjectId, 'fileUploadStore.checkProjectId')
      if (fileUploadStore.checkProjectId) {
        setShowProject(false);
        setProjectId(fileUploadStore.checkProjectId);
        setParentId(0);
      } else {
        if (projectList.length > 0) {
          setShowProject(true);
          setProjectId(projectList[0].id);
          setParentId(0);
        }
      }
    }, [projectList.length, fileUploadStore.checkProjectId]);

    const initCollection = async () => {
      const newCollection = await Promise.all(
        (defaultCollection || []).map(async (item) => {
          const resultItem = await getSimpleMedia(Number(item));
          return resultItem;
        }),
      );
      setCollection([...newCollection]);
    };

    useEffect(() => {
      initCollection();
    }, [defaultCollection]);
    // 选择项目
    const selectProjectId = (item: { id: number, name: string}) => {
      resetPageTotal();
      setProjectId(item.id)
      fileUploadStore.editCheckProjectId(item.id)
      fileUploadStore.editCheckProjectName(item.name)
      setParentId(0)
      setFolderList([{ name: "素材库" }]);
      setShowProject(false)
    }
    // 触底
    const onScrollBottom = () => {
      if (refTotal.current > 0) {
        if ((Math.ceil(refTotal.current/30)) == refPage.current ) {
          return;
        } else {
          setConcatFlag(true)
          setPage((val) => {
            refPage.current = val + 1
            return val + 1;
          } )
        }
      }
    }
    // 搜索
    const onSearch = throttle((name: string) => {
      setSearchName(name)
    }, 1000)

    // 全选
    const handleSelectAll = () => {
      if (materList && materList.length > 0) {
        if (materList.every(item => item.type === 'FOLDER')) {
          Message.warning('请先添加素材')
          return;
        }
        setCheckFlag(true)
        const map = new Map();
        const arr = collection.concat(materList.filter(item => item.type !== 'FOLDER')) // 去除文件夹类型数据
        const newArr = arr.filter(v => !map.has(v.id) && map.set(v.id, v));
        console.log(newArr, 'newArr')
        setCollection(newArr)
      } else {
        Message.warning('请先添加素材')
      }
    }
    // 新建文件夹
    const addFile = () => {
      setCreateFolderModalVisible(true)
    }
    return (
      <Modal
        {...res}
        closable={false}
        unmountOnExit={true}
        onCancel={() => {
          res.onCancel();
        }}
        footer={
          <>
            <Button
              onClick={() => {
                fileUploadStore.uploadTasks = [];
                localStorage.removeItem('uploadTasks');
                res.onCancel();
              }}
            >
              取消
            </Button>
            <Tooltip color={'#fff'} content={
              <div className="flex--center" style={{color: '#333'}}>
                <Image style={{ marginBottom: '10px', marginRight: '5px' }} width={14} height={14} src={WarningImg} />点击确定后，未上传完成的文件会在任务中心继续上传
              </div>
            }>
              <Button
                onClick={() => {
                  const list = toCollectionGetMaterialInfo();
                  if (!list) {
                    Message.error('请选择素材');
                    return;
                  }
                  if (list?.length > maxCollectionLength) {
                    Message.error(`最大选择素材数量为${maxCollectionLength}个`);
                    return;
                  }
                  localStorage.removeItem('uploadTasks');
                  fileUploadStore.uploadTasks = [];
                  handleOkBtnClick(list);
                }}
                type='primary'
              >
                确定
              </Button>
            </Tooltip>
          </>
        }
        alignCenter={false}
        className={`${res.className} vl-modal upload-file-modal`}
        style={{ ...res.style, width: 1060, top: 50 }}>
        <div className="modal-header">
          <div className="moadl-title">
            <div>
              <img width={26} src={avtiveImg} alt="" />
              <span>选择文件</span>
            </div>
            <div>
              <IconClose
                onClick={() => {
                  fileUploadStore.uploadTasks = [];
                  localStorage.removeItem('uploadTasks');
                  res.onCancel();
                }}
              />
            </div>
          </div>
        </div>
        <div className="upload-file-modal-content">
          {
            showProject && <ProjectList onClick={(item) => {
              selectProjectId(item as { id: number, name: string})
            }}/>
          }
          {
            !showProject && <>
              {folderList?.length ? (
                <>
                  <SimpleBream
                    showAddFile={showAddFile}
                    addFile={addFile}
                    list={folderList}
                    handleBreamCrumbItemClick={(item) => {
                      setCheckFlag(false)
                      handleBreamCrumbItemClick(item);
                    }}
                    onSearch={(e) => {
                      onSearch(e);
                    }}
                  />
                </>
              ) : (
                <div style={{height: 22}}></div>
              )}
              <div style={{marginLeft: "-5px"}}>
                <BatchCollection
                  checkFlag={checkFlag}
                  disabeldAll={false}
                  number={collection.length}
                  total={total}
                  handleCancel={() => {
                    setCheckFlag(false)
                    setCollection([]);
                  }}
                  handleSelectAll={() => {
                    handleSelectAll()
                  }}
                />
              </div>
            </>
          }
          {
            !showProject && <div className="file-upload-modal-content">
                  <div className="material-file-modal-contaienr">
                    {
                      clickLoading && <Spin className="clickSpin"/>
                    }
                    {
                      !clickLoading && <div className="material-wrapper">
                          <Spin
                              style={{width: "100%", height: "100%"}}
                              loading={loading}>
                              <div className="upload-box">
                                {
                                  <MaterialContainer
                                    changeName={() => {
                                      resetPageTotal();
                                      setConcatFlag(false);
                                      getMaterialList()
                                    }}
                                    searchName={searchName}
                                    onScrollBottom={() => {
                                      onScrollBottom();
                                    }}
                                    projectId={projectId}
                                    taskId={""}
                                    onChange={(state, item) => {
                                      localCollection(state, item);
                                    }}
                                    currentFolderId={folderList[folderList.length - 1]?.id || 0}
                                    allowType={allowType}
                                    filterCondition={filterCondition}
                                    maxCollectionLength={maxCollectionLength}
                                    importCollection={importCollection}
                                    collection={collection}
                                    handleCollectionChange={setCollection}
                                    data={materList}
                                    handleFolderClick={(e) => {
                                      setCheckFlag(false)
                                      floderItemClick(e);
                                    }}
                                    allCollectionLength={0}
                                    tip={""}
                                  />
                                }
                              </div>
                          </Spin>
                      </div>
                    }
                  </div>
              </div>
          }
          {createFolderModalVisible ? (
            <CreateFolderModal
              projectId={projectId}
              handleOnBtnClick={() => {
                resetPageTotal()
                getMaterialList()
                setCreateFolderModalVisible(false);
              }}
              onCancel={() => {
                setCreateFolderModalVisible(false);
              }}
              visible={createFolderModalVisible}
              folderName={folderList?.length > 1 ? folderList[folderList.length - 1]?.name :  null}
            />
          ) : (
            <></>
          )}
        </div>
      </Modal>
    );
  },
);

export default MaterialFileModal
