/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Image, Avatar, Message } from "@arco-design/web-react";
import {
  add_grouping_icon,
  suspend_ai_icon,
} from "@/assets/creationscriptimage";
import { IconClose } from "@arco-design/web-react/icon";
import { TextOperation } from "@/lib/text-operation";
import { diff_match_patch } from "@/lib/diff-match-patch";
import { v4 as uuidv4 } from "uuid";
import { Client } from "@/lib/client";
import Intelligent from "@/components/business/Intelligent";
import Assistant from "@/components/business/Assistant";
import {
  getScript,
  getShare_doc,
} from "@/services/creativeScript/creationStores";
import ContentHeader from "@/components/UI/ContentHeader";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import DragDrop from "./component/DragDrop";
import useData from "./useData";
import Login from "@/stores/login";
import { editScript } from "@/services/creativeScript/explosiveScript";
import { toJS } from "mobx";
import creationStores from "@/stores/creationScriptStore";
import { getTokenToLocalStorage } from "@/utils/token";
import dayjs from "dayjs";
import useMixCutDetailMsg from "@/useStore/mixCutDetail/useMixCutDetailMsg";
import Header from "./component/Header";
import { getTimbre } from "@/services/mixCutDetail/mixCutDetail";
import commonStore from "@/stores/common";
import _ from "lodash";
import "./index.less";
const diff = new diff_match_patch();
// ，将两个文档的差异转换为TextOperation操作
const patchToOperation = (oldDoc, newDoc) => {
  const operation = new TextOperation();
  diff.diff_main(oldDoc, newDoc).forEach((diff) => {
    switch (diff[0]) {
      case 0:
        operation.retain(diff[1].length);
        break;
      case 1:
        operation.insert(diff[1]);
        break;
      case -1:
        operation.delete(diff[1].length);
        break;
      default:
        break;
    }
  });
  return operation;
};

export default CreationScript;
let ws;
function CreationScript() {
  const locations = useLocation();
  const data = useRef<any>();
  const client = useRef(null);
  const userId = useRef<any>(1);
  const clientId = useRef<any>(uuidv4());
  const [creationName, setCreationName] = useState("");
  const creationNameRef = useRef(null);
  const [userList, setUserList] = useState([]);
  const [updateTime, setUpdateTime] = useState<any>(
    new Date(dayjs().format("YYYY-MM-DD HH:mm")).getTime() / 1000,
  );
  const [isEditName, setIsEditName] = useState<boolean>(false);
  const editNameRef = useRef(null);
  const countRef = useRef(0);
  const params = useParams();
  const token = getTokenToLocalStorage();
  const [outInfo, setOutInfo] = useState<string>("");
  const navigate = useNavigate();
  const [tablePosition, setTablePosition] = useState({
    row: 0,
    col: 0,
  });

  const [cursorInfo, setCursorInfo] = useState([]);
  const dataSendFUn = useCallback(
    _.debounce(() => {
      if (countRef.current) {
        client.current?.performOperation(
          client.current.document,
          JSON.stringify(creationStores.dataSource),
        );
      }
      countRef.current++;
      data.current = JSON.stringify(creationStores.dataSource);
    }, 100),
    [],
  );

  const sendCursor = useCallback(
    _.debounce((info) => {
      ws.send(
        JSON.stringify({
          method: 1,
          body: JSON.stringify(info),
        }),
      );
    }, 500),
    [],
  );
  const {
    columns,
    defaultInitialData,
    insertAndUpdateKeys,
    selectStatus,
    setSelectStatus,
    suspendAi,
    setSuspendAi,
    dropList,
    editorHandler,
    smartStatus,
    sendSmartStatus,
    setItelligentIsExecution,
    selectInfo,
    setSendSmartStatus,
    handleAddCaptions,

    addCaptions,
  } = useData({
    dataSendFUn,
    sendCursor,
  });

  const editScriptFun = async (type) => {
    const items = {
      content: JSON.stringify(toJS(creationStores.dataSource)) || "",
      title: creationName || "",
      id: params.id || locations.state.id,
    };
    if (type == "title") {
      items.content = "";
      creationNameRef.current = creationName;
    } else {
      items.title = "";
    }
    const res = await editScript(items);
    if (res?.code == 0) {
      if (type != "title") {
        Message.success("保存成功");
      }
    } else {
      Message.error(res);
    }
  };
  // 智能文案操作
  const handleClickInterlligent = (opt) => {
    const edit = editorHandler.current[selectInfo.index].editor;
    switch (opt.type) {
      case "change":
        if (
          sendSmartStatus ==
          edit
            .getText()
            .replace(/\n\n\n\n/g, "\n")
            .slice(1)
        ) {
          edit.commands.focus("all");
          edit.commands.insertContent(opt.text);
        } else {
          edit.commands.insertContentAt(selectInfo.range, opt.text, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: "full",
            },
          });
        }
        Message.success("替换成功");
        break;
      case "copy":
        navigator.clipboard.writeText(opt.text);
        Message.success("复制成功");
        break;
      case "splice":
        edit.commands.insertContentAt(selectInfo.range, opt.text, {
          updateSelection: true,
          parseOptions: {
            preserveWhitespace: "full",
          },
        });
        Message.success("添加成功");
        break;
      default:
        break;
    }
    dataSendFUn();
  };
  // 定义一个打印函数，用于调试client的状态
  const print = () => {
    console.log(client.current.state instanceof Client.Synchronized);
    console.log(client.current.state instanceof Client.AwaitingConfirm);
    console.log(client.current.state instanceof Client.AwaitingWithBuffer);
  };
  // socket
  const clientReceive = useCallback((obj) => {
    print();
    if (obj.client_id == clientId.current) {
      if (!(client.current.state instanceof Client.Synchronized))
        client.current.serverAck();
    } else {
      if (!obj?.nickname) {
        client.current.applyServer(
          TextOperation.fromJSON(JSON.parse(obj.body).ops),
        );
        creationStores.compareUpdate(JSON.parse(client.current.document));
      }
    }
  }, []);
  // 处理WebSocket的连接和消息处理

  function inherit(Const, Super) {
    function F() {
      //
    }
    F.prototype = Super.prototype;
    Const.prototype = new F();
    Const.prototype.constructor = Const;
  }
  // 定义一个MyClient类，继承自Client类，
  function MyClient(userId, document, revision, ws) {
    Client.call(this, revision);
    this.userId = userId;
    this.document = document;
    this.ws = ws;
  }
  inherit(MyClient, Client);

  // 向服务端发送数据
  MyClient.prototype.sendOperation = function (revision, operation) {
    this.ws.send(
      JSON.stringify({
        method: 2,
        body: JSON.stringify({ revision, ops: operation }),
      }),
    );
  };
  MyClient.prototype.applyOperation = function (operation) {
    this.document = operation.apply(this.document);
  };
  const userListRef = useRef(null);
  useEffect(() => {
    userListRef.current = userList;
  }, [userList]);
  MyClient.prototype.performOperation = function (oldDoc, newDoc) {
    const operation = patchToOperation(oldDoc, newDoc);
    this.document = newDoc;
    this.applyClient(operation);
  };
  const debouncedScroll = useCallback(
    _.debounce(() => {
      const tableDom = document.querySelector(
        ".creationScript-table-container",
      );
      if (tableDom) {
        tableDom.scrollTo({
          top: tableDom.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 200),
    [], // 空依赖数组意味着这个函数只会在组件挂载时创建一次
  );

  const getShareDocFun = async (doc_id) => {
    // const teme = await getTeamDetail();
    const res = await getShare_doc({
      doc_id,
      doc_source: "script",
    });
    const item = res?.data;
    const obj = res?.data || [];
    if (!res) {
      return;
    }
    creationStores.delData(JSON.parse(item?.doc) || []);
    if (!creationName) {
      setCreationName(item.title || "");
    }
    creationNameRef.current = item.title || "";
    userId.current = Login.uuid;
    //
    try {
      ws = new WebSocket(
        `/api/v1/share_doc/watch?client_id=${clientId.current}&uid=${Login.uuid}&doc_id=${doc_id}&doc_source=script&Token=${token}`,
      );
    } catch (error) {
      console.log(error);
    }
    ws.onmessage = (ev) => {
      const wsInfo = JSON.parse(ev.data);

      let info: any = {};
      if (wsInfo) {
        info = JSON.parse(wsInfo.body);
      }
      const newUserList = [...userList];
      switch (wsInfo.method) {
        case 5:
          setUserList(_.uniqBy(Object.values(info), "uid"));
          if (Object.values(info).length == 1) {
            clientReceive(Object.values(info)[0]);
          } else {
            Message.success("开始协同");
          }
          break;
        case 4:
          clientReceive(info);
          setUserList(_.uniqBy([...newUserList, ...[info]], "uid"));
          break;
        case 3:
          setOutInfo(info);
          break;
        case 2:
          setUpdateTime(info.updated_at);
          clientReceive(wsInfo);

          break;
        case 1:
          // {"method":1,"body":"{\"RowId\":0,\"ColId\":1,\"position\":21,\"uid\":\"536257773457946664\",\"range\":{\"from\":21,\"to\":21}}","uid":"536257773457946664"}
          if (!cursorInfo[0]?.userInfo?.length) {
            setCursorInfo([
              {
                RowId: info.RowId,
                ColId: info.ColId,
                userInfo: [
                  {
                    id: info.uid,
                    name: userListRef.current.find((v) => v.uid == info.uid)
                      ?.nickname,
                    position: info.position,
                    colorInfo: userListRef.current.find(
                      (v) => v.uid == info.uid,
                    )?.avater_color,
                    range: info.range,
                  },
                ],
              },
            ]);
          } else {
            if (
              cursorInfo[0].userInfo.length &&
              cursorInfo[0].userInfo.every((v) => v.id != info.uid)
            ) {
              cursorInfo[0].userInfo.push({
                id: info.uid,
                name: userListRef.current.find((v) => v.uid == info.uid)
                  ?.nickname,
                position: info.position,
                colorInfo: userListRef.current.find((v) => v.uid == info.uid)
                  ?.avater_color,
                range: info.range,
              });
              setCursorInfo([...cursorInfo]);
            }
          }

          break;

        default:
          break;
      }
    };
    ws.onclose = () => {
      console.log("链接断开");
    };
    ws.onopen = () => {
      dataSendFUn();
    };
    ws.onerror = (error) => {
      console.error("WebSocket 发生错误:", error);
    };
    client.current = new MyClient(1, obj.doc, obj.revision, ws);
  };

  const getScriptFun = async (id) => {
    const res = await getScript(id);
    if (res?.data) {
      setCreationName(res.data?.item?.title || "");
      getShareDocFun(res.data.item.id);
    }
  };

  useEffect(() => {
    if (!params?.id) {
      getShareDocFun(locations.state.id);
    } else {
      getScriptFun(params.id);
    }
  }, [clientReceive]);

  useEffect(() => {
    if (isEditName) {
      editNameRef.current.focus();
    }
  }, [isEditName]);
  useEffect(() => {
    setUserList(
      _.uniqBy(
        userList.filter((v) => v.client_id != outInfo),
        "uid",
      ),
    );
  }, [outInfo]);
  useEffect(() => {
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);
  useEffect(() => {
    getTimbre({
      type: 1,
      page: 1,
      page_size: 99,
    }).then((res) => {
      commonStore.setTimbreList(res.data);
    });
  }, []);
  return (
    <>
      <ContentHeader
        name="创建脚本"
        subName="实现你的无限创意,在这里可以尽情发挥你的才艺和创意"
      />
      <div className="creation-content">
        {/* <div className="table-anchor">
          <TableAnchor
            columns={getDragDropCol()}
            send={dataSendFUn}
            handleClickPosition={(index, i) => {
              setTablePosition({
                row: index,
                col: i,
              });
            }}
          />
        </div> */}
        <Image
          preview={false}
          className="suspend-ai"
          width={62}
          src={suspend_ai_icon}
          style={{ display: suspendAi ? "none" : "block" }}
          onClick={() => {
            setSuspendAi(true);
            setSelectStatus(null);
            setSendSmartStatus("");
          }}
        />
        <div className="content-left">
          <Header
            creationName={creationName}
            setCreationName={setCreationName}
            creationNameRef={creationNameRef.current}
            editScriptFun={editScriptFun}
            updateTime={updateTime}
            dropList={dropList}
            dataSendFUn={dataSendFUn}
            columns={columns}
            scrip_id={params?.id || locations?.state?.id}
            userList={userList}
            addCaptions={handleAddCaptions}
          />

          <div className="creationScript-table">
            <DragDrop
              tablePosition={tablePosition}
              columns={columns}
              addCaptions={addCaptions}
              insertAndUpdateKeys={insertAndUpdateKeys}
              defaultInitialData={defaultInitialData}
              sendInfo={dataSendFUn}
              cursorInfo={cursorInfo}
            />
          </div>
          <div className="table_bottom-hr">
            <div></div>
            <div>
              <Button
                className="add-grouping"
                icon={
                  <Image
                    src={add_grouping_icon}
                    style={{ marginRight: 8 }}
                    width={16}
                    preview={false}
                  />
                }
                onClick={() => {
                  if (creationStores.dataSource.length < 15) {
                    creationStores.compareUpdate(
                      insertAndUpdateKeys(
                        creationStores.dataSource,
                        "add",
                        defaultInitialData(),
                        -1,
                      ),
                    );
                    Message.success("添加成功");
                    debouncedScroll();
                    dataSendFUn();
                  } else {
                    Message.warning("分镜组个数最多为15个");
                  }
                }}>
                添加分组
              </Button>
            </div>
            <div></div>
          </div>
        </div>
        <div className="content-right">
          <div
            style={{
              width: sendSmartStatus ? 432 : 0,
              opacity: sendSmartStatus ? 1 : 0,
              transition: `width 0.3s ease,opacity 0.${sendSmartStatus ? 9 : 2}s ease`,
              marginLeft: selectStatus || suspendAi || smartStatus ? 21 : 0,
            }}>
            <Intelligent
              defaultStatus={smartStatus}
              value={sendSmartStatus}
              isInExecution={setItelligentIsExecution}
              handleOnChange={(e) => {
                handleClickInterlligent(e);
              }}
              handleCloseBtnClick={() => {
                setSendSmartStatus("");
              }}
            />
          </div>
          <div
            style={{
              width: suspendAi ? 432 : 0,
              opacity: suspendAi ? 1 : 0,
              transition: `width 0.3s ease,opacity 0.${suspendAi ? 9 : 2}s ease`,
            }}>
            <Assistant
              status={suspendAi}
              closeIcon={
                <IconClose
                  onClick={() => {
                    setSuspendAi(false);
                  }}
                  style={{ cursor: "pointer" }}
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
