import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Dropdown,
  Image,
  Input,
  Message,
  Modal,
  Tooltip,
} from "@arco-design/web-react";
import {
  add_column_icon,
  download_icon,
  import_icon,
  input_icon,
  inscript_icon,
  add_caption,
} from "@/assets/creationscriptimage";
import dayjs from "dayjs";
import { filmDditingIcon } from "@/assets/scriptimage";
import creationStores from "@/stores/creationScriptStore";
import { toJS } from "mobx";
import Modals from "@/components/UI/Modals";
import { IconClose } from "@arco-design/web-react/icon";
import useParseScript from "@/useStore/mixCutDetail/useParseScript";
import { saveEngineeringContent } from "@/services/mixCutDetail/mixCutDetail";
import { createEngineering } from "@/services/createSpace/engineering";
import FilesUpload from "../FilesUpload";
import {
  addCustomScriptRefenceCount,
  toIdGetCustomScript,
} from "@/services/createSpace/script";
import CreateEngineeringModal from "@/pages/CreationSpace/CreativeSpace/CreativeContainer/components/Engineering/CreatEngineeringModal/CreateEngineeringModal";
import { setScriptCount } from "@/services/creativeScript/creationStores";
const AvatarGroup = Avatar.Group;
interface IHeaderProps {
  creationName: string;
  setCreationName: (opt: string) => void;
  creationNameRef: string;
  editScriptFun: (opt: string) => void;
  updateTime: number;
  dropList: any;
  columns: any;
  dataSendFUn: () => void;
  scrip_id: string | number;
  userList: any;
  addCaptions: () => void;
}
const Header: React.FC<IHeaderProps> = (props) => {
  const {
    scrip_id,
    userList,
    creationName,
    setCreationName,
    creationNameRef,
    editScriptFun,
    updateTime,
    dropList,
    columns,
    dataSendFUn,
    addCaptions,
  } = props;
  const [isEditName, setIsEditName] = useState<boolean>(false);
  const editNameRef = useRef(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const { pureParseCutsomScript } = useParseScript();
  // 解析导入脚本文件的数据
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [isShowEdit, setIsShowEdit] = useState<boolean>(false);
  const [getJson, setGetJson] = useState([]);
  const closeModal = () => {
    setVisibleModal(false);
  };

  const importScript = () => {
    // ...toJS(creationStores.dataSource)
    const newData = [];
    //  设置一个计数器用于生成唯一的key值
    // 如果c数组已有数据，则keyCounter初始化为c中最后一个元素的key加1，否则初始化为1
    let keyCounter =
      newData.length > 0 ? Math.max(...newData.map((item) => item.key)) + 1 : 1;
    // 转换数组a到数组c的格式
    const keyMapping = {};
    const cls = columns;
    cls.forEach((item) => {
      keyMapping[item.title] = item.dataIndex;
    });
    getJson.forEach((item) => {
      const newObj = { key: keyCounter++ }; // 初始化新对象并设置key值
      for (const key in item) {
        if (keyMapping[key]) {
          if (key === "台词文案") {
            // 特殊处理台词文案
            newObj[keyMapping[key]] = [
              {
                type: "listItem",
                content: [
                  {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: item[key],
                      },
                    ],
                  },
                ],
              },
            ];
          } else if (key === "素材") {
            newObj[keyMapping[key]] = [];
          } else {
            newObj[keyMapping[key]] = [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: item[key],
                  },
                ],
              },
            ];
          }
        }
      }
      newData.push(newObj); // 将新对象添加到数组c中
    });
    if (newData.length > 15) {
      Message.warning("分镜组个数最多为15个");
    }

    creationStores.compareUpdate(newData.slice(0, 15));
    closeModal();
    dataSendFUn();
  };
  useEffect(() => {
    if (isEditName) {
      editNameRef.current.focus();
    }
  }, [isEditName]);
  return (
    <>
      <Modals
        width={769}
        height={594}
        title="导入脚本"
        cancelBtn={<Button onClick={closeModal}>取消</Button>}
        subMiteBtn={
          <Button
            disabled={!getJson.length}
            type="primary"
            onClick={() => {
              Modal.confirm({
                title: "温馨提示!",
                content: "导入脚本后当面页面内容将全部被替换是否直接导入？",
                okButtonProps: {
                  status: "danger",
                },
                onOk: () => {
                  importScript();
                },
              });
            }}>
            保存
          </Button>
        }
        titleIcon={<Image width={18} preview={false} src={inscript_icon} />}
        closeIcon={<IconClose onClick={closeModal} />}
        visible={visibleModal}>
        <div className="modal-content">
          <div className="modal-download">
            <div>请先下载Excel数据导入模版，并按照模版导入数据</div>
            <a href="/template.xlsx" download>
              <Image width={18} preview={false} src={download_icon} />
              <span>下载Excel模版</span>
            </a>
          </div>
          <FilesUpload getJson={setGetJson} visible={visibleModal} />
        </div>
      </Modals>
      <CreateEngineeringModal
        submit={async (e) => {
          try {
            const data = await createEngineering(e);
            const json = await toIdGetCustomScript(scrip_id);
            const content = pureParseCutsomScript(json);
            await saveEngineeringContent(
              Number(data.id),
              JSON.stringify(content),
            );
            await setScriptCount({
              id: scrip_id,
              type: 1,
            });
            window.open(`/mix-detail/${data.id}`, "_blank");
          } catch (e) {
            console.log(e);
            Message.error(e);
          }
        }}
        title={"创建工程"}
        visible={createModalVisible}
        value={{}}
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        onOk={(e) => {
          setCreateModalVisible(false);
        }}
      />
      <div className="creation-tool">
        <div style={{ flex: 1, marginTop: 19 }}>
          <div
            className="creation-name"
            onMouseEnter={() => {
              setIsShowEdit(true);
            }}
            onMouseLeave={() => {
              setIsShowEdit(false);
            }}>
            {isEditName ? (
              <Input
                autoWidth
                className="creation-title"
                ref={editNameRef}
                value={creationName}
                maxLength={20}
                onChange={(e) => {
                  setCreationName(e);
                }}
                onBlur={() => {
                  setIsEditName(false);
                  if (creationNameRef == creationName) {
                    return;
                  }
                  editScriptFun("title");
                }}
                placeholder="请输入脚本名称"
              />
            ) : (
              <p className="creation-title">{creationName}</p>
            )}
            <Image
              src={input_icon}
              style={{
                marginRight: 11,
                cursor: "pointer",
                display: isShowEdit && !isEditName ? "block" : "none",
              }}
              width={22}
              preview={false}
              onClick={() => {
                setIsEditName(true);
              }}
            />
          </div>
          <div className="creation-name-border"></div>
        </div>
        {userList && userList.length ? (
          <p className="save">
            {dayjs(updateTime * 1000)
              .format("YYYY-MM-DD HH:mm")
              .slice(-5)}
            &nbsp;已保存
          </p>
        ) : (
          <></>
        )}
        <div className="creation-btn-box" style={{ marginLeft: 10 }}>
          <AvatarGroup className="creation-avatarGroup">
            {userList.map((v, i) => {
              if (i > 10) {
                return (
                  <Avatar
                    key={v.uid}
                    style={{
                      background: "#eeeeee",
                      border: "none",
                      width: 30,
                      height: 30,
                      display: "none",
                    }}>
                    <div className="avatar-num">&nbsp;{v.nickname}</div>
                  </Avatar>
                );
              } else {
                return (
                  <Avatar key={v.uid}>
                    <Tooltip
                      position="top"
                      trigger="hover"
                      content={v.nickname}>
                      {v.avatar && !v.avatar.includes("linear-gradient") ? (
                        <div className="avatar-script">
                          <img src={v.avatar} width={30} />
                        </div>
                      ) : (
                        <div
                          className="user-image"
                          style={{ background: v.avater_color || "" }}>
                          {v.nickname.slice(-2) || `${++i}`}
                        </div>
                      )}
                    </Tooltip>
                  </Avatar>
                );
              }
            })}
          </AvatarGroup>
          <Button
            className="import-script-btn"
            icon={
              <Image
                src={add_caption}
                style={{ marginRight: 8 }}
                width={16}
                preview={false}
              />
            }
            onClick={() => {
              const firstObject = creationStores.dataSource[0];
              const captions = Object.keys(firstObject).filter((key) =>
                key.startsWith("caption"),
              );
              if (captions.length >= 15) {
                Message.warning("最多为15个");
                return;
              }
              addCaptions();
            }}>
            添加台词组
          </Button>
          <Button
            className="import-script-btn"
            icon={
              <Image
                src={import_icon}
                style={{ marginRight: 8 }}
                width={16}
                preview={false}
              />
            }
            onClick={() => {
              setVisibleModal(true);
            }}>
            导入脚本
          </Button>

          <Dropdown droplist={dropList()} trigger="click" position="bl">
            <Button
              className="add-column-btn"
              icon={
                <Image
                  src={add_column_icon}
                  style={{ marginRight: 8 }}
                  width={16}
                  preview={false}
                />
              }>
              添加列
            </Button>
          </Dropdown>

          <Button
            className="save-btn"
            icon={
              <Image
                src={filmDditingIcon}
                style={{ marginRight: 8 }}
                width={16}
                preview={false}
              />
            }
            onClick={() => setCreateModalVisible(true)}>
            去混剪
          </Button>
        </div>
      </div>
    </>
  );
};

export default Header;
